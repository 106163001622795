import webApi from "../../WebApi/WebApi";
import eastrinOmg from "../../NcaafFrontAssets/ScheduleAssets/images/eastrin.png";
import kent from "../../NcaafFrontAssets/ScheduleAssets/images/kent.png";
import westron from "../../NcaafFrontAssets/ScheduleAssets/images/westron.png";
import akron from "../../NcaafFrontAssets/ScheduleAssets/images/akron.png";
import ohio from "../../NcaafFrontAssets/ScheduleAssets/images/ohio.png";
import central from "../../NcaafFrontAssets/ScheduleAssets/images/central.png";
import ballstate from "../../NcaafFrontAssets/ScheduleAssets/images/ballstate.png";
import miami from "../../NcaafFrontAssets/ScheduleAssets/images/miami.png";
import bowling from "../../NcaafFrontAssets/ScheduleAssets/images/bowling.png";
import toledo from "../../NcaafFrontAssets/ScheduleAssets/images/toledo.png";
export const ncaafScheduleData = [
  {
    date: "WEDNESDAY, 4TH NOVEMBER",
    ncaafScheduleTableData: [
      {
        Eastern: "Eastern Michigan",
        imgUrl: eastrinOmg,
        Eastern1: "Kent State",
        imgUrl2: kent,
        Eastern2: "KENT 27, EMU 23",
        Eastern3: "P. Hutchinson",
        Eastern4: "B. Bradford",
        Eastern5: "H. Beydoun",
        number: 241,
        knight: 72,
        receving: 112,
      },
      {
        Eastern: "Western Michigan",
        imgUrl: westron,
        Eastern1: "Akron",
        imgUrl2: akron,
        Eastern2: "WMU 58, AKR 13",
        westren: "K. Eleby ",
        Eastern4: "J. Knight",
        Eastern5: "D. Eskridge",
        number: 262,
        knight: 74,
        receving: 114,
      },
      {
        Eastern: "Buffalo",
        imgUrl: eastrinOmg,
        Eastern1: "Northern Illinois",
        imgUrl2: kent,
        Eastern2: "BUFF 49, NIU 30",
        Eastern3: "R. Bowers",
        Eastern4: "J. Patterson",
        Eastern5: "T. Richie",
        number: 202,
        knight: 137,
        receving: 106,
      },
      {
        Eastern: "Ohio",
        imgUrl: ohio,
        Eastern1: "Central Michigan",
        imgUrl2: central,
        Eastern2: "CMU 30, OHIO 27",
        Eastern3: "D. Richardson",
        Eastern4: "K. Lewis",
        Eastern5: "I. Cox",
        number: 243,
        knight: 112,
        receving: 102,
      },
      {
        Eastern: "Ball State",
        imgUrl: ballstate,
        Eastern1: "Miami (OH)",
        imgUrl2: miami,
        Eastern2: "M-OH 38, BALL 31",
        Eastern3: "D. Plitt",
        Eastern4: "C. Huntley",
        Eastern5: "J. Sorenson",
        number: 309,
        knight: 130,
        receving: 139,
      },
      {
        Eastern: "Bowling Green",
        imgUrl: bowling,
        Eastern1: "Toledo",
        imgUrl2: toledo,
        Eastern2: "TOL 38, BGSU 3",
        Eastern3: "E. Peters",
        Eastern4: "S. Seymour",
        Eastern5: "J. Ortega-Jones",
        number: 214,
        knight: 93,
        receving: 61,
      },
    ],
  },

  {
    date: "THURSDAY, 4TH November",
    ncaafScheduleTableData: [
      {
        Eastern: "Utah State",
        imgUrl: eastrinOmg,
        Eastern1: "Nevada",
        imgUrl2: kent,
        Eastern2: "NEV 34, USU 9",
        Eastern3: "C. Strong",
        Eastern4: "T. Taua",
        Eastern5: "R. Doubs",
        number: 411,
        knight: 107,
        receving: 137,
      },
      {
        Eastern: "Wyoming",
        imgUrl: westron,
        Eastern1: "Colorado State",
        imgUrl2: akron,
        Eastern2: "CSU 34, WYO 24",
        westren: "L. Williams ",
        Eastern4: "X. Valladay",
        Eastern5: "D. Wright",
        number: 321,
        knight: 147,
        receving: 146,
      },
    ],
  },

  {
    date: "FRIDAY, 4TH November",
    ncaafScheduleTableData: [
      {
        Eastern: "#9 BYU",
        imgUrl: eastrinOmg,
        Eastern1: "#21 Boise State",
        imgUrl2: kent,
        Eastern2: "San Diego State",
        Eastern3: "Z. Wilson",
        Eastern4: "T. Allgeier",
        Eastern5: "K. Shakir",
        number: 359,
        knight: 123,
        receving: 139,
      },
      {
        Eastern: "#11 Miami",
        imgUrl: westron,
        Eastern1: "NC State",
        imgUrl2: akron,
        Eastern2: "MIAMI 44, NCST 41",
        westren: "D. King",
        Eastern4: "D. King",
        Eastern5: "M. Harley",
        number: 430,
        knight: 105,
        receving: 153,
      },
      {
        Eastern: "San José State",
        imgUrl: eastrinOmg,
        Eastern1: "San Diego State",
        imgUrl2: kent,
        Eastern2: "SJSU 28, SDSU 17",
        Eastern3: "C. Baker",
        Eastern4: "G. Bell",
        Eastern5: "J. Matthews",
        number: 261,
        knight: 109,
        receving: 139,
      },
    ],
  },
];


export const weekListDate = {

  2022: [
    { 'Week 1': ["08-26", "09-04"] },
    { 'Week 2': ["09-07", "09-10"] },
    { 'Week 3': ["09-14", "09-17"] },
    { 'Week 4': ["09-21", "09-24"] },
    { 'Week 5': ["09-28", "09-30"] },
    { 'Week 6': ["10-04", "10-08"] },
    { 'Week 7': ["10-10", "10-15"] },
    { 'Week 8': ["10-17", "10-22"] },
    { 'Week 9': ["10-24", "10-29"] },
    { 'Week 10': ["10-31", "11-04"] },
    { 'Week 11': ["11-07", "11-11"] },
    { 'Week 12': ["11-14", "11-18"] },
    { 'Week 13': ["11-22", "11-25"] },
    { 'Week 14': ["12-01", "12-03"] },
    { 'Week 15': ["12-08", "12-10"] },
    { 'Week Bowls': ["12-16", "01-09"] },
  ],
  2023: [
    { 'Week 1': ["08-23", "08-24", "08-25", "08-26", "08-27", "08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04"] },
    { 'Week 2': ["09-05", "09-06", "09-07", "09-08", "09-09", "09-10"] },
    { 'Week 3': ["09-11", "09-12", "09-13", "09-14", "09-15", "09-16", "09-17"] },
    { 'Week 4': ["09-18", "09-19", "09-20", "09-21", "09-22", "09-23", "09-24"] },
    { 'Week 5': ["09-25", "09-26", "09-27", "09-28", "09-29", "09-30"] },
    { 'Week 6': ["10-1", "10-2", "10-3", "10-4", "10-5", "10-6", "10-7", "10-8"] },
    { 'Week 7': ["10-9", "10-10", "10-11", "10-12", "10-13", "10-14", "10-15"] },
    { 'Week 8': ["10-16", "10-17", "10-18", "10-19", "10-20", "10-21", "10-22"] },
    { 'Week 9': ["10-23", "10-24", "10-25", "10-26", "10-27", "10-28", "10-29"] },
    { 'Week 10': ["10-30", "10-31", "11-1", "11-2", "11-3", "11-4"] },
    { 'Week 11': ["11-5", "11-6", "11-7", "11-8", "11-9", "11-10", "11-11"] },
    { 'Week 12': ["11-12", "11-13", "11-14", "11-15", "11-16", "11-17", "11-18"] },
    { 'Week 13': ["11-19", "11-20", "11-21", "11-22", "11-23", "11-24", "11-25"] },
    { 'Week 14': ["11-26", "11-27", "11-28", "11-29", "11-30", "12-1", "12-2", "12-3"] },
    { 'Week 15': ["12-4", "12-5", "12-6", "12-7", "12-8", "12-9", "12-10"] },
    { 'Week Bowls': ["12-11", "12-12", "12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19", "12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26", "12-27", "12-28", "12-29", "12-30", "12-31", "01-09"] },

  ],

  2024: [
    { 'Week Bowls': ["01-01", "01-02", "01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09"] },
    { 'Week 1': ["08-12","08-13","08-14","08-15","08-16","08-17","08-18","08-19","08-20","08-21","08-22","08-23","08-24","08-26","08-27","08-28","08-29","08-30","08-31","09-01","09-02","09-03", "09-04"] },
    { 'Week 2': ["09-05", "09-06", "09-07", "09-08", "09-09", "09-10"] },
    { 'Week 3': ["09-11", "09-12", "09-13", "09-14", "09-15", "09-16", "09-17"] },
    { 'Week 4': ["09-18", "09-19", "09-20", "09-21", "09-22", "09-23", "09-24"] },
    { 'Week 5': ["09-25", "09-26", "09-27", "09-28", "09-29", "09-30"] },
    { 'Week 6': ["10-1", "10-2", "10-3", "10-4", "10-5", "10-6", "10-7", "10-8"] },
    { 'Week 7': ["10-9", "10-10", "10-11", "10-12", "10-13", "10-14", "10-15"] },
    { 'Week 8': ["10-16", "10-17", "10-18", "10-19", "10-20", "10-21", "10-22"] },
    { 'Week 9': ["10-23", "10-24", "10-25", "10-26", "10-27", "10-28", "10-29"] },
    { 'Week 10': ["10-30", "10-31", "11-1", "11-2", "11-3", "11-4"] },
    { 'Week 11': ["11-5", "11-6", "11-7", "11-8", "11-9", "11-10", "11-11"] },
    { 'Week 12': ["11-12", "11-13", "11-14", "11-15", "11-16", "11-17", "11-18"] },
    { 'Week 13': ["11-19", "11-20", "11-21", "11-22", "11-23", "11-24", "11-25"] },
    { 'Week 14': ["11-26", "11-27", "11-28", "11-29", "11-30", "12-1", "12-2", "12-3"] },
    { 'Week 15': ["12-4", "12-5", "12-6", "12-7", "12-8", "12-9", "12-10"] },
    { 'Week Bowls': ["12-11", "12-12", "12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19", "12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26", "12-27", "12-28", "12-29", "12-30", "12-31", "01-09"] },

  ],


}

export const weekListDateNcaaf = {

  2022: [
    { 'Week 1': ["08-26", "09-04"] },
    { 'Week 2': ["09-07", "09-10"] },
    { 'Week 3': ["09-14", "09-17"] },
    { 'Week 4': ["09-21", "09-24"] },
    { 'Week 5': ["09-28", "09-30"] },
    { 'Week 6': ["10-04", "10-08"] },
    { 'Week 7': ["10-10", "10-15"] },
    { 'Week 8': ["10-17", "10-22"] },
    { 'Week 9': ["10-24", "10-29"] },
    { 'Week 10': ["10-31", "11-04"] },
    { 'Week 11': ["11-07", "11-11"] },
    { 'Week 12': ["11-14", "11-18"] },
    { 'Week 13': ["11-22", "11-25"] },
    { 'Week 14': ["12-01", "12-03"] },
    { 'Week 15': ["12-08", "12-10"] },
    { 'Week Bowls': ["12-16", "01-09"] },
  ],
  2023: [
    { 'Week 1': ["08-23", "08-24", "08-25", "08-26", "08-27", "08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04"] },
    { 'Week 2': ["09-05", "09-06", "09-07", "09-08", "09-09", "09-10"] },
    { 'Week 3': ["09-11", "09-12", "09-13", "09-14", "09-15", "09-16", "09-17"] },
    { 'Week 4': ["09-18", "09-19", "09-20", "09-21", "09-22", "09-23", "09-24"] },
    { 'Week 5': ["09-25", "09-26", "09-27", "09-28", "09-29", "09-30"] },
    { 'Week 6': ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-07", "10-08"] },
    { 'Week 7': ["10-09", "10-10", "10-11", "10-12", "10-13", "10-14", "10-15"] },
    { 'Week 8': ["10-16", "10-17", "10-18", "10-19", "10-20", "10-21", "10-22"] },
    { 'Week 9': ["10-23", "10-24", "10-25", "10-26", "10-27", "10-28", "10-29"] },
    { 'Week 10': ["10-30", "10-31", "11-01", "11-02", "11-03", "11-04"] },
    { 'Week 11': ["11-05", "11-06", "11-07", "11-08", "11-09", "11-10", "11-11"] },
    { 'Week 12': ["11-12", "11-13", "11-14", "11-15", "11-16", "11-17", "11-18"] },
    { 'Week 13': ["11-19", "11-20", "11-21", "11-22", "11-23", "11-24", "11-25"] },
    { 'Week 14': ["11-26", "11-27", "11-28", "11-29", "11-30", "12-01", "12-02", "12-03"] },
    { 'Week 15': ["12-04", "12-05", "12-06", "12-07", "12-08", "12-09", "12-10"] },
    { 'Week Bowls': ["12-11", "12-12", "12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19", "12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26", "12-27", "12-28", "12-29", "12-30", "12-31", "01-09"] },

  ],

  2024: [
    { 'Week Bowls': ["01-01", "01-02", "01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09","01-10","05-08","05-06","05-07","05-08","05-09","05-10","05-11","05-12","05-13","05-14","05-15","05-16","05-17","05-18","05-19","05-20","05-21","05-22","05-23","05-24","05-25","05-26","05-27","05-28","05-29","05-30","05-31",
    "06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07", "06-08", "06-09","06-10","06-11","06-12","06-13","06-14","06-16","06-16","06-17","06-18","06-19","06-20","06-21","06-22","06-23","06-24","06-25","06-26","06-27","06-28","06-29","06-30",
    "07-01", "07-02", "07-03", "07-04", "07-05", "07-06", "07-07", "07-08", "07-09","07-10","07-11","07-12","07-13","07-14","07-15","07-16","07-17","07-18","07-19","07-20","07-21","07-22","07-23","07-24","07-25","07-26","07-27","07-28","07-29","07-30","07-31",
    "08-01", "08-02", "08-03", "08-04", "08-05", "08-08", "08-07", "08-08", "08-09","08-10","08-11","08-12", 
  ] },
    { 'Week 1': ["08-28", "09-04","08-13","08-14","08-15","08-16","08-17","08-18","08-19","08-20","08-21","08-22","08-23","08-24","08-25","08-26","08-27","08-28","08-29","08-30","08-31"] },
    { 'Week 2': ["09-07", "09-10"] },
    { 'Week 3': ["09-14", "09-17"] },
    { 'Week 4': ["09-21", "09-24"] },
    { 'Week 5': ["09-28", "09-30"] },
    { 'Week 6': ["10-04", "10-08"] },
    { 'Week 7': ["10-10", "10-15"] },
    { 'Week 8': ["10-17", "10-22"] },
    { 'Week 9': ["10-24", "10-29"] },
    { 'Week 10': ["10-31", "11-04"] },
    { 'Week 11': ["11-07", "11-11"] },
    { 'Week 12': ["11-14", "11-18"] },
    { 'Week 13': ["11-22", "11-25"] },
    { 'Week 14': ["12-01", "12-03"] },
    { 'Week 15': ["12-08", "12-10"] },
    { 'Week Bowls': ["12-16", "01-09"] }

  ],


}



//NCAAF schedule api integration
export const getAllNcaafSchedulelist = async (division, year, week, onSuccess, onFailure) => {
  console.log(division, year, week, "ncaafteamId")



  try {
    const res = await webApi.post(

      `/getNcaaScheduleList`,
      {
        league: division ? division : "",
        year: year ? year : "",
        week: week ? week : "",


      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const getAllNcaafSchedulelistById = async (year, teamId, onSuccess, onFailure) => {
  console.log(teamId, year, "ncaafteamId")



  try {
    const res = await webApi.post(

      `/getNcaaScheduleList`,
      {
        year: year ? year : "",

        team: teamId ? teamId : ""

      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const getAllNcaafScheduleDivisonlist = async (data) => {
  return await webApi.get('ncaaDivisionList');
}
export const getcheckGameNcaaf = async (data) => {
  return await webApi.get('checkGameNcaaf');
}