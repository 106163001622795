import React, { useEffect, useState } from "react"; 
import { Helmet } from "react-helmet";
import { getAllNewslist } from "../../service/newsService";
import { format } from 'date-fns'
import { useLocation ,useHistory} from "react-router-dom";
import cfsDefault from "../../../src/Assets/cfsdefault.png"


const NewsSearch = () => {

    const {state} = useLocation();
    const history = useHistory();
    // const queryParams = new URLSearchParams(state.search)

    
    const [newsList, SetAllNewsList] = useState(state?.result);
    const [searchList, SetAllSearchList] = useState(state?.searchNews);

    const [searchValue, setSearchValue] = useState(false);
    const [searchDuplicate, setSearchDuplicate] = useState("");

    

    const [Error, setAllError] = useState("");


    const [searchNews, setSearchNews] = React.useState("");


console.log(localStorage.getItem("results"),"state123")

    useEffect(() => {
        // news() 
         SetAllNewsList(state?.result)
         SetAllSearchList(state?.searchNews)
        console.log(newsList,"newsList")
        getAllNewslist(
            localStorage.getItem("searchNews"),
            (r) => {
                console.log(r, "1205")
                SetAllNewsList(r);
                // setSearchNews("");
                setAllError("");

            },
        );
    // history.push(`/news-search?SearchData=${searchNews}`);
        
    }, []);

    // const news = (v) => {
    //     getAllNewslist((newsList) => {
    //         console.log(newsList, "fetlist")
    //         SetAllNewsList(newsList);
    //     });
    // }

    // search Query

    useEffect(() => {
        
        onLink();
    
      },[]);

    const onLink =()=>{

        history.push(`/news-search?searchdata=${localStorage.getItem("searchNews")}`);
    }

    
    const onAddSearchNews = (e) => {
        // setSearchValue(false)
        const { name, value } = e.target;
        console.log(value, "saturday")

        setSearchNews(value);

    };
    console.log(searchNews, "searchNews")

    const onAddSearchNewsBtn = (e) => {
        e.preventDefault()        
        console.log("clicked")
        

        if (searchNews === "") {

            setAllError("Please Enter Your Keyword")
        } else {
        history.push(`/news-search?searchdata=${searchNews}`);

            setSearchValue(true)
        setSearchDuplicate(searchNews)
        getAllNewslist(
            searchNews,
            (r) => {
                console.log(r, "1205")
                SetAllNewsList(r);
                // setSearchNews("");
                localStorage.setItem("searchNews",searchNews)

                setAllError("");

            },
        );
        }

    };
    console.log(searchNews,"after")

    const soImg=(t,p)=>{
        return t?t:p?p:cfsDefault

    }

    return (<>


        <Helmet>
            <title>NFL | News </title>
        </Helmet>

        <section class="innerMainbg helpBg customWidth">
            <div class="innerBannerbg">
                <div class="innerShadow">
                    <div class="innerDots">
                        <div class="innerHeading pb-5">
                            <h2>News</h2>
                            {/* {
                                searchDuplicate ?
                                    <h3 className="mb-5">Your Last Search Results is - {searchDuplicate}</h3> :
                                    <h3 className="mb-5">Your Last Search Results is - {state.searchNews}</h3>


                            } */}
                            {/* <h3 className="mb-5">Your Search Results- {state.searchNews}</h3> */}

                            <div class="d-flex nsr">
                                <input
                                    class="form-control me-2"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    name="txt_keyword_pharse"
                                    style={{borderColor:Error?'red':"",borderWidth:'2px'}}

                                    onChange={onAddSearchNews}
                                    value={searchNews}

                                />
                                <button onClick={(e)=>onAddSearchNewsBtn(e)}>Search</button>
                            </div>
                            <p style={{
                                color: 'red', height: '25px',
                                textAlign: 'left'}}>{Error}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="innerBodycontent">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                           

                            <div className="row pt-5">
                            <div className="newsSrchhdn">
                            {
                                searchDuplicate ?
                                    <h4 className="mb-5">Showing results for - "{searchDuplicate}"</h4> :
                                    <h4 className="mb-5">Showing results for - "{localStorage.getItem("searchNews")}"</h4>


                            }
                            </div>
                            {/* {console.log(newsList,"newsList")} */}
                                {newsList && newsList.length > 0 ?
                                    newsList.map((n, index) => (
                                        <div className="col-md-2" key={index}>
                                            <div class="newsPlayerbg newsSrchbg">
                                                <a href={n.article_link1} target="_blank"><img src={soImg(n.team_imgs,n.player_imgs)} alt="" /></a>
                                                <div class="vidCardTxt">
                                                    <a href={n.article_link1} target="_blank">
                                                        <small>{format(new Date(n.article_date1), 'MM/dd/yyyy')}</small>
                                                        <h6>{n.article_headline1}</h6>
                                                        <p>{n.article_excerpt1}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )) : <p style={{ color: "red" }}>No Data Found</p>}

                            </div>

                        </div>


                    </div>


                </div>
            </div>



        </section>

    </>)
}

export default NewsSearch