import React from "react";
import Poadcast from "./HomeArticleVideoSlider";
import "./Poadcast.css";
import HomeArticleVideoSlider from "./HomeArticleVideoSlider";

const NflSlider = () => {
  return (
    <>
      <HomeArticleVideoSlider />
    </>
  );
};

export default NflSlider;
