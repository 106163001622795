export const UpperTeeth = () => (
  <svg
    className="upperTeeth"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="39.87"
    viewBox="0 0 113.393 39.87"
  >
    <g id="Group_34" data-name="Group 34" transform="translate(0 0)">
      <path
        id="Path_189"
        data-name="Path 189"
        d="M-6674.215-5420.655l-2.176-1.815-7.239.922,3.275-1.9-.141-.488a22.169,22.169,0,0,0-3.96,1.692c-2.544,1.712-3.667,1.72-5.939-.279l-7.044,2.582-1.5-2.13,19.054-4.023-.019-.379-2.278,1.63.332.622,7.8-3.889,7.906,3.95.354-.608-2.4-1.726c3.3.96,6.372,2.052,9.536,2.719,3.126.661,6.341.892,9.725,1.339l-1.731,2.5-7.024-2.542-3.476,1.906-6.317-3.463-.345.623,2.706,1.591-5.985-.474,0-.807-2.8,2.41c.643-1.206,1.283-2.412,1.829-3.437l-3.673.324C-6675.28-5422.859-6674.748-5421.757-6674.215-5420.655Z"
        transform="translate(6730.836 5430.105)"
        fill="#fff"
      />
      <path
        id="Path_190"
        data-name="Path 190"
        d="M-6723.747-5399.937c-1.412-2.761-2.825-5.52-4.567-8.927-3.736,8.693-5.319,17.05-3.988,25.926-4.472-9.774-3.2-19.146,1.674-28.2.551-1.023,2.111-2.218,3.076-2.117a4.267,4.267,0,0,1,3.086,2.459c.85,3.476,1.159,7.083,1.676,10.641Z"
        transform="translate(6748.585 5422.809)"
        fill="#5396c6"
      />
      <path
        id="Path_191"
        data-name="Path 191"
        d="M-6583.252-5408.194q-2.057,3.908-4.111,7.818c-.344-3.467-1.309-6.991.478-10.209.651-1.174,2.064-2.523,3.232-2.627,1-.09,2.56,1.27,3.149,2.375,4.322,8.091,5.738,16.571,2.8,25.493a5,5,0,0,1-1.348,2.4c0-4.242.418-8.537-.116-12.711-.547-4.279-2.026-8.438-3.1-12.651Z"
        transform="translate(6676.283 5422.788)"
        fill="#5396c6"
      />
      <path
        id="Path_192"
        data-name="Path 192"
        d="M-6707.743-5391.982c-1.992-5.791-3.952-11.619-2.51-17.831.409-1.758.707-4.124,3.013-4.168,2.2-.044,2.436,2.261,2.962,3.917,1.374,4.314-.363,8.344-1.321,12.41A44.375,44.375,0,0,1-6707.743-5391.982Z"
        transform="translate(6736.64 5423.164)"
        fill="#fff"
      />
      <path
        id="Path_193"
        data-name="Path 193"
        d="M-6642.2-5396.682c-1.456-3.194-3.048-6.335-4.318-9.6-.833-2.142-.769-4.511,1.283-6.011,1.972-1.438,4.095-.667,5.9.6a4.4,4.4,0,0,1,1.587,5.8c-1.272,3.082-2.5,6.182-3.748,9.273Z"
        transform="translate(6705.32 5422.715)"
        fill="#fff"
      />
      <path
        id="Path_194"
        data-name="Path 194"
        d="M-6668.135-5396.4c-1.342-3.761-2.907-7.461-3.943-11.3-.65-2.41,2.209-4.9,5.183-5.015,2.8-.108,4.383,1.423,4.29,4.369a9.5,9.5,0,0,1-.751,3.438c-1.237,2.832-2.64,5.592-3.978,8.382Z"
        transform="translate(6717.681 5422.545)"
        fill="#fff"
      />
      <path
        id="Path_195"
        data-name="Path 195"
        d="M-6599.176-5392.314c-1.085-4.147-2.53-8.244-3.117-12.458a18.282,18.282,0,0,1,1.125-7.833c.726-2.223,2.706-2.189,3.952-.166a9.835,9.835,0,0,1,1.02,2.582C-6595.077-5406.083-6596.367-5396.345-6599.176-5392.314Zm-1.688-15.538-1.087.2.94,6.3.771-.091Z"
        transform="translate(6683.388 5423.311)"
        fill="#fff"
      />
      <path
        id="Path_196"
        data-name="Path 196"
        d="M-6621.292-5395.114c-1.05-3.7-2.238-7.37-3.091-11.115-.408-1.784.117-3.918,2.023-4.463a7.662,7.662,0,0,1,4.936.528c1.922.9,2.244,2.914,1.429,4.845-1.462,3.467-3.053,6.878-4.589,10.312Z"
        transform="translate(6694.259 5421.647)"
        fill="#fff"
      />
      <path
        id="Path_197"
        data-name="Path 197"
        d="M-6688.133-5395.033c-1.586-3.585-3.265-7.133-4.719-10.77a3.287,3.287,0,0,1,1.526-4.377,6.892,6.892,0,0,1,4.892-.44c1.055.441,1.44,2.506,2.09,3.864a1.158,1.158,0,0,1,.013.75q-1.581,5.41-3.191,10.81Z"
        transform="translate(6727.988 5421.65)"
        fill="#fff"
      />
      <path
        id="Path_202"
        data-name="Path 202"
        d="M-6760.052-5424.557l-.333-3.26-1.231,1.633a14.1,14.1,0,0,1,.02-2.41,4.214,4.214,0,0,1,4.654-3.437l-1.852,2.255a41.682,41.682,0,0,1,6.452.078,15.634,15.634,0,0,1,4.619,1.9c1.974,1.06,3.844,2.312,5.95,3.6-1.2.268-2.41.539-3.776.842l-5.7-4-.295.254.559,1.057C-6753.972-5425.7-6757.262-5428.162-6760.052-5424.557Z"
        transform="translate(6761.66 5432.036)"
        fill="#fff"
      />
      <path
        id="Path_205"
        data-name="Path 205"
        d="M-6577.445-5424.152c2-1.187,3.809-2.225,5.577-3.318,2.3-1.424,4.524-3.157,7.566-1.6l-.752-.876,4.626.257-1.68-2.1c3.057-.43,5,2.107,4.186,5.653l-.775-1.59c-.143,1.1-.244,1.875-.4,3.074-2.239-2.728-4.785-1.686-7.3-1.287-.471.073-1.042-.487-2.146-1.052-1.3.952-3.177,2.329-5.071,3.72Z"
        transform="translate(6671.129 5431.94)"
        fill="#fff"
      />
      <path
        id="Path_207"
        data-name="Path 207"
        d="M-6736.769-5405.176c-1.329-3.6-1.175-7.017,1.065-10.178C-6733.047-5411.591-6736.365-5408.562-6736.769-5405.176Z"
        transform="translate(6749.843 5423.838)"
        fill="#fff"
      />
      <path
        id="Path_208"
        data-name="Path 208"
        d="M-6559.1-5406.284c.138-2.593.267-4.438.317-6.286.013-.477-.307-.958-.314-1.439a4.737,4.737,0,0,1,.249-2.242c.237-.43,1.118-.5,1.712-.735a8.368,8.368,0,0,1,.665,2.006A12.173,12.173,0,0,1-6559.1-5406.284Z"
        transform="translate(6662.118 5424.64)"
        fill="#fff"
      />
      <path
        id="Path_209"
        data-name="Path 209"
        d="M-6749.636-5405.582c-2.411-3.4-2.686-6.957-2.4-10.61.029-.366.736-.679,1.128-1.016.267.353.724.682.77,1.061q.533,4.429.912,8.875A6.37,6.37,0,0,1-6749.636-5405.582Z"
        transform="translate(6756.975 5424.749)"
        fill="#fff"
      />
      <path
        id="Path_210"
        data-name="Path 210"
        d="M-6745.922-5417c2.722.328,2.733.562,1.887,2.943-.324.912.233,2.124.332,3.206.114,1.236.163,2.479.278,4.308C-6746.469-5409.958-6746.42-5413.4-6745.922-5417Z"
        transform="translate(6754.051 5424.647)"
        fill="#fff"
      />
      <path
        id="Path_211"
        data-name="Path 211"
        d="M-6565.755-5405.349c-1-3.245-3.242-6.334-1.3-9.981C-6564.478-5412.337-6565-5408.863-6565.755-5405.349Z"
        transform="translate(6666.4 5423.826)"
        fill="#fff"
      />
      <path
        id="Path_214"
        data-name="Path 214"
        d="M-6552.3-5417.94c.756.663,1.618,1.052,1.653,1.507.285,3.615-.178,7.105-2.717,10.171C-6553.03-5409.94-6552.7-5413.618-6552.3-5417.94Z"
        transform="translate(6659.295 5425.109)"
        fill="#fff"
      />
      <path
        id="Path_216"
        data-name="Path 216"
        d="M-6710.9-5416.253h-3.186c.129.437.365,1.235.75,2.536l-2.952-3.326h5.4Z"
        transform="translate(6739.359 5424.668)"
        fill="#fff"
      />
      <path
        id="Path_218"
        data-name="Path 218"
        d="M-6593.027-5415.239l-1.811-1.73h5.774l-2.594,2.268.42-1.911Z"
        transform="translate(6679.676 5424.632)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const BottomTeeth = () => (
  <svg
    className="bottomTeeth"
    xmlns="http://www.w3.org/2000/svg"
    width="90%"
    height="37.079"
    viewBox="0 0 101.143 37.079"
  >
    <g id="Group_33" data-name="Group 33" transform="translate(0 0)">
      <path
        id="Path_186"
        data-name="Path 186"
        d="M-6653.824-5269.822q-.551,2.527-1.1,5.053l.456.221c.869-1.859,1.737-3.719,2.687-5.751.123,1.074.227,1.973.329,2.874l.446-.006v-5.993c.942,1.1,1.581,1.845,2.49,2.9-1.668,3.3-3.428,6.783-5.188,10.267l-.673-.309c.235-.73.472-1.459.816-2.529-3.73,3.518-3.73,3.517-8.174,1.5a7.067,7.067,0,0,0-2.738-.783l2.324,1.851-.2.44-5.106-2.207-.248.325,2.4,2.207c-1.934-.923-4.829.063-5.7-2.863-.033-.113-.444-.109-.678-.17l-8.073-2.123.958-1.152c-4.414-.452-9.045-1.292-13.678-1.294-4.677,0-9.355.823-14.167,1.3.05.174.163.569.316,1.107l-8.282,2.6c-1.41,2.21-3.957,2-6.031,2.73q1.27-1.176,2.539-2.354l-.167-.291-5.719,2.394-.233-.34,2.307-1.8-.18-.355c-1.289.534-2.784.813-3.823,1.654-2.157,1.75-3.48.654-4.819-.961a3.207,3.207,0,0,0-1.427-1.182l.891,2.424-.638.355-5.426-10.3c.785-.984,1.376-1.728,2.4-3.007v5.8l.615-.007c.1-.8.191-1.6.3-2.495.943,1.937,1.817,3.729,2.692,5.523l.429-.208q-.576-2.521-1.154-5.043c3,3.009,5.264,7.163,10.772,4.46l-1.836,1.71.374.456c1.447-1.272,2.895-2.543,4.537-3.985,2.546,1.594,4.714.148,7.137-2.392-.393,1.338-.624,2.124-.854,2.91l.295.118,2.293-3.408.46,1.823.5-1.412h4.9l0-.381-3.828-.887,6.832-1.619-.17-.561,3.743,2.178-1.137.723.245.438,4.787-2.285,5.086,2.5.28-.507-1.233-.876,2.969-1.709,7.185,1.7-3.363.927-.019.258h4.849c.048.229.145.685.3,1.4l.48-.856,4.485,4.53.335-.337-3.3-3.913.5-.576c2.012,2.1,4.169,3.634,6.778,2.145l4.55,4.22.438-.454c-.791-.644-1.579-1.289-2.433-1.985,2.464,0,4.628.481,6.417-.131,1.9-.65,3.438-2.339,5.136-3.575Z"
        transform="translate(6743.457 5296.697)"
        fill="#fff"
      />
      <path
        id="Path_187"
        data-name="Path 187"
        d="M-6586.679-5319.186c2.515,7.832,3.964,15.084,1.554,22.506a19.715,19.715,0,0,1-3.285,5.914c-1.86,2.368-4.489,1.922-5.938-.737-1.688-3.1-1.1-6.477-.08-9.366C-6592.3-5306.9-6589.481-5312.681-6586.679-5319.186Zm-.91,24.062.857.261q1.165-3.885,2.328-7.772l-.708-.213Z"
        transform="translate(6673.711 5319.186)"
        fill="#5396c6"
      />
      <path
        id="Path_188"
        data-name="Path 188"
        d="M-6724.066-5318.077c2.5,5.872,5.306,11.641,7.37,17.663a16.423,16.423,0,0,1,.2,8.622c-.858,3.678-4.322,4.1-6.637,1.1-4.108-5.341-4.411-11.571-3.671-17.884a93.685,93.685,0,0,1,2.083-9.51Zm-.164,24.065.861-.26q-1.38-4.334-2.759-8.667l-.655.2Q-6725.506-5298.378-6724.23-5294.012Z"
        transform="translate(6738.437 5318.648)"
        fill="#5396c6"
      />
      <path
        id="Path_198"
        data-name="Path 198"
        d="M-6628.626-5306.73c1.332,4.628,2.639,8.759,3.68,12.954a3.423,3.423,0,0,1-2.938,4.451c-2.451.383-4.326-.843-4.176-3.419a60.922,60.922,0,0,1,1.686-9.552A32.116,32.116,0,0,1-6628.626-5306.73Z"
        transform="translate(6691.725 5313.066)"
        fill="#fff"
      />
      <path
        id="Path_199"
        data-name="Path 199"
        d="M-6677.355-5305.993c.852,4.312,1.943,8.594,2.476,12.944.36,2.939-1.407,4.4-4.021,4.106-2.377-.271-3.689-2.071-3.018-4.83.993-4.076,2.31-8.073,3.489-12.1Z"
        transform="translate(6716.311 5312.703)"
        fill="#fff"
      />
      <path
        id="Path_200"
        data-name="Path 200"
        d="M-6607.962-5295.242c3.591,3.994,3.4,10.739,1.147,14.467-1.312,2.167-3.181,2.232-4.63.146a6.494,6.494,0,0,1-.512-6.729C-6610.717-5290.022-6609.307-5292.607-6607.962-5295.242Z"
        transform="translate(6682.187 5307.419)"
        fill="#fff"
      />
      <path
        id="Path_201"
        data-name="Path 201"
        d="M-6699.2-5295.458c1.383,2.737,2.762,5.258,3.944,7.87a6.47,6.47,0,0,1-.512,6.93c-1.541,2.035-3.318,1.854-4.64-.321C-6702.8-5284.924-6702.3-5291.5-6699.2-5295.458Z"
        transform="translate(6726.05 5307.526)"
        fill="#fff"
      />
      <path
        id="Path_203"
        data-name="Path 203"
        d="M-6659.809-5301.091c.888,3,1.806,6,2.635,9.023a2.275,2.275,0,0,1-.161,1.726c-.945,1.27-1.857,2.959-3.172,3.45-1.379.517-3.515-2.453-3.122-4.469.635-3.262,1.658-6.448,2.52-9.666Z"
        transform="translate(6707.259 5310.293)"
        fill="#fff"
      />
      <path
        id="Path_204"
        data-name="Path 204"
        d="M-6645.188-5302.465c.993,3.669,1.839,6.662,2.593,9.678a2.719,2.719,0,0,1-.105,1.968,39.383,39.383,0,0,1-2.983,3.4c-.959-.966-2.568-1.83-2.737-2.917a17.488,17.488,0,0,1,.34-6.656C-6647.667-5298.712-6646.417-5300.225-6645.188-5302.465Z"
        transform="translate(6699.849 5310.97)"
        fill="#fff"
      />
      <path
        id="Path_206"
        data-name="Path 206"
        d="M-6557.253-5311.462c3.089,2.88,4.355,9.862,2.4,12.416C-6553.885-5303.338-6558.469-5306.624-6557.253-5311.462Z"
        transform="translate(6655.06 5316.768)"
        fill="#fff"
      />
      <path
        id="Path_212"
        data-name="Path 212"
        d="M-6741.6-5305.412c-.419,8.563-.464,8.73-3.048,10.693C-6744.6-5298.869-6744.042-5302.39-6741.6-5305.412Z"
        transform="translate(6747.055 5313.685)"
        fill="#fff"
      />
      <path
        id="Path_213"
        data-name="Path 213"
        d="M-6748.625-5299.019c-1.634-2.881-.516-9.3,2.551-12.8.124,1.458.472,2.414.22,3.175-.651,1.981-1.681,3.839-2.332,5.821A19.186,19.186,0,0,0-6748.625-5299.019Z"
        transform="translate(6749.375 5316.936)"
        fill="#fff"
      />
      <path
        id="Path_215"
        data-name="Path 215"
        d="M-6561.735-5306.777a14.857,14.857,0,0,1,3.008,11.048C-6562.28-5298.667-6561.567-5302.446-6561.735-5306.777Z"
        transform="translate(6657.162 5314.381)"
        fill="#fff"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M-6736.921-5281.657c-2.886-1.194-1.81-3.214-1.585-5.083l.656-.121Q-6737.384-5284.26-6736.921-5281.657Z"
        transform="translate(6744.221 5303.302)"
        fill="#fff"
      />
      <path
        id="Path_219"
        data-name="Path 219"
        d="M-6564.922-5281.867q.463-2.713.929-5.425C-6563.1-5285.285-6562.272-5283.3-6564.922-5281.867Z"
        transform="translate(6658.727 5303.514)"
        fill="#fff"
      />
    </g>
  </svg>
);
