export const NcaafDepthTableList = [
  {
    mainHeading: "3WR 1TE",
    tableContent: [
      {
        srNo: "QB",
        starter: "Josh Allen",
        secondd: "Matt Barkley",
        thirdd: "Jake Fromm",
        fourthh: "",
      },
      {
        srNo: "RB",
        starter: "Devin Singletary",
        secondd: "Zack Moss",
        thirdd: "T.J. Yeldon",
        fourthh: "Taiwan Jones ",
        RedValue: "Q",
        index: "fourthh",
      },
      {
        srNo: "WR",
        starter: "Stefon Diggs",
        secondd: "Gabriel Davis",
        thirdd: "Isaiah Hodgins",
        fourthh: "",
        RedValue: "IR",
        index: "thirdd",
        supSecondd: "CINCINNATI BEARCATS",
      },
      {
        srNo: "WR",
        starter: "John Brown",
        secondd: "Isaiah McKenzie",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "WR",
        starter: "Cole Beasley",
        secondd: "Andre Roberts",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "TE",
        starter: "Tyler Kroft",
        secondd: "Lee Smith",
        thirdd: "Dawson Knox",
        fourthh: "Tommy Sweeney",
        RedValue: "O",
        index: "thirdd",
        index2: "fourthh",
      },
      {
        srNo: "LT",
        starter: "Dion Dawkins",
        secondd: "Ty Nsekhe",
        thirdd: "Ryan Bates",
        fourthh: "",
      },
      {
        srNo: "LG",
        starter: "Jon Feliciano",
        secondd: "Cody Ford",
        thirdd: "Ike Boettger",
        fourthh: "",
        index: "secondd",
        RedValue: "O",
      },
      {
        srNo: "C",
        starter: "Mitch Morse ",
        secondd: "Ryan Bates",
        thirdd: "",
        fourthh: "",
        index: "starter",
        RedValue: "Q",
      },
      {
        srNo: "RG",
        starter: "Brian Winters",
        secondd: "Daryl Williams",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "RT",
        starter: "Daryl Williams",
        secondd: "Ty Nsekhe",
        thirdd: "Cody Ford ",
        fourthh: "",
        index: "thirdd",
        RedValue: "O",
      },
    ],
  },
  {
    mainHeading: "Base 4-3 D",
    tableContent: [
      {
        srNo: "LDE",
        starter: "Trent Murphy",
        secondd: "Darryl Johnson",
        thirdd: "AJ Epenesa",
        fourthh: "",
      },
      {
        srNo: "LDE",
        starter: "Vernon Butler",
        secondd: "Harrison Phillips",
        thirdd: "Brandin Bryant",
        fourthh: "Star Lotulelei ",
        RedValue: "O",
        index: "starter",
        index2: "fourthh",
      },
      {
        srNo: "RDT",
        starter: "Ed Oliver",
        secondd: "Quinton Jefferson",
        thirdd: "Justin Zimmer",
        fourthh: "",
        RedValue: "",
        index: "",
      },
      {
        srNo: "RDE",
        starter: "Jerry Hughes",
        secondd: "Mario Addison",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "WLB",
        starter: "Matt Milano",
        secondd: "Andre Smith",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "MLB",
        starter: "Tremaine Edmunds",
        secondd: "Tyler Matakevich",
        thirdd: "Tyrel Dodson ",
        fourthh: "",
        RedValue: "IR",
        index: "thirdd",
        index2: "",
      },
      {
        srNo: "SLB",
        starter: "A.J. Klein",
        secondd: "Del'Shawn Phillips ",
        RedValue: "IR",
        index: "secondd",
        thirdd: "",
        fourthh: "",
      },
      {
        srNo: "LCB",
        starter: "Tre'Davious White",
        secondd: "Taron Johnson",
        thirdd: "Cam Lewis ",
        fourthh: "E.J. Gaines ",
        RedValue: "IR",
        index: "thirdd",

        RedValue2: "O",
        index3: "fifthh",
      },
      {
        srNo: "SS",
        starter: "Jordan Poyer",
        secondd: "Dean Marlowe",
      },
      {
        srNo: "FS",
        starter: "Micah Hyde ",
        secondd: "Jaquan Johnson",
        thirdd: "Josh Thomas",
        RedValue: "O",
        index: "starter",
      },
      {
        srNo: "RCB",
        starter: "Levi Wallace",
        secondd: "Josh Norman",
        thirdd: "Siran Neal",
        fourthh: "Josh Norman",
        index: "secondd",
        index2: "fourthh",
        RedValue: "O",
      },
    ],
  },
  {
    mainHeading: "Special Teams",
    tableContent: [
      {
        srNo: "PK",
        starter: "Tyler Bass",
      },
      {
        srNo: "P",
        starter: "Corey Bojorquez",
      },
      {
        srNo: "H",
        starter: "Corey Bojorquez",
      },
      {
        srNo: "PR",
        starter: "Andre Roberts",
        secondd: "Isaiah McKenzie",
      },
      {
        srNo: "KR",
        starter: "Andre Roberts",
        secondd: "Isaiah McKenzie",
      },
      {
        srNo: "LS",
        starter: "Reid Ferguson",
        secondd: "Lee Smith",
      },
    ],
  },
];
