import standingAAImg1 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg1.png";
import standingAAImg2 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg2.png";
import standingAAImg3 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg3.png";
import standingAAImg4 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg4.png";
import standingAAImg5 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg5.png";
import standingAAImg6 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg6.png";
import standingAAImg7 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg7.png";
import standingAAImg8 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg8.png";
import standingAAImg9 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg9.png";
import standingAAImg10 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg10.png";
import standingAAImg11 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg11.png";
import standingAAImg12 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg12.png";
import standingAAImg13 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg13.png";
//
import standingAAImg14 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg14.png";
import standingAAImg15 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg15.png";
import standingAAImg16 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg16.png";
import standingAAImg17 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg17.png";
import standingAAImg18 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg18.png";
import standingAAImg19 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg19.png";
import standingAAImg20 from "../../NcaafFrontAssets/HomepageAssets/img/standingAAImg20.png";

export const BigSkyStandingList = [
  {
    standingTableHeading: "Big Sky Conference",
    tableBodyList: [
      {
        teamImgUrl: standingAAImg1,
        teamName: "Cal Poly Mustangs",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg2,
        teamName: "Eastern Washington Eagles",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg3,
        teamName: "Idaho State Bengals",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg4,
        teamName: "Idaho Vandals",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg5,
        teamName: "Montana Grizzlies",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg6,
        teamName: "Montana State Bobcats",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg7,
        teamName: "Northern Arizona Lumberjacks",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg8,
        teamName: "Northern Colorado Bears",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg9,
        teamName: "Portland State Vikings",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg10,
        teamName: "Sacramento State Hornets",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg11,
        teamName: "Southern Utah Thunderbirds",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg12,
        teamName: "UC Davis Aggies",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg13,
        teamName: "Weber State Wildcats",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
    ],
  },
  {
    standingTableHeading: "Big Sky Conference",
    tableBodyList: [
      {
        teamImgUrl: standingAAImg14,
        teamName: "Charleston Southern Buccaneers",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg15,
        teamName: "Gardner-Webb Bulldogs",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg16,
        teamName: "Hampton Pirates",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg17,
        teamName: "Kennesaw State Owls",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg18,
        teamName: "Monmouth Hawks",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-0",
        pf: "0",
        pa: "0",
        strk: "",
      },
      {
        teamImgUrl: standingAAImg19,
        teamName: "North Alabama Lions",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-3",
        pf: "37",
        pa: "76",
        strk: "L3",
      },
      {
        teamImgUrl: standingAAImg20,
        teamName: "Campbell Fighting Camels",
        win: "0-0",
        lose: "0",
        tie: "0",
        wl: "0-4",
        pf: "82",
        pa: "188",
        strk: "L4",
      },
    ],
  },
];

export const glossaryList = [
  {
    glosaaryTitle: "GLOSSARY",
    glossaryListData: [
      {
        glossaryItemName: "W-L",
        glossaryItemData: "Conference Record",
      },
      {
        glossaryItemName: "PF",
        glossaryItemData: "Total Points For",
      },
    ],
  },
  {
    glosaaryTitle: "",
    glossaryListData: [
      {
        glossaryItemName: "PA",
        glossaryItemData: "Total Points Against",
      },
      {
        glossaryItemName: "STRK",
        glossaryItemData: "Current Streak",
      },
    ],
  },
];
