import teamDetailLeaderOne from "../../Assets/img/team-detail-leader-img.svg";
import VideoCardImg from "../../Assets/img/team-detail-videos-players-img.png";
import playbuttonImg from "../../Assets/img/playbutton.png";
import pausebuttonImg from "../../Assets/img/pause-btn.png";

import newsimg1 from "../../components/Navbar/assets/news1.png";
import newsimg2 from "../../components/Navbar/assets/news2.png";

export const NcaafTeamDetailLeaderList = [
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
  {
    cardtitle: "Passing Yards ",
    playerImage: teamDetailLeaderOne,
    playerName: "Josh Allen, QB",
    playerNumber: "2,018",
  },
];
export const aboutTeamPlayerBioList = [
  {
    descriptionHeading: "Passing yards",
    numberUrl: `203.2`,
    description: "91st",
  },
  {
    descriptionHeading: "Rushing yards",
    numberUrl: `238.5`,
    description: "15th",
  },
  {
    descriptionHeading: "Points for",
    numberUrl: `39.3`,
    description: "17th",
  },
  {
    descriptionHeading: "Points for against",
    numberUrl: `11.7`,
    description: "119th",
  },
];
export const prodcastVideosList = [
  {
    imgUrl1: VideoCardImg,
    imgUrl2: playbuttonImg,
    imgUrl3: pausebuttonImg,
    audioName: "F Your Purse",
    audioplayerTiming: 0,
  },
];
export const teamPlayerNewsArray = [
  {
    id: 1,
    heading: "Matchup Previews + Week 5",
    description: `Injury Analysis with Dr. David Chao`,
    match: `(2020 Fantasy Football)`,
    imagePath: newsimg1,
  },
  {
    id: 2,
    heading: "Matchup Previews + Week 5",
    description: `Injury Analysis with Dr. David Chao`,
    match: `(2020 Fantasy Football)`,
    imagePath: newsimg2,
  },
];
