import React from "react";
import FantasyStats from "../components/fantacyFootballStats/FantasyStats";

const FantasyFootballStats = () => {
  return (
    <>
      <FantasyStats />
    </>
  );
};

export default FantasyFootballStats;
