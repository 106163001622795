import React, { useState } from "react";
import ProdcastPauseContent from "./ProdcastPauseContent";
import { Link } from "react-router-dom";
import objectImg from "../../Assets/images/object.png";
import dnldImg from "../../Assets/images/dnld.png";
import heartImg from "../../Assets/images/heart.png";
import soundImg from "../../Assets/images/sound.png";
import clockImg from "../../Assets/images/clock.png";
import pauseImg from "../../Assets/images/pause.png";
import Videos from "../homepage/Videos"
import ArrowIcon from "../../../src/components/Navbar/assets/arrowicon.png";
import AudioPodcast from "../homepage/AudioPodcast"

const NcaafPodcastListItem = ({ index,podcastRow }) => {
  const [isPlay, setPlay] = useState(false);


  const str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}
const timeUpdate = (event) => {
    var audioFileId = event.target.id;

    var audioFileIdIndex = audioFileId.split("__")[1];

    const minutes = Math.floor(event.target.currentTime / 60);
    const seconds = Math.floor(event.target.currentTime - minutes * 60);
    const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

    document.getElementById("audioStartTime__" + audioFileIdIndex).innerHTML = currentTime;

    const m = Math.floor(event.target.duration / 60);
    const s = Math.floor(event.target.duration - m * 60);
    const duration = str_pad_left(m, '0', 2) + ':' + str_pad_left(s, '0', 2);

    document.getElementById("duration" + audioFileIdIndex).innerHTML = duration;

    const remain = currentTime.split(":")[1];
    const remain1 = duration.split(":")[1];

    const remain3 = currentTime.split(":")[0];
    const remain4 = duration.split(":")[0];

    const final = remain1 - remain;
    const final1 = remain4 - remain3;


    document.getElementById("remain" + audioFileIdIndex).innerHTML = "remaining time is " + "0" + final1 + ":" + final;

}
  const togglePlaying = (e, index) => {

    const myAudio = document.getElementById(index);
    if (myAudio.paused) {
        myAudio.play();
    } else {
        myAudio.pause();
    }
}
  return (
    <div>
      {/* <div className="col-md-2 col-12 d-flex justify-content-center justify-content-md-start  align-items-center align-items-md-start">
        <div
          className={`position-relative mw-custom ${isPlay && "bg-overlay"} `}
        >
          <img className="w-100" src={obj.imgUrl1} alt="readPause-img" />
          <img
            onClick={() => setPlay(!isPlay)}
            className={`prodcast-pause-img ${isPlay && "on-audio-player"}`}
            src={obj.imgUrl2}
            alt="pausebuttonImg"
          />
        </div>
      </div> */}
                                    <AudioPodcast viewOn="ncaaf" />

      {/* <div className="col-md-10 ps-md-5 col-12 d-flex flex-column justify-content-center mt-5 mt-md-0">
        <ProdcastPauseContent obj={podcastRow} isPlay={isPlay} />
      </div> */}
      <p className="text-end pb-4 z-index-20 position-relative white font-web font-semibold para respLeft" >
                            <Link to={`/viewAllNcaafPodcast`} className="cursor-pointer viewAllLink">
                                VIEW ALL NCAAF PODCASTS
                                <span className="ms-3"><img src={ArrowIcon} alt="ArrowIcon" /></span>
                            </Link>
                        </p>
    </div>
  );
};

export default NcaafPodcastListItem;
