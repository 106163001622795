import React, { useState, useEffect } from "react";
import { gameLogDataFirst, gameLogDataSecond } from "./Helper";
import { showDate } from "../../functions/GeneralFn";

import { ColorRing } from 'react-loader-spinner';
import { useLocation, useHistory } from "react-router-dom";
import { ncaaPlayerGameLogApi } from "../../service/NcaaService";
import ColorRingCustomLoader from "../common/ColorRingCustomLoader";


const GameLogTableNcaa = ({ playerId, playerDetails }) => {
  const location = useLocation();

  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const [gameLogData, set_gameLogData] = useState({});
  const [isLoader, set_isLoader] = useState(true);
  const [yearNo, set_yearNo] = useState((queryParams.get("year") == null) ? 2023 : queryParams.get("year"));

  useEffect(() => {
    getNcaaPlayerGameLog();
  }, [playerId, yearNo]);


  async function getNcaaPlayerGameLog() {
    ncaaPlayerGameLogApi(playerId, yearNo).then(function (result) {
      const response_data = result.data.response_data;
      set_gameLogData(response_data);
      set_isLoader(false)
    });
  }
  // console.log('gameLogData', gameLogData);


  const currentYear = new Date().getFullYear();
  let yearDropdown = [];
  for (let i = currentYear; i > (currentYear - 2); i--) {
    yearDropdown.push(<option value={i} key={i}  >{i}</option>);
  }

  const onChageDropdown = (e) => {
    // var team = document.getElementById("teamDropdown").value;
    var yearSelect = document.getElementById("yearDropdown").value;
    history.push(`/ncaaf/player-profile/${playerId}?year=${yearSelect}`);
    // set_teamId(team);
    set_yearNo(yearSelect);
  }

  const cmpPercentage = (fractionString) => {
    if (fractionString && fractionString != '') {
      const [numerator, denominator] = fractionString.split('/');
      const fraction = parseFloat(numerator) / parseFloat(denominator);
      const percentage = fraction * 100;
      return `${percentage.toFixed(2)}`;
    } else {
      return `--`;
    }
  }


  return (
    <>
      <section className="py-5">
        <div className="container my-5 py-5 gamelog-table">
          <div className="row align-items-lg-end">
            <div className="col-lg-4">
              <div className="gameblog-headings">
                <h5 className="font-22 white p-3 ms-4 ps-4 mb-0 text-uppercase fw-semibold">
                  Game Log
                </h5>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="d-flex justify-content-end mt-2 mt-lg-0 mb-2">
                {/* <select
                  className="form-select form-select-sm ps-4 py-4 font-16 gamelog-select"
                  aria-label=".form-select-sm example"
                >
                  <option selected>Season</option>
                  <option value="Winter">Winter</option>
                  <option value="Spring">Spring</option>
                  <option value="Summer">Summer</option>
                  <option value="Monsoon">Monsoon</option>
                </select> */}
                <select
                  className="form-select form-select-sm ms-3 ps-4 py-4 font-16 gamelog-select"
                  aria-label=".form-select-sm example"
                  onChange={onChageDropdown}
                  id="yearDropdown" value={yearNo}
                >
                  {yearDropdown}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {gameLogDataFirst.map((val, index) => {
                        return (
                          <th
                            className={`${index === 0
                              ? "font-16 fw-semibold text-nowrap"
                              : index === 1
                                ? "font-16 fw-semibold text-nowrap"
                                : "font-16 fw-semibold border-1-black text-center  text-nowrap"
                              }`}
                            scope="col"
                            colSpan={
                              index === 0
                                ? "7"
                                : index === 1
                                  ? "6"
                                  : index === 2
                                    ? "5"
                                    : null
                            }
                            key={index}
                          >
                            {index === 0 && yearNo}
                            {val.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <thead>
                    <tr className="tablehead-tr">
                      {gameLogDataSecond.map((val, index) => {
                        return (
                          <th
                            className={`${index === 0
                              ? "font-16 fw-semibold ps-4"
                              : index === 1
                                ? "font-16 fw-semibold px-3"
                                : index === 12
                                  ? "font-16 fw-semibold px-4 tr-border-left"
                                  : ""
                              } font-16 fw-semibold px-3`}
                            scope="col"
                            colSpan={
                              index === 0
                                ? "1"
                                : index === 1
                                  ? "1"
                                  : index === 2
                                    ? "2"
                                    : null
                            }
                            key={index}
                          >
                            {val.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (isLoader) ?
                        <tr>
                          <td colSpan={20}><ColorRingCustomLoader isLoader={isLoader} /></td>
                        </tr>
                        :
                        <>
                          {gameLogData && gameLogData.length > 0 && gameLogData.map((val, index) => {
                            return (
                              <tr key={index} className="gamelog-tr">
                                <td className="font-16 fw-light color-grey">
                                  {showDate(val.matchDate, 'MM-dd')}
                                </td>
                                <td className="font-18 fw-light color-grey">
                                  vs
                                  <>
                                    <span className="mx-3">
                                      {
                                        (playerDetails.team_id == val.awayTeamDetails.team_id) ?
                                          <img src={val.homeTeamDetails.logo_standard} alt={val.homeTeamDetails.team_code} width={'30px'} />
                                          :
                                          <img src={val.awayTeamDetails.logo_standard} alt={val.awayTeamDetails.team_code} width={'30px'} />
                                      }
                                    </span>
                                  </>
                                </td>
                                <td className="font-16 fw-light blue" colSpan="2">
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {
                                    (playerDetails.team_id == val.awayTeamDetails.team_id) ?
                                      <>
                                        <span className={`color-red pe-2 ${val.awayTeamScore.totalscore > val.homeTeamScore.totalscore ? 'color-green' : ''}`} >
                                          {val.awayTeamScore.totalscore > val.homeTeamScore.totalscore ? 'W' : 'L'}
                                        </span>

                                        {val.awayTeamScore.totalscore}-{val.homeTeamScore.totalscore}
                                      </>
                                      :
                                      <>
                                        <span className={`color-red pe-2 ${val.awayTeamScore.totalscore < val.homeTeamScore.totalscore ? 'color-green' : ''}`} >
                                          {val.awayTeamScore.totalscore < val.homeTeamScore.totalscore ? 'W' : 'L'}
                                        </span>

                                        {val.homeTeamScore.totalscore}-{val.awayTeamScore.totalscore}
                                      </>
                                  }
                                </td>

                                {
                                  (playerDetails?.team_id == val?.awayTeamDetails?.team_id) ?
                                    <>
                                      <td className="font-16 fw-light color-grey">{val?.passing?.awayteam?.player?.length > 1 ? val?.passing?.awayteam?.player[0]?.comp_att : val?.passing?.awayteam?.player?.comp_att}</td>
                                      {/* <td className="font-16 fw-light p-0 color-grey">--</td> */}
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.awayteam?.player?.length > 1 ? val?.passing?.awayteam?.player[0]?.yards : val?.passing?.awayteam?.player?.yards}</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.awayteam?.player?.length > 1 ? cmpPercentage(val?.passing?.awayteam?.player[0]?.comp_att) : cmpPercentage(val?.passing?.awayteam?.player?.comp_att)}
                                      </td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.awayteam?.player?.length > 1 ? val?.passing?.awayteam?.player[0]?.average : val?.passing?.awayteam?.player?.average}</td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.awayteam?.player?.length > 1 ? val?.passing?.awayteam?.player[0]?.passing_touch_downs : val?.passing?.awayteam?.player?.passing_touch_downs}</td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.awayteam?.player?.length > 1 ? val?.passing?.awayteam?.player[0]?.interceptions : val?.passing?.awayteam?.player?.interceptions}</td>
                                      {/* <td className="font-16 fw-light p-0 color-grey">--</td> */}
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.awayteam?.player?.length > 1 ?
                                          (val?.passing?.awayteam?.player[0]?.sacks >= 1 ? val?.passing?.awayteam?.player[0]?.sacks : 0) :
                                          (val?.passing?.awayteam?.player?.sacks >= 1 ? val?.passing?.awayteam?.player?.sacks : 0)
                                        }</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.awayteam?.player?.length > 1 ?
                                          (val?.passing?.awayteam?.player[0]?.rating >= 1 ? val?.passing?.awayteam?.player[0]?.rating : 0) :
                                          (val?.passing?.awayteam?.player?.rating >= 1 ? val?.passing?.awayteam?.player?.rating : 0)
                                        }</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.awayteam?.player?.length > 1 ?
                                          (val?.passing?.awayteam?.player[0]?.rating >= 1 ? val?.passing?.awayteam?.player[0]?.rating : 0) :
                                          (val?.passing?.awayteam?.player?.rating >= 1 ? val?.passing?.awayteam?.player?.rating : 0)
                                        }
                                      </td>
                                    </>
                                    :
                                    <>
                                      <td className="font-16 fw-light color-grey">{val?.passing?.hometeam?.player?.length > 1 ? val?.passing?.hometeam?.player[0]?.comp_att : val?.passing?.hometeam?.player?.comp_att}</td>
                                      {/* <td className="font-16 fw-light p-0 color-grey">--</td> */}
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.hometeam?.player?.length > 1 ? val?.passing?.hometeam?.player[0]?.yards : val?.passing?.hometeam?.player?.yards}</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.hometeam?.player?.length > 1 ? cmpPercentage(val?.passing?.hometeam?.player[0]?.comp_att) : cmpPercentage(val?.passing?.hometeam?.player?.comp_att)}
                                      </td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.hometeam?.player?.length > 1 ? val?.passing?.hometeam?.player[0]?.average : val?.passing?.hometeam?.player?.average}</td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.hometeam?.player?.length > 1 ? val?.passing?.hometeam?.player[0]?.passing_touch_downs : val?.passing?.hometeam?.player?.passing_touch_downs}</td>
                                      <td className="font-16 fw-light p-0 color-grey">{val?.passing?.hometeam?.player?.length > 1 ? val?.passing?.hometeam?.player[0]?.interceptions : val?.passing?.hometeam?.player?.interceptions}</td>
                                      {/* <td className="font-16 fw-light p-0 color-grey">--</td> */}
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.hometeam?.player?.length > 1 ?
                                          (val?.passing?.hometeam?.player[0]?.sacks >= 1 ? val?.passing?.hometeam?.player[0]?.sacks : 0) :
                                          (val?.passing?.hometeam?.player?.sacks >= 1 ? val?.passing?.hometeam?.player?.sacks : 0)
                                        }</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.hometeam?.player?.length > 1 ?
                                          (val?.passing?.hometeam?.player[0]?.rating >= 1 ? val?.passing?.hometeam?.player[0]?.rating : 0) :
                                          (val?.passing?.hometeam?.player?.rating >= 1 ? val?.passing?.hometeam?.player?.rating : 0)
                                        }</td>
                                      <td className="font-16 fw-light p-0 color-grey">
                                        {val?.passing?.hometeam?.player?.length > 1 ?
                                          (val?.passing?.hometeam?.player[0]?.rating >= 1 ? val?.passing?.hometeam?.player[0]?.rating : 0) :
                                          (val?.passing?.hometeam?.player?.rating >= 1 ? val?.passing?.hometeam?.player?.rating : 0)
                                        }</td>
                                    </>}

                                {(val?.rushing1) ? <>
                                  <td className="font-16 fw-light p-0 color-grey tr-border-left">{val?.rushing1?.total_rushes}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing1?.yards}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing1?.average}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing1?.rushing_touch_downs}</td>
                                  <td className="font-16 fw-light p-0 color-grey ps-4 pe-5">{val?.rushing1?.longest_rush}</td>
                                </> : <>
                                  <td className="font-16 fw-light p-0 color-grey tr-border-left">{val?.rushing2?.total_rushes}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing2?.yards}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing2?.average}</td>
                                  <td className="font-16 fw-light p-0 color-grey">{val?.rushing2?.rushing_touch_downs}</td>
                                  <td className="font-16 fw-light p-0 color-grey ps-4 pe-5">{val?.rushing2?.longest_rush}</td>
                                </>}

                              </tr>
                            );
                          })}

                          {gameLogData && gameLogData?.length == 0 && <tr><td colSpan={20}><div className="noData"><p>No Data Found!</p></div></td></tr>}
                        </>
                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GameLogTableNcaa;
