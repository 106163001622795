import { articleCategoryListApi } from "../../service/articleService";

// export const articleLinkList = [
//     {
//         text: "NFL",
//         url: "nfl",
//     },
//     {
//         text: "NCAAF",
//         url: "ncaaf",
//     },
//     {
//         text: "DEVY",
//         url: "devy",
//     },
//     {
//         text: "CAMPUS 2 CANTON",
//         url: "campus-2-canton",
//     },
//     {
//         text: "DYNASTY",
//         url: "dynasty",
//     },
//     {
//         text: "SEASON LONG",
//         url: "season-long",
//     },
// ];


export const articleLinkList = async ( ) => {
    var returndataList = [];
    await articleCategoryListApi().then(function (result) {
      const response = result.data;
      if (response.response_data) {
        response.response_data.map((element) => {
          var data = {
            text: element.title,
            url: element.permalink, 
          }
          returndataList.push(data);
        })
      }
    });
    return returndataList;
}

export const getLinkFromTitle = (title) => {
    var changedTitle = title.toLowerCase().replace(/ /g, "-");
    var changedTitle = changedTitle.replace(/'/g, "-");
    var changedTitle = changedTitle.replace(/\//g, "-");
    // var changedTitle = changedTitle.replace(/+/g, "-"); 
    var changedTitle = changedTitle.replace(/--/g, "-");
    return changedTitle;
}


// export function getArticleCategoryLink(catTitle = "NFL") {
//     var articleCatLink = "#";
//     // var articleLinkList1 = articleLinkList();
//     // console.log('articleLinkList help', articleLinkList1)
//     // articleLinkList.forEach((element) => {
//     //     if (catTitle == element.text) {
//             // articleCatLink = "/articles/" + element.url
//     //     }
//     // })
//     return articleCatLink;
// }

export function getArticleDetailsLink(articleRow = {}) {
    var articleLink = "#";
    if (articleRow && articleRow.permalink && articleRow.permalink != "") {
        articleLink = `/article/${articleRow.permalink}/${articleRow._id}`;
    } else {
        articleLink = `/article/${articleRow.title}/${articleRow._id}`;
    }
    return articleLink;
}
