import standingIAImg1 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg1.png";
import standingIAImg2 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg2.png";
import standingIAImg3 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg3.png";
import standingIAImg4 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg4.png";
import standingIAImg5 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg5.png";
import standingIAImg6 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg6.png";
import standingIAImg7 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg7.png";
import standingIAImg8 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg8.png";
import standingIAImg9 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg9.png";
import standingIAImg10 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg10.png";
import standingIAImg11 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg11.png";
//
import standingIAImg12 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg12.png";
import standingIAImg13 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg13.png";
import standingIAImg14 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg14.png";
import standingIAImg15 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg15.png";
import standingIAImg16 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg16.png";
import standingIAImg17 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg17.png";
import standingIAImg18 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg18.png";
import standingIAImg19 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg19.png";
import standingIAImg20 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg20.png";
import standingIAImg21 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg21.png";
import standingIAImg22 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg22.png";
import standingIAImg23 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg23.png";
import standingIAImg24 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg24.png";
import standingIAImg25 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg25.png";
import standingIAImg26 from "../../NcaafFrontAssets/HomepageAssets/img/standingIAImg26.png";
import webApi from "../../WebApi/WebApi";

export const americanStandingList = [
  {
    standingTableHeading: "American Athletic Conference",
    tableBodyList: [
      {
        teamImgUrl: standingIAImg1,
        teamNo: "7",
        teamName: "Cincinnati Bearcats",
        win: "4-0",
        lose: "157",
        tie: "40",
        wl: "6-0",
        pf: "236",
        pa: "70",
        home: "5-0",
        away: "1-0",
        strk: "W6",
        ap: "2-0",
        usa: "1-0",
      },
      {
        teamImgUrl: standingIAImg2,
        teamNo: "",
        teamName: "Tulsa Golden Hurricane",
        win: "3-0",
        lose: "110",
        tie: "69",
        wl: "3-1",
        pf: "117",
        pa: "85",
        home: "1-0",
        away: "2-1",
        strk: "W3",
        ap: "1-1",
        usa: "1-1",
      },
      {
        teamImgUrl: standingIAImg3,
        teamNo: "19",
        teamName: "SMU Mustangs",
        win: "4-1",
        lose: "178",
        tie: "163",
        wl: "7-1",
        pf: "324",
        pa: "229",
        home: "3-1",
        away: "4-0",
        strk: "W2",
        ap: "1-1",
        usa: "1-1",
      },
      {
        teamImgUrl: standingIAImg4,
        teamNo: "",
        teamName: "Memphis Tigers",
        win: "3-2",
        lose: "162",
        tie: "190",
        wl: "4-2",
        pf: "199",
        pa: "214",
        home: "4-0",
        away: "0-2",
        strk: "W1",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg5,
        teamNo: "",
        teamName: "UCF Knights",
        win: "3-2",
        lose: "221",
        tie: "167",
        wl: "4-2",
        pf: "270",
        pa: "188",
        home: "1-1",
        away: "3-1",
        strk: "W2",
        ap: "0-0",
        usa: "0-0",
      },
      {
        teamImgUrl: standingIAImg6,
        teamNo: "",
        teamName: "Navy Midshipmen",
        win: "3-2",
        lose: "143",
        tie: "164",
        wl: "3-4",
        pf: "153",
        pa: "259",
        home: "1-2",
        away: "2-2",
        strk: "L2",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg7,
        teamNo: "",
        teamName: "Houston Cougars",
        win: "2-2",
        lose: "117",
        tie: "134",
        wl: "2-3",
        pf: "143",
        pa: "177",
        home: "1-2",
        away: "1-1",
        strk: "L2",
        ap: "0-2",
        usa: "0-2",
      },
      {
        teamImgUrl: standingIAImg8,
        teamNo: "",
        teamName: "Tulane Green Wave",
        win: "2-4",
        lose: "199",
        tie: "188",
        wl: "4-4",
        pf: "292",
        pa: "236",
        home: "1-2",
        away: "3-2",
        strk: "W2",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg9,
        teamNo: "",
        teamName: "Temple Owls",
        win: "1-4",
        lose: "123",
        tie: "194",
        wl: "1-4",
        pf: "123",
        pa: "194",
        home: "1-1",
        away: "0-3",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg10,
        teamNo: "",
        teamName: "East Carolina Pirates",
        win: "1-4",
        lose: "146",
        tie: "174",
        wl: "1-5",
        pf: "175",
        pa: "223",
        home: "0-3",
        away: "1-2",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg11,
        teamNo: "",
        teamName: "South Florida Bulls",
        win: "0-5",
        lose: "114",
        tie: "187",
        wl: "1-6",
        pf: "141",
        pa: "245",
        home: "1-2",
        away: "0-4",
        strk: "L6",
        ap: "0-2",
        usa: "0-2",
      },
    ],
  },
  {
    standingTableHeading: "Atlantic Coast Conference",
    tableBodyList: [
      {
        teamImgUrl: standingIAImg12,
        teamNo: "2",
        teamName: " Notre Dame Fighting Irish",
        win: "4-0",
        lose: "157",
        tie: "40",
        wl: "6-0",
        pf: "236",
        pa: "70",
        home: "5-0",
        away: "1-0",
        strk: "W6",
        ap: "2-0",
        usa: "1-0",
      },
      {
        teamImgUrl: standingIAImg13,
        teamNo: "4",
        teamName: " Clemson Tigers",
        win: "3-0",
        lose: "110",
        tie: "69",
        wl: "3-1",
        pf: "117",
        pa: "85",
        home: "1-0",
        away: "2-1",
        strk: "W3",
        ap: "1-1",
        usa: "1-1",
      },
      {
        teamImgUrl: standingIAImg14,
        teamNo: "9",
        teamName: " Miami Hurricanes",
        win: "4-1",
        lose: "178",
        tie: "163",
        wl: "7-1",
        pf: "324",
        pa: "229",
        home: "3-1",
        away: "4-0",
        strk: "W2",
        ap: "1-1",
        usa: "1-1",
      },
      {
        teamImgUrl: standingIAImg15,
        teamNo: "",
        teamName: "North Carolina Tar Heels",
        win: "3-2",
        lose: "162",
        tie: "190",
        wl: "4-2",
        pf: "199",
        pa: "214",
        home: "4-0",
        away: "0-2",
        strk: "W1",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg16,
        teamNo: "",
        teamName: "Virginia Tech Hokies",
        win: "3-2",
        lose: "221",
        tie: "167",
        wl: "4-2",
        pf: "270",
        pa: "188",
        home: "1-1",
        away: "3-1",
        strk: "W2",
        ap: "0-0",
        usa: "0-0",
      },
      {
        teamImgUrl: standingIAImg17,
        teamNo: "",
        teamName: "Wake Forest Demon Deacons",
        win: "3-2",
        lose: "143",
        tie: "164",
        wl: "3-4",
        pf: "153",
        pa: "259",
        home: "1-2",
        away: "2-2",
        strk: "L2",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg18,
        teamNo: "",
        teamName: "Boston College Eagles",
        win: "2-2",
        lose: "117",
        tie: "134",
        wl: "2-3",
        pf: "143",
        pa: "177",
        home: "1-2",
        away: "1-1",
        strk: "L2",
        ap: "0-2",
        usa: "0-2",
      },
      {
        teamImgUrl: standingIAImg19,
        teamNo: "",
        teamName: "NC State Wolfpack",
        win: "2-4",
        lose: "199",
        tie: "188",
        wl: "4-4",
        pf: "292",
        pa: "236",
        home: "1-2",
        away: "3-2",
        strk: "W2",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg20,
        teamNo: "",
        teamName: "Pittsburgh Panthers",
        win: "1-4",
        lose: "123",
        tie: "194",
        wl: "1-4",
        pf: "123",
        pa: "194",
        home: "1-1",
        away: "0-3",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg21,
        teamNo: "",
        teamName: "Virginia Cavaliers",
        win: "1-4",
        lose: "146",
        tie: "174",
        wl: "1-5",
        pf: "175",
        pa: "223",
        home: "0-3",
        away: "1-2",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg22,
        teamNo: "",
        teamName: "Georgia Tech Yellow Jackets",
        win: "0-5",
        lose: "114",
        tie: "187",
        wl: "1-6",
        pf: "141",
        pa: "245",
        home: "1-2",
        away: "0-4",
        strk: "L6",
        ap: "0-2",
        usa: "0-2",
      },
      {
        teamImgUrl: standingIAImg23,
        teamNo: "",
        teamName: "Louisville Cardinals",
        win: "1-4",
        lose: "146",
        tie: "174",
        wl: "1-5",
        pf: "175",
        pa: "233",
        home: "0-3",
        away: "1-2",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg24,
        teamNo: "",
        teamName: "Florida State Seminoles",
        win: "0-5",
        lose: "114",
        tie: "187",
        wl: "1-6",
        pf: "141",
        pa: "245",
        home: "1-2",
        away: "0-4",
        strk: "L6",
        ap: "0-2",
        usa: "0-2",
      },
      {
        teamImgUrl: standingIAImg25,
        teamNo: "",
        teamName: "Duke Blue Devils",
        win: "1-4",
        lose: "146",
        tie: "174",
        wl: "1-5",
        pf: "175",
        pa: "223",
        home: "0-3",
        away: "1-2",
        strk: "L3",
        ap: "0-1",
        usa: "0-1",
      },
      {
        teamImgUrl: standingIAImg26,
        teamNo: "",
        teamName: "Syracuse Orange",
        win: "0-5",
        lose: "114",
        tie: "187",
        wl: "1-6",
        pf: "141",
        pa: "245",
        home: "1-2",
        away: "0-4",
        strk: "L6",
        ap: "0-2",
        usa: "0-2",
      },
    ],
  },
];

export const glossaryList = [
  {
    glosaaryTitle: "GLOSSARY",
    glossaryListData: [
      {
        glossaryItemName: "W-L",
        glossaryItemData: "Conference Record",
      },
      {
        glossaryItemName: "PF",
        glossaryItemData: "Total Points For",
      },
      {
        glossaryItemName: "PA",
        glossaryItemData: "Total Points Against",
      },
    ],
  },
  {
    glosaaryTitle: "",
    glossaryListData: [
      {
        glossaryItemName: "HOME",
        glossaryItemData: "Home Record",
      },
      {
        glossaryItemName: "AWAY",
        glossaryItemData: "Away Record",
      },
      {
        glossaryItemName: "STRK",
        glossaryItemData: "Current Streak",
      },
    ],
  },
  {
    glosaaryTitle: "",
    glossaryListData: [
      {
        glossaryItemName: "AP",
        glossaryItemData: "vs. AP Ranked Teams",
      },
      {
        glossaryItemName: "USA",
        glossaryItemData: "vs. USA Ranked Teams",
      },
      {
        glossaryItemName: "",
        glossaryItemData: "",
      },
    ],
  },
];


export const getNcaaStandingList = async (year, onSuccess, onFailure) => {
  

  try {
    const res = await webApi.get(

      `/getNcaaStanding?year=${year}`,
      
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};