import React from "react";
import { useEffect, useState } from "react";
import LayerImg from "../../components/Navbar/assets/layer.png";
import VideoIcon from "../Navbar/assets/icon.png";
import { useLocation } from "react-router-dom";

import { getAllvideos } from "../../service/cmsService";
import HeadingDotted from "../common/HeadingDotted";

const ViewallVideo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [page, set_page] = useState(queryParams.get("page")); 

  const [allVideolist, setVideoList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState();

  const closeModal = () => setShowModal(false);
  const openModal = (data) => { 
    setVideoUrl(data);
    setShowModal(true);
  }


  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      return () => { document.body.style.overflow = "scroll"; };
    }
  });


  useEffect(() => {
    videoData();
  }, []);

  async function videoData() {
    getAllvideos(page).then(function (result) {
      const response = result.data;
      setVideoList(response.response_data);
    });
  }

 
  return (
    <div className="poadcast-section  position-relative">
      <div className="position-absolute end-0 bottom-190 ">
        <img className="d-none" src={LayerImg} alt="LayerImg" />
      </div>
      <div className="container pb-md-4 pb-3">
        <div className="viewAllvideohnd">
          {/* <Link to="/podcast" className="cursor-pointer">
            <span class="glyphicon glyphicon-arrow-left" aria-hidden="true"></span>
          </Link> */}
          <div>
            <h1 className="heading white font-web skew-heading text-uppercase">All Videos</h1>
            <HeadingDotted />
          </div>
        </div>
        <div className="row py-sm-5  py-3">
          <div className="col-xl-12 d-flex align-items-center justify-content-between">
            <div className="row mx-0 w-100 justify-content-center">

              {allVideolist.map((data, index) => {
                return (
                  <div className="col-lg-3 col-sm-6  my-3" key={index}>
                    <div className="videos-img position-relative cursor-pointer">
                      <div className="vlbase"><img className="w-100 h-100 " src={data.thumbnail_image} alt="Video1" /></div>
                      <div className="position-absolute top-0 h-100 d-flex justify-content-center align-items-center w-100" onClick={e => openModal(data)}>
                        <img className="playicon-img" src={VideoIcon} alt="VideoIcon" />
                      </div>
                    </div>
                  </div>
                )
              })}

              {showModal &&
                <>
                  <div className="modalWrapp" onClick={closeModal}></div>
                  <div className="modalContainer nModal">
                    <button onClick={closeModal} className="closemodicon">X</button>
                    <h2>{videoUrl.title}</h2>
                    <video width="320" height="240" loop="true" autoplay="autoplay" controls >
                      <source src={videoUrl.video} type="video/mp4" />
                    </video>
                  </div>
                </>

              }

            </div>
          </div>

        </div>


      </div>
    </div>
  )
}

export default ViewallVideo