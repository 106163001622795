import React from "react";
import { ColorRing } from "react-loader-spinner";

import cfsDefault from "../../../src/Assets/cfsdefault.png";


const ColorRingCustomLoader = ({ isLoader=true }) => {
  return (
    <>
      <div className="loaderCntr">
        <div className="loaderDesign">
          <img src={cfsDefault} width="55px" />
          <ColorRing
            visible={isLoader}
            height="120"
            width="120"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#0085CA', '#BFC0BF', '#0085CA', '#BFC0BF', '#0085CA',]}
          />
        </div>
      </div>
    </>
  );
};

export default ColorRingCustomLoader;
