import React from "react";

const Offencetabs = () => {
  return (
    <>
      <div>
        <h3 className="font-34 font-web fw-bold american-heading mb-5  american-standings-table">
          <span>2</span>
          <span>0</span>
          <span>2</span>0 AMERICAN STANDINGS
        </h3>
        <div className="team-table text-white mb-sm-5 ms-md-3 min-width-392 mt-5">
          <div className="d-flex team-content px-4 py-4 mt-2">
            <h3 className="font-12 fw-medium w-50 mb-0">TEAM</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">CONF</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0 pe-3">OVR</h3>
          </div>
          <div className="d-flex align-items-center px-4 py-5 ">
            <h3 className="font-12 mb-0 fw-medium w-50 py-4 blue">
              Cincinnati
            </h3>
            <h3 className="font-12 mb-0 fw-medium w-25 text-end">4-0</h3>
            <h3 className="font-12 mb-0 fw-medium w-25 text-end pe-3">6-0</h3>
          </div>
          <div className="d-flex align-items-center px-4 pb-4 pt-4 bg-light-blue3">
            <h3 className="font-12 fw-medium w-50 blue pt-3">Tulsa</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">3-0</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3 b-0">3-1</h3>
          </div>
          <div className="d-flex align-items-center px-4 py-5">
            <h3 className="font-12 fw-medium w-50 py-4 blue">SMU</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">4-1</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3 mb-0">7-1</h3>
          </div>
          <div className="d-flex align-items-center px-4 pb-4 pt-4 bg-light-blue3">
            <h3 className="font-12 fw-medium w-50 blue pt-3">Memphis</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">3-2</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3 mb-0">4-2</h3>
          </div>
          <div className="d-flex px-4 align-items-center py-5">
            <h3 className="font-12 fw-medium w-50 py-4 blue">UCF</h3>
            <h3 className="font-12 fw-medium w-25 text-end">3-2</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3">4-2</h3>
          </div>
          <div className="d-flex align-items-center px-4 pb-4 pt-4 bg-light-blue3">
            <h3 className="font-12 fw-medium w-50 blue pt-3">Memphis</h3>
            <h3 className="font-12 fw-medium w-25 text-end">3-2</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3">3-4</h3>
          </div>
          <div className="d-flex align-items-center px-4 py-5">
            <h3 className="font-12 fw-medium w-50 py-4 blue">Navy</h3>
            <h3 className="font-12 fw-medium w-25 text-end">3-2</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3">3-4</h3>
          </div>
          <div className="d-flex align-items-center px-4 pb-4 pt-4 bg-light-blue3">
            <h3 className="font-12 fw-medium w-50 blue pt-3">Houston</h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">2-2</h3>
            <h3 className="font-12 fw-medium w-25 text-end pe-3 mb-0">2-3</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offencetabs;
