import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import GameLogTable from "../components/playerProflie/GameLogTable";
import MetricsGameTable from "../components/playerProflie/MetricsGameTable";
import PlayerProfileBottomNews from "../components/playerProflie/PlayerProfileBottomNews";
import PlayerProfileHero from "../components/playerProflie/PlayerProfileHero";
import GlobalConfig from "../GlobalConfig";
import { ncaaPlayerDetailsApi } from "../service/NcaaService";
import GameLogTableNcaa from "../components/playerProflie/GameLogTableNcaa";

const NcaaPlayerProfilePage = () => {
  const params = useParams();
  const [playerId, set_playerId] = useState(params.playerId);
  const [playerDetails, set_playerDetails] = useState({});

  useEffect(() => {
    nflPlayerDetails();
  }, [playerId]);


  async function nflPlayerDetails() {
    ncaaPlayerDetailsApi(playerId).then(function (result) {
      const response_data = result.data.response_data;
      set_playerDetails(response_data);
    });
  }
console.log('playerDetails', playerDetails);

  return (
    <>
      <Helmet>
        <title> {(playerDetails && playerDetails.name) ? playerDetails.name : ""} | {GlobalConfig.SITE_NAME} </title>
        <meta name="description" content={`${(playerDetails && playerDetails.name) ? playerDetails.name : ""} | ${GlobalConfig.SITE_NAME} `} />
        <meta name="keywords" content={`${(playerDetails && playerDetails.name) ? playerDetails.name : ""} | ${GlobalConfig.SITE_NAME} `} />
      </Helmet>

      <div className="player-profile-bg">
        <PlayerProfileHero playerDetails={playerDetails} playerType={'ncaa'} />
        <GameLogTableNcaa playerId={playerId} playerDetails={playerDetails} />
        {/* <MetricsGameTable /> */}
        <PlayerProfileBottomNews />
      </div>
    </>
  );
};

export default NcaaPlayerProfilePage;
