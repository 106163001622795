import webApi from "../../WebApi/WebApi";

import userimg from "../../Assets/769978_man_512x512.png";
import playerimg from "../../Assets/draft-table-img-1.png";
import playerimg2 from "../../Assets/draft-table-img-2.png";
import playerimg3 from "../../Assets/draft-table-img-3.png";
import playerimg4 from "../../Assets/draft-table-img-4.png";
import playerimg5 from "../../Assets/draft-table-img-5.png";
import playerimg6 from "../../Assets/draft-table-img-6.png";
import playerimg7 from "../../Assets/draft-table-img-7.png";
import playerimg8 from "../../Assets/draft-table-img-8.png";
import Arizona from "../../Assets/headerIcon/Arizona.png";
import Baltimore from "../../Assets/headerIcon/baltimore.png";
import Buffalo from "../../Assets/headerIcon/buffalo.png";
import Cincennati from "../../Assets/headerIcon/cincennati.png";
import Cleveland from "../../Assets/headerIcon/Cleveland.png";
import England from "../../Assets/headerIcon/England.png";
import Francisco from "../../Assets/headerIcon/Francisco.png";
import Indianapolis from "../../Assets/headerIcon/Indianapolis.png";
import Miami from "../../Assets/headerIcon/Miami.png";
import NewYork from "../../Assets/headerIcon/NewYork.png";
import Pittsburgh from "../../Assets/headerIcon/Pittsburgh.png";
import Rams from "../../Assets/headerIcon/Rams.png";
import Seattle from "../../Assets/headerIcon/Seattle.png";
import leaderImage from "../../Assets/img/player-image-2.png";
import leaderImage2 from "../../Assets/leader-img-3.png";
import leaderImage3 from "../../Assets/leader-img-4.png";
import leaderImage4 from "../../Assets/leader-img-5.png";
import leaderImage5 from "../../Assets/leader-img-6.png";
import leaderImage6 from "../../Assets/leader-img-7.png";
import leaderImage7 from "../../Assets/leader-img-8.png";
import leaderImage8 from "../../Assets/leader-img-9.png";
import leaderImage9 from "../../Assets/leader-img-10.png";
import leaderImage10 from "../../Assets/leader-img-11.png";
import leaderImage11 from "../../Assets/leader-img-12.png";
import leaderImage12 from "../../Assets/leader-img-13.png";
import leaderImage13 from "../../Assets/leader-img-14.png";
import leaderImage14 from "../../Assets/leader-img-15.png";
import leaderImage15 from "../../Assets/leader-img-16.png";
import leaderImage16 from "../../Assets/leader-img-17.png";
import leaderImage17 from "../../Assets/leader-img-18.png";
import leaderImage18 from "../../Assets/leader-img-19.png";
import leaderImage19 from "../../Assets/leader-img-20.png";
import leaderImage20 from "../../Assets/leader-img-21.png";
import leaderImage21 from "../../Assets/leader-img-22.png";
import leaderImage22 from "../../Assets/leader-img-23.png";
import playerTeamIcon from "../../Assets/player-team-icon.png";
import tableImg1 from "../../Assets/schedule-table-img-1.png";
import tableImg10 from "../../Assets/schedule-table-img-10.png";
import tableImg11 from "../../Assets/schedule-table-img-11.png";
import tableImg12 from "../../Assets/schedule-table-img-12.png";
import tableImg13 from "../../Assets/schedule-table-img-13.png";
import tableImg14 from "../../Assets/schedule-table-img-14.png";
import tableImg15 from "../../Assets/schedule-table-img-15.png";
import tableImg16 from "../../Assets/schedule-table-img-16.png";
import tableImg17 from "../../Assets/schedule-table-img-17.png";
import tableImg18 from "../../Assets/schedule-table-img-18.png";
import tableImg19 from "../../Assets/schedule-table-img-19.png";
import tableImg2 from "../../Assets/schedule-table-img-2.png";
import tableImg20 from "../../Assets/schedule-table-img-20.png";
import tableImg21 from "../../Assets/schedule-table-img-21.png";
import tableImg22 from "../../Assets/schedule-table-img-22.png";
import tableImg23 from "../../Assets/schedule-table-img-23.png";
import tableImg24 from "../../Assets/schedule-table-img-24.png";
import tableImg3 from "../../Assets/schedule-table-img-3.png";
import tableImg4 from "../../Assets/schedule-table-img-4.png";
import tableImg5 from "../../Assets/schedule-table-img-5.png";
import tableImg6 from "../../Assets/schedule-table-img-6.png";
import tableImg9 from "../../Assets/schedule-table-img-9.png";

export const scheduleHeadingList = [
  {
    heading: "MATCHUP",
  },
  {
    heading: "",
  },
  {
    heading: "",
  },
  {
    heading: "TIME",
  },
  // {
  //   heading: "NAT TV",
  // },
  // {
  //   heading: "TICKETS",
  // },
  {
    heading: " LOCATION",
  },
];

export const scheduleTableDescriptionList = [
  // {
  //   timeText: "5:50 AM",
  //   ticketRate: "Tickets as low as $150",
  //   placeLocation: "Bank of America Stadium, Charlotte",
  //   tableFirstTeamImg: tableImg1,
  //   tableSecondTeamImg: tableImg2,
  //   natTvText: "",
  //   teamFirstName: "Falcons",
  //   teamSecondName: "Panthers",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Bills Stadium, Orchard Park",
  //   tableFirstTeamImg: tableImg3,
  //   tableSecondTeamImg: tableImg4,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $28",
  //   placeLocation: "Paul Brown Stadium, Cincinnati",
  //   tableFirstTeamImg: tableImg5,
  //   tableSecondTeamImg: tableImg6,
  //   natTvText: "",
  //   teamFirstName: "Tennessee",
  //   teamSecondName: "Cincinnati",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "FirstEnergy Stadium, Cleveland",
  //   tableFirstTeamImg: tableImg3,
  //   tableSecondTeamImg: tableImg4,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Ford Field, Detroit",
  //   tableFirstTeamImg: tableImg9,
  //   tableSecondTeamImg: tableImg10,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Lambeau Field, Green Bay",
  //   tableFirstTeamImg: tableImg11,
  //   tableSecondTeamImg: tableImg12,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Arrowhead Stadium, Kansas City",
  //   tableFirstTeamImg: tableImg13,
  //   tableSecondTeamImg: tableImg14,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Hard Rock Stadium, Miami Gardens",
  //   tableFirstTeamImg: tableImg15,
  //   tableSecondTeamImg: tableImg16,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "M&T Bank Stadium, Baltimore",
  //   tableFirstTeamImg: tableImg17,
  //   tableSecondTeamImg: tableImg18,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Empower Field at Mile High, Denver",
  //   tableFirstTeamImg: tableImg19,
  //   tableSecondTeamImg: tableImg20,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $43",
  //   placeLocation: "Soldier Field, Chicago",
  //   tableFirstTeamImg: tableImg21,
  //   tableSecondTeamImg: tableImg22,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
  // {
  //   timeText: "11:30 PM",
  //   ticketRate: "Tickets as low as $221",
  //   placeLocation: "CenturyLink Field, Seattle",
  //   tableFirstTeamImg: tableImg23,
  //   tableSecondTeamImg: tableImg24,
  //   natTvText: "",
  //   teamFirstName: "New England",
  //   teamSecondName: "Buffalo",
  //   lightText: "AT",
  // },
];

export const drafttablelist = [
  {
    rank: 1,
    playerName: "Trevor Lawrence",
    school: "Clemson",
    pos: "OVR",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg,
  },
  {
    rank: 2,
    playerName: "Penei Sewell",
    school: "Alabama",
    pos: "QB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg2,
  },
  {
    rank: 3,
    playerName: "Patrick Surtain II",
    school: "Alabama",
    pos: "RB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg3,
  },
  {
    rank: 4,
    playerName: "Ja'Marr Chase",
    school: "FB",
    pos: "FB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg4,
  },
  {
    rank: 5,
    playerName: "Trevor Lawrence",
    school: "Clemson",
    pos: "WR",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg5,
  },
  {
    rank: 6,
    playerName: "Micah Parsons",
    school: "Penn State",
    pos: "TE",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg6,
  },
  {
    rank: 7,
    playerName: "Trevor Lawrence",
    school: "Clemson",
    pos: "OT",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg7,
  },
  {
    rank: 8,
    playerName: "Justin Fields",
    school: "Clemson",
    pos: "OG",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg8,
  },
  {
    rank: 9,
    playerName: "Ohio State",
    school: "Clemson",
    pos: "C",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg,
  },
  {
    rank: 10,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "DE",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg2,
  },
  {
    rank: 11,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "DT",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg3,
  },
  {
    rank: 12,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "ILB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg4,
  },
  {
    rank: 13,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "OLB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg5,
  },
  {
    rank: 14,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "CB",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg6,
  },
  {
    rank: 15,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "S",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg7,
  },

  {
    rank: 16,
    playerName: "Rashod Bateman",
    school: "Minnesota",
    pos: "LS",
    height: 1.98,
    weight: 80,
    shirtcolor: "white",
    posRank: 1,
    overRank: 2,
    grade: 93,
    teamImage: playerTeamIcon,
    playerImage: userimg,
    teamImage2: playerimg8,
  },
];



export const defensiveLeaderList = [
  {
    listype: "TACKLES",
    listplayers: [
      {
        playerImage: leaderImage16,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage17,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage18,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage19,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage20,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
    ],
  },
  {
    listype: "SACKS",
    listplayers: [
      {
        playerImage: leaderImage21,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage22,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage5,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage2,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage7,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 5000,
      },
    ],
  },
  {
    listype: "INTERCEPTIONS",
    listplayers: [
      {
        playerImage: leaderImage17,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 400,
      },
      {
        playerImage: leaderImage18,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 2462,
      },
      {
        playerImage: leaderImage19,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 653,
      },
      {
        playerImage: leaderImage20,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 234,
      },
      {
        playerImage: leaderImage21,
        playerName: "Matt Ryan",
        team: "ATL",
        yds: 992,
      },
    ],
  },
];

export const offensiveTeamListData = [
  {
    listype: "TOTAL YARDS",
    listplayers: [
      {
        playerImage: Arizona,
        playerName: "Arizona Cardinals",
        yds: 246.2,
      },
      {
        playerImage: Seattle,
        playerName: "Los Angeles Chargers",
        yds: 246.2,
      },
      {
        playerImage: Buffalo,
        playerName: "Seattle Seahawks",
        yds: 288.2,
      },
      {
        playerImage: Arizona,
        playerName: "Kansas City Chiefs",
        team: "ATL",
        yds: 246.2,
      },
      {
        playerImage: Arizona,
        playerName: "Tennessee Titans",
        yds: 241.2,
      },
    ],
  },
  {
    listype: "PASSING",
    listplayers: [
      {
        playerImage: Pittsburgh,
        playerName: "Dallas Cowboys",
        yds: 11.1,
      },
      {
        playerImage: Francisco,
        playerName: "Atlanta Falcons",
        yds: 24.62,
      },
      {
        playerImage: Indianapolis,
        playerName: "Seattle Seahawks",
        yds: 246.2,
      },
      {
        playerImage: Cleveland,
        playerName: "Kansas City Chiefs",
        team: "ATL",
        yds: 246.2,
      },
      {
        playerImage: Cincennati,
        playerName: "Houston Texans",
        yds: 5000,
      },
    ],
  },
  {
    listype: "RECEIVING",
    listplayers: [
      {
        playerImage: Baltimore,
        playerName: "Baltimore Ravens",
        yds: 400.11,
      },
      {
        playerImage: Rams,
        playerName: "Arizona Cardinals",
        yds: 246.2,
      },
      {
        playerImage: Miami,
        playerName: "New England Patriots",
        yds: 65.3,
      },
      {
        playerImage: NewYork,
        playerName: "Tennessee Titans",
        yds: 2341.11,
      },
      {
        playerImage: England,
        playerName: "Cleveland Browns",
        yds: 992.11,
      },
    ],
  },
];

export const playerstatistics = [
  {
    offense: "Passing",
    defense: "Tackles",
    specialteam: "Returning",
  },
  {
    offense: "Rushing",
    defense: "Sacks",
    specialteam: "Kicking",
  },
  {
    offense: "Receiving",
    defense: "Interceptions",
    specialteam: "Punting",
  },
  {
    offense: "Touchdowns",
    defense: "",
    specialteam: "",
  },
];

export const teamstatisticsTableData = [
  {
    offense: "Total Yards",
    defense: "Yards Allowed",
    specialteam: "Returning",
  },
  {
    offense: "Passing",
    defense: "Turnovers",
    specialteam: "Kicking",
  },
  {
    offense: "Rushing",
    defense: "Passing",
    specialteam: "Punting",
  },
  {
    offense: "Receiving",
    defense: "Receiving",
    specialteam: "",
  },
  {
    offense: "Downs",
    defense: "Downs",
    specialteam: "",
  },
];


export const weekListDate = {
  2019: [
    { 'Week 1': ["09-04", "09-10"] },
    { 'Week 2': ["09-11", "09-17"] },
    { 'Week 3': ["09-18", "09-24"] },
    { 'Week 4': ["09-25", "10-01"] },
    { 'Week 5': ["10-02", "10-08"] },
    { 'Week 6': ["10-09", "10-15"] },
    { 'Week 7': ["10-16", "10-22"] },
    { 'Week 8': ["10-23", "10-29"] },
    { 'Week 9': ["10-30", "11-05"] },
    { 'Week 10': ["11-06", "11-12"] },
    { 'Week 11': ["11-13", "11-19"] },
    { 'Week 12': ["11-20", "11-26"] },
    { 'Week 13': ["11-27", "12-03"] },
    { 'Week 14': ["12-04", "12-10"] },
    { 'Week 15': ["12-11", "12-17"] },
    { 'Week 16': ["12-18", "12-24"] },
    { 'Week 17': ["12-25", "12-31"] },
    { 'Week 18': ["01-01", "01-07"] },
  ],
  2020: [
    { 'Week 1': ["09-05", "09-15"] },
    { 'Week 2': ["09-16", "09-22"] },
    { 'Week 3': ["09-23", "09-29"] },
    { 'Week 4': ["09-30", "10-06"] },
    { 'Week 5': ["10-07", "10-13"] },
    { 'Week 6': ["10-14", "10-20"] },
    { 'Week 7': ["10-21", "10-27"] },
    { 'Week 8': ["10-28", "11-03"] },
    { 'Week 9': ["11-04", "11-10"] },
    { 'Week 10': ["11-11", "11-17"] },
    { 'Week 11': ["11-18", "11-24"] },
    { 'Week 12': ["11-25", "12-02"] },
    { 'Week 13': ["12-03", "12-08"] },
    { 'Week 14': ["12-09", "12-15"] },
    { 'Week 15': ["12-16", "12-22"] },
    { 'Week 16': ["12-23", "12-29"] },
    { 'Week 17': ["12-30", "01-05"] },
    { 'Week 18': ["01-06", "01-12"] },
  ],
  2021: [
    { 'Week 1': ["09-09", "09-14"] },
    { 'Week 2': ["09-15", "09-21"] },
    { 'Week 3': ["09-22", "09-28"] },
    { 'Week 4': ["09-29", "10-05"] },
    { 'Week 5': ["10-06", "10-12"] },
    { 'Week 6': ["10-13", "10-19"] },
    { 'Week 7': ["10-20", "10-26"] },
    { 'Week 8': ["10-27", "11-02"] },
    { 'Week 9': ["11-03", "11-09"] },
    { 'Week 10': ["11-10", "11-16"] },
    { 'Week 11': ["11-17", "11-23"] },
    { 'Week 12': ["11-24", "11-30"] },
    { 'Week 13': ["12-01", "12-07"] },
    { 'Week 14': ["12-08", "12-14"] },
    { 'Week 15': ["12-15", "12-21"] },
    { 'Week 16': ["12-22", "12-28"] },
    { 'Week 17': ["12-29", "01-04"] },
    { 'Week 18': ["01-05", "01-11"] },
  ],
  2022: [
    { 'PreSeason Hall of Fame Weekend': ["08-01", "08-09"] },
    { 'PreSeason Week 1': ["08-10", "08-16"] },
    { 'PreSeason Week 2': ["08-17", "08-23"] },
    { 'PreSeason Week 3': ["08-24", "09-07"] },
    { 'Week 1': ["09-08", "09-13"] },
    { 'Week 2': ["09-14", "09-20"] },
    { 'Week 3': ["09-21", "09-27"] },
    { 'Week 4': ["09-28", "10-04"] },
    { 'Week 5': ["10-05", "10-11"] },
    { 'Week 6': ["10-12", "10-18"] },
    { 'Week 7': ["10-19", "10-25"] },
    { 'Week 8': ["10-26", "11-01"] },
    { 'Week 9': ["11-02", "11-08"] },
    { 'Week 10': ["11-09", "11-15"] },
    { 'Week 11': ["11-16", "11-22"] },
    { 'Week 12': ["11-23", "11-29"] },
    { 'Week 13': ["11-30", "12-06"] },
    { 'Week 14': ["12-07", "12-13"] },
    { 'Week 15': ["12-14", "12-20"] },
    { 'Week 16': ["12-21", "12-27"] },
    { 'Week 17': ["12-28", "01-03"] },
    { 'Week 18': ["01-04", "01-11"] },
    { 'Wild Card': ["01-12", "01-17"] },
    { 'Divisional Round': ["01-18", "01-24"] },
    { 'Conference Championships': ["01-25", "01-31"] },
    { 'Pro Bowl': ["02-01", "02-07"] },
    { 'Super Bowl': ["02-08", "02-14"] },

  ],
  2023: [
    { 'PreSeason Hall of Fame Weekend': ["08-03", "08-04"] },
    { 'PreSeason Week 1': ["08-05", "08-06", "08-07", "08-08", "08-09", "08-10", "08-11", "08-12", "08-13"] },
    { 'PreSeason Week 2': ["08-14", "08-15", "08-16", "08-17", "08-18", "08-19", "08-20", "08-21"] },
    { 'PreSeason Week 3': ["08-22", "08-23", "08-24", "08-25", "08-26", "08-27"] },
    { 'Week 1': ["08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04", "09-05", "09-06", "09-07", "09-08", "09-09", "09-10", "09-11", "09-12"] },
    { 'Week 2': ["09-13", "09-14", "09-15", "09-16", "09-17", "09-18", "09-19"] },
    { 'Week 3': ["09-20", "09-21", "09-22", "09-23", "09-24", "09-25", "09-26"] },
    { 'Week 4': ["09-27", "09-28", "09-29", "09-30", "10-01", "10-02", "10-03"] },
    { 'Week 5': ["10-4", "10-5", "10-6", "10-7", "10-8", "10-9", "10-10"] },
    { 'Week 6': ["10-11", "10-12", "10-13", "10-14", "10-15", "10-16", "10-17"] },
    { 'Week 7': ["10-18", "10-19", "10-20", "10-21", "10-22", "10-23", "10-24"] },
    { 'Week 8': ["10-25", "10-26", "10-27", "10-28", "10-29", "10-30", "10-31"] },
    { 'Week 9': ["11-1", "11-2", "11-3", "11-4", "11-5", "11-6", "11-7"] },
    { 'Week 10': ["11-8", "11-9", "11-10", "11-11", "11-12", "11-13", "11-14"] },
    { 'Week 11': ["11-15", "11-16", "11-17", "11-18", "11-19", "11-20", "11-21"] },
    { 'Week 12': ["11-22", "11-23", "11-24", "11-25", "11-26", "11-27", "11-28"] },
    { 'Week 13': ["11-29", "11-30", "11-1", "12-1", "12-2", "12-3", "12-4", "12-5"] },
    { 'Week 14': ["12-6", "12-7", "12-8", "12-9", "12-10", "12-11", "12-12"] },
    { 'Week 15': ["12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19"] },
    { 'Week 16': ["12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26"] },
    { 'Week 17': ["12-27", "12-28", "12-29", "12-30", "12-31",] },

  ],

  2024: [
    { 'Week 17': ["01-01", "01-02"] },
    { 'Week 18': ["01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09", "01-10", "01-11", "01-12"] },
    { 'Week 18': ["01-13", "01-14", "01-15", "01-16"] },
    { 'Week 18': ["01-17", "01-18", "01-19", "01-20", "01-21", "01-22"] },
    { 'Week 18': ["01-23", "01-24", "01-25", "01-26", "01-27", "01-28", "01-29", "01-30", "01-31"] },
    { 'Week 18': ["02-01", "02-02", "02-03", "02-04", "02-05", "02-06", "02-07"] },
    { 'Week 18': ["02-08", "02-09", "02-10", "02-11", "02-12", "02-13", "02-14"] },
    { 'Week 18': ["02-15", "02-16", "02-17", "02-18", "02-19", "02-20", "02-21"] },
    { 'Week 18': ["02-22", "02-23", "02-24", "02-25", "02-26", "02-27", "02-28"] },
    { 'Week 18': ["03-01", "03-02", "03-03", "03-04", "03-05", "03-06", "03-07"] },
    { 'Week 18': ["03-08", "03-09", "03-10", "03-11", "03-12", "03-13", "03-14"] },
    { 'Week 18': ["03-15", "03-16", "03-17", "03-18", "03-19", "03-20", "03-21"] },
    { 'Week 18': ["03-22", "03-23", "03-24", "03-25", "03-26", "03-27", "03-28"] },
    { 'Week 18': ["03-29","03-30","04-01", "04-02", "04-03", "04-04", "04-05", "04-06", "04-07"] },
    { 'Week 18': ["04-08", "04-09", "04-10", "04-11", "04-12", "04-13", "04-14"] },
    { 'Week 18': ["04-15", "04-16", "04-17", "04-18", "04-19", "04-20", "04-21"] },
    { 'Week 18': ["04-22", "04-23", "04-24", "04-25", "04-26", "04-27", "04-28"] },
    { 'Week 18': ["04-29","04-30","05-01", "05-02", "05-03", "05-04", "05-05", "05-06", "05-07"] },
    { 'Week 18': ["05-08", "05-09", "05-10", "05-11", "05-12", "05-13", "05-14"] },
    { 'Week 18': ["05-15", "05-16", "05-17", "05-18", "05-19", "05-20", "05-21"] },
    { 'Week 18': ["05-22", "05-23", "05-24", "05-25", "05-26", "05-27", "05-28"] },
    { 'Week 18': ["05-29","05-30","06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"] },
    { 'Week 18': ["06-08", "06-09", "06-10", "06-11", "06-12", "06-13", "06-14"] },
    { 'Week 18': ["06-15", "06-16", "06-17", "06-18", "06-19", "06-20", "06-21"] },
    { 'Week 18': ["06-22", "06-23", "06-24", "06-25", "06-26", "06-27", "06-28"] },
    { 'Week 18': ["06-29","06-30","07-01", "07-02", "07-03", "07-04", "07-05", "07-06", "07-07"] },
    { 'Week 18': ["07-08", "07-09", "07-10", "07-11", "07-12", "07-13", "07-14"] },
    { 'Week 18': ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"] },
    { 'Week 18': ["07-22", "07-23", "07-24", "07-25", "07-26", "07-27", "07-28"] },
    { 'PreSeason Hall of Fame Weekend': ["07-29","07-30","08-01", "08-02", "08-03", "08-04", "08-05", "08-06", "08-07"] },
    { 'PreSeason Week 1': ["08-08", "08-09", "08-10", "08-11", "08-12", "08-13", "08-14"] },
    { 'PreSeason Week 2': ["08-15", "08-16", "08-17", "08-18", "08-19", "08-20", "08-21"] },
    { 'PreSeason Week 3': ["08-22", "08-23", "08-24", "08-25", "08-26", "08-27"] },
    { 'Week 1': ["08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04", "09-05", "09-06", "09-07", "09-08", "09-09", "09-10", "09-11", "09-12"] },
    { 'Week 2': ["09-13", "09-14", "09-15", "09-16", "09-17", "09-18", "09-19"] },
    { 'Week 3': ["09-20", "09-21", "09-22", "09-23", "09-24", "09-25", "09-26"] },
    { 'Week 4': ["09-27", "09-28", "09-29", "09-30", "10-01", "10-02", "10-03"] },
    { 'Week 5': ["10-4", "10-5", "10-6", "10-7", "10-8", "10-9", "10-10"] },
    { 'Week 6': ["10-11", "10-12", "10-13", "10-14", "10-15", "10-16", "10-17"] },
    { 'Week 7': ["10-18", "10-19", "10-20", "10-21", "10-22", "10-23", "10-24"] },
    { 'Week 8': ["10-25", "10-26", "10-27", "10-28", "10-29", "10-30", "10-31"] },
    { 'Week 9': ["11-1", "11-2", "11-3", "11-4", "11-5", "11-6", "11-7"] },
    { 'Week 10': ["11-8", "11-9", "11-10", "11-11", "11-12", "11-13", "11-14"] },
    { 'Week 11': ["11-15", "11-16", "11-17", "11-18", "11-19", "11-20", "11-21"] },
    { 'Week 12': ["11-22", "11-23", "11-24", "11-25", "11-26", "11-27", "11-28"] },
    { 'Week 13': ["11-29", "11-30", "11-1", "12-1", "12-2", "12-3", "12-4", "12-5"] },
    { 'Week 14': ["12-6", "12-7", "12-8", "12-9", "12-10", "12-11", "12-12"] },
    { 'Week 15': ["12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19"] },
    { 'Week 16': ["12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26"] },
    { 'Week 17': ["12-27", "12-28", "12-29", "12-30", "12-31",] },

  ],
  2025: [
    { 'Week 17': ["01-01", "01-02"] },
    { 'Week 18': ["01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09", "01-10", "01-11", "01-12"] },
    { 'Week 18': ["01-13", "01-14", "01-15", "01-16"] },
    { 'Week 18': ["01-17", "01-18", "01-19", "01-20", "01-21", "01-22"] },
    { 'Week 18': ["01-23", "01-24", "01-25", "01-26", "01-27", "01-28", "01-29", "01-30", "01-31"] },
    { 'Week 18': ["02-01", "02-02", "02-03", "02-04", "02-05", "02-06", "02-07"] },
    { 'Week 18': ["02-08", "02-09", "02-10", "02-11", "02-12", "02-13", "02-14"] },
    { 'Week 18': ["02-15", "02-16", "02-17", "02-18", "02-19", "02-20", "02-21"] },
    { 'Week 18': ["02-22", "02-23", "02-24", "02-25", "02-26", "02-27", "02-28"] },
    { 'Week 18': ["03-01", "03-02", "03-03", "03-04", "03-05", "03-06", "03-07"] },
    { 'Week 18': ["03-08", "03-09", "03-10", "03-11", "03-12", "03-13", "03-14"] },
    { 'Week 18': ["03-15", "03-16", "03-17", "03-18", "03-19", "03-20", "03-21"] },
    { 'Week 18': ["03-22", "03-23", "03-24", "03-25", "03-26", "03-27", "03-28"] },
    { 'Week 18': ["03-29","03-30","04-01", "04-02", "04-03", "04-04", "04-05", "04-06", "04-07"] },
    { 'Week 18': ["04-08", "04-09", "04-10", "04-11", "04-12", "04-13", "04-14"] },
    { 'Week 18': ["04-15", "04-16", "04-17", "04-18", "04-19", "04-20", "04-21"] },
    { 'Week 18': ["04-22", "04-23", "04-24", "04-25", "04-26", "04-27", "04-28"] },
    { 'Week 18': ["04-29","04-30","05-01", "05-02", "05-03", "05-04", "05-05", "05-06", "05-07"] },
    { 'Week 18': ["05-08", "05-09", "05-10", "05-11", "05-12", "05-13", "05-14"] },
    { 'Week 18': ["05-15", "05-16", "05-17", "05-18", "05-19", "05-20", "05-21"] },
    { 'Week 18': ["05-22", "05-23", "05-24", "05-25", "05-26", "05-27", "05-28"] },
    { 'Week 18': ["05-29","05-30","06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"] },
    { 'Week 18': ["06-08", "06-09", "06-10", "06-11", "06-12", "06-13", "06-14"] },
    { 'Week 18': ["06-15", "06-16", "06-17", "06-18", "06-19", "06-20", "06-21"] },
    { 'Week 18': ["06-22", "06-23", "06-24", "06-25", "06-26", "06-27", "06-28"] },
    { 'Week 18': ["06-29","06-30","07-01", "07-02", "07-03", "07-04", "07-05", "07-06", "07-07"] },
    { 'Week 18': ["07-08", "07-09", "07-10", "07-11", "07-12", "07-13", "07-14"] },
    { 'Week 18': ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"] },
    { 'Week 18': ["07-22", "07-23", "07-24", "07-25", "07-26", "07-27", "07-28"] },
    { 'PreSeason Hall of Fame Weekend': ["07-29","07-30","08-01", "08-02", "08-03", "08-04", "08-05", "08-06", "08-07"] },
    { 'PreSeason Week 1': ["08-08", "08-09", "08-10", "08-11", "08-12", "08-13", "08-14"] },
    { 'PreSeason Week 2': ["08-15", "08-16", "08-17", "08-18", "08-19", "08-20", "08-21"] },
    { 'PreSeason Week 3': ["08-22", "08-23", "08-24", "08-25", "08-26", "08-27"] },
    { 'Week 1': ["08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04", "09-05", "09-06", "09-07", "09-08", "09-09", "09-10", "09-11", "09-12"] },
    { 'Week 2': ["09-13", "09-14", "09-15", "09-16", "09-17", "09-18", "09-19"] },
    { 'Week 3': ["09-20", "09-21", "09-22", "09-23", "09-24", "09-25", "09-26"] },
    { 'Week 4': ["09-27", "09-28", "09-29", "09-30", "10-01", "10-02", "10-03"] },
    { 'Week 5': ["10-4", "10-5", "10-6", "10-7", "10-8", "10-9", "10-10"] },
    { 'Week 6': ["10-11", "10-12", "10-13", "10-14", "10-15", "10-16", "10-17"] },
    { 'Week 7': ["10-18", "10-19", "10-20", "10-21", "10-22", "10-23", "10-24"] },
    { 'Week 8': ["10-25", "10-26", "10-27", "10-28", "10-29", "10-30", "10-31"] },
    { 'Week 9': ["11-1", "11-2", "11-3", "11-4", "11-5", "11-6", "11-7"] },
    { 'Week 10': ["11-8", "11-9", "11-10", "11-11", "11-12", "11-13", "11-14"] },
    { 'Week 11': ["11-15", "11-16", "11-17", "11-18", "11-19", "11-20", "11-21"] },
    { 'Week 12': ["11-22", "11-23", "11-24", "11-25", "11-26", "11-27", "11-28"] },
    { 'Week 13': ["11-29", "11-30", "11-1", "12-1", "12-2", "12-3", "12-4", "12-5"] },
    { 'Week 14': ["12-6", "12-7", "12-8", "12-9", "12-10", "12-11", "12-12"] },
    { 'Week 15': ["12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19"] },
    { 'Week 16': ["12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26"] },
    { 'Week 17': ["12-27", "12-28", "12-29", "12-30", "12-31",] },

  ]


}


export const weekListDateNcaaf = {

  2022: [
    { 'Week 1': ["08-26", "09-04"] },
    { 'Week 2': ["09-07", "09-10"] },
    { 'Week 3': ["09-14", "09-17"] },
    { 'Week 4': ["09-21", "09-24"] },
    { 'Week 5': ["09-28", "09-30"] },
    { 'Week 6': ["10-04", "10-08"] },
    { 'Week 7': ["10-10", "10-15"] },
    { 'Week 8': ["10-17", "10-22"] },
    { 'Week 9': ["10-24", "10-29"] },
    { 'Week 10': ["10-31", "11-04"] },
    { 'Week 11': ["11-07", "11-11"] },
    { 'Week 12': ["11-14", "11-18"] },
    { 'Week 13': ["11-22", "11-25"] },
    { 'Week 14': ["12-01", "12-03"] },
    { 'Week 15': ["12-08", "12-10"] },
    { 'Week Bowls': ["12-16", "01-09"] },
  ],
  2023: [
    { 'Week 1': ["08-23", "08-24", "08-25", "08-26", "08-27", "08-28", "08-29", "08-30", "08-31", "09-01", "09-02", "09-03", "09-04"] },
    { 'Week 2': ["09-05", "09-06", "09-07", "09-08", "09-09", "09-10"] },
    { 'Week 3': ["09-11", "09-12", "09-13", "09-14", "09-15", "09-16", "09-17"] },
    { 'Week 4': ["09-18", "09-19", "09-20", "09-21", "09-22", "09-23", "09-24"] },
    { 'Week 5': ["09-25", "09-26", "09-27", "09-28", "09-29", "09-30"] },
    { 'Week 6': ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-07", "10-08"] },
    { 'Week 7': ["10-09", "10-10", "10-11", "10-12", "10-13", "10-14", "10-15"] },
    { 'Week 8': ["10-16", "10-17", "10-18", "10-19", "10-20", "10-21", "10-22"] },
    { 'Week 9': ["10-23", "10-24", "10-25", "10-26", "10-27", "10-28", "10-29"] },
    { 'Week 10': ["10-30", "10-31", "11-01", "11-02", "11-03", "11-04"] },
    { 'Week 11': ["11-05", "11-06", "11-07", "11-08", "11-09", "11-10", "11-11"] },
    { 'Week 12': ["11-12", "11-13", "11-14", "11-15", "11-16", "11-17", "11-18"] },
    { 'Week 13': ["11-19", "11-20", "11-21", "11-22", "11-23", "11-24", "11-25"] },
    { 'Week 14': ["11-26", "11-27", "11-28", "11-29", "11-30", "12-01", "12-02", "12-03"] },
    { 'Week 15': ["12-04", "12-05", "12-06", "12-07", "12-08", "12-09", "12-10"] },
    { 'Week Bowls': ["12-11", "12-12", "12-13", "12-14", "12-15", "12-16", "12-17", "12-18", "12-19", "12-20", "12-21", "12-22", "12-23", "12-24", "12-25", "12-26", "12-27", "12-28", "12-29", "12-30", "12-31", "01-09"] },
    { 'Week 15': ["01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09", "01-10", "01-11", "01-12"] },
    { 'Week 15': ["01-13", "01-14", "01-15", "01-16"] },
    { 'Week 15': ["01-17", "01-18", "01-19", "01-20", "01-21", "01-22"] },
    { 'Week 15': ["01-23", "01-24", "01-25", "01-26", "01-27", "01-28", "01-29", "01-30", "01-31"] },
    { 'Week 15': ["02-01", "02-02", "02-03", "02-04", "02-05", "02-06", "02-07"] },
    { 'Week 15': ["02-08", "02-09", "02-10", "02-11", "02-12", "02-13", "02-14"] },
    { 'Week 15': ["02-15", "02-16", "02-17", "02-18", "02-19", "02-20", "02-21"] },
    { 'Week 15': ["02-22", "02-23", "02-24", "02-25", "02-26", "02-27", "02-28"] },
    { 'Week 15': ["03-01", "03-02", "03-03", "03-04", "03-05", "03-06", "03-07"] },
    { 'Week 15': ["03-08", "03-09", "03-10", "03-11", "03-12", "03-13", "03-14"] },
    { 'Week 15': ["03-15", "03-16", "03-17", "03-18", "03-19", "03-20", "03-21"] },
    { 'Week 15': ["03-22", "03-23", "03-24", "03-25", "03-26", "03-27", "03-28"] },
    { 'Week 15': ["03-29","03-30","04-01", "04-02", "04-03", "04-04", "04-05", "04-06", "04-07"] },
    { 'Week 15': ["04-08", "04-09", "04-10", "04-11", "04-12", "04-13", "04-14"] },
    { 'Week 15': ["04-15", "04-16", "04-17", "04-18", "04-19", "04-20", "04-21"] },
    { 'Week 15': ["04-22", "04-23", "04-24", "04-25", "04-26", "04-27", "04-28"] },
    { 'Week 15': ["04-29","04-30","05-01", "05-02", "05-03", "05-04", "05-05", "05-06", "05-07"] },
    { 'Week 15': ["05-08", "05-09", "05-10", "05-11", "05-12", "05-13", "05-14"] },
    { 'Week 15': ["05-15", "05-16", "05-17", "05-18", "05-19", "05-20", "05-21"] },
    { 'Week 15': ["05-22", "05-23", "05-24", "05-25", "05-26", "05-27", "05-28"] },
    { 'Week 15': ["05-29","05-30","06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"] },
    { 'Week 15': ["06-08", "06-09", "06-10", "06-11", "06-12", "06-13", "06-14"] },
    { 'Week 15': ["06-15", "06-16", "06-17", "06-18", "06-19", "06-20", "06-21"] },
    { 'Week 15': ["06-22", "06-23", "06-24", "06-25", "06-26", "06-27", "06-28"] },
    { 'Week 15': ["06-29","06-30","07-01", "07-02", "07-03", "07-04", "07-05", "07-06", "07-07"] },
    { 'Week 15': ["07-08", "07-09", "07-10", "07-11", "07-12", "07-13", "07-14"] },
    { 'Week 15': ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"] },
    { 'Week 15': ["07-22", "07-23", "07-24", "07-25", "07-26", "07-27", "07-28"] },
    { 'Week 15': ["07-29","07-30","08-01", "08-02", "08-03", "08-04", "08-05", "08-06", "08-07"] },
    { 'Week 15': ["08-08", "08-09", "08-10", "08-11", "08-12", "08-13", "08-14"] },
    { 'Week 15': ["08-15", "08-16", "08-17", "08-18", "08-19", "08-20", "08-21"] },
    { 'Week 15': ["08-22", "08-23", "08-24", "08-25", "08-26", "08-27"] },
  ],

  2024: [
    { 'Week Bowls': ["01-01", "01-02", "01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09"] },
    { 'Week 1': ["08-26", "09-04"] },
    { 'Week 2': ["09-07", "09-10"] },
    { 'Week 3': ["09-14", "09-17"] },
    { 'Week 4': ["09-21", "09-24"] },
    { 'Week 5': ["09-28", "09-30"] },
    { 'Week 6': ["10-04", "10-08"] },
    { 'Week 7': ["10-10", "10-15"] },
    { 'Week 8': ["10-17", "10-22"] },
    { 'Week 9': ["10-24", "10-29"] },
    { 'Week 10': ["10-31", "11-04"] },
    { 'Week 11': ["11-07", "11-11"] },
    { 'Week 12': ["11-14", "11-18"] },
    { 'Week 13': ["11-22", "11-25"] },
    { 'Week 14': ["12-01", "12-03"] },
    { 'Week 15': ["01-03", "01-04", "01-05", "01-06", "01-07", "01-08", "01-09", "01-10", "01-11", "01-12"] },
    { 'Week 15': ["01-13", "01-14", "01-15", "01-16"] },
    { 'Week 15': ["01-17", "01-18", "01-19", "01-20", "01-21", "01-22"] },
    { 'Week 15': ["01-23", "01-24", "01-25", "01-26", "01-27", "01-28", "01-29", "01-30", "01-31"] },
    { 'Week 15': ["02-01", "02-02", "02-03", "02-04", "02-05", "02-06", "02-07"] },
    { 'Week 15': ["02-08", "02-09", "02-10", "02-11", "02-12", "02-13", "02-14"] },
    { 'Week 15': ["02-15", "02-16", "02-17", "02-18", "02-19", "02-20", "02-21"] },
    { 'Week 15': ["02-22", "02-23", "02-24", "02-25", "02-26", "02-27", "02-28"] },
    { 'Week 15': ["03-01", "03-02", "03-03", "03-04", "03-05", "03-06", "03-07"] },
    { 'Week 15': ["03-08", "03-09", "03-10", "03-11", "03-12", "03-13", "03-14"] },
    { 'Week 15': ["03-15", "03-16", "03-17", "03-18", "03-19", "03-20", "03-21"] },
    { 'Week 15': ["03-22", "03-23", "03-24", "03-25", "03-26", "03-27", "03-28"] },
    { 'Week 15': ["03-29","03-30","04-01", "04-02", "04-03", "04-04", "04-05", "04-06", "04-07"] },
    { 'Week 15': ["04-08", "04-09", "04-10", "04-11", "04-12", "04-13", "04-14"] },
    { 'Week 15': ["04-15", "04-16", "04-17", "04-18", "04-19", "04-20", "04-21"] },
    { 'Week 15': ["04-22", "04-23", "04-24", "04-25", "04-26", "04-27", "04-28"] },
    { 'Week 15': ["04-29","04-30","05-01", "05-02", "05-03", "05-04", "05-05", "05-06", "05-07"] },
    { 'Week 15': ["05-08", "05-09", "05-10", "05-11", "05-12", "05-13", "05-14"] },
    { 'Week 15': ["05-15", "05-16", "05-17", "05-18", "05-19", "05-20", "05-21"] },
    { 'Week 15': ["05-22", "05-23", "05-24", "05-25", "05-26", "05-27", "05-28"] },
    { 'Week 15': ["05-29","05-30","06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"] },
    { 'Week 15': ["06-08", "06-09", "06-10", "06-11", "06-12", "06-13", "06-14"] },
    { 'Week 15': ["06-15", "06-16", "06-17", "06-18", "06-19", "06-20", "06-21"] },
    { 'Week 15': ["06-22", "06-23", "06-24", "06-25", "06-26", "06-27", "06-28"] },
    { 'Week 15': ["06-29","06-30","07-01", "07-02", "07-03", "07-04", "07-05", "07-06", "07-07"] },
    { 'Week 15': ["07-08", "07-09", "07-10", "07-11", "07-12", "07-13", "07-14"] },
    { 'Week 15': ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"] },
    { 'Week 15': ["07-22", "07-23", "07-24", "07-25", "07-26", "07-27", "07-28"] },
    { 'Week 15': ["07-29","07-30","08-01", "08-02", "08-03", "08-04", "08-05", "08-06", "08-07"] },
    { 'Week 15': ["08-08", "08-09", "08-10", "08-11", "08-12", "08-13", "08-14"] },
    { 'Week 15': ["08-15", "08-16", "08-17", "08-18", "08-19", "08-20", "08-21"] },
    { 'Week 15': ["08-22", "08-23", "08-24", "08-25", "08-26", "08-27"] },
    { 'Week Bowls': ["12-16", "01-09"] }

  ],


}


//Schedule api integration
export const getAllNflSchedulelist = async (year, week, teamId,  onSuccess, onFailure) => {
  console.log(year, week,teamId, "bcr")

  try {
    const res = await webApi.post(

      `/nflScheduleList`,
      {
        year: year ? year : "",
        week: week ? week : "",
        team: teamId ? teamId : ""
      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};








