import foferror from "../Assets/images/errornotfound.png";


const NotFound = () => {
    console.log('Not Found');
    return (
        <>
        <div style={{height:"454px"}}>
             <p style={{ color: "white", textAlign: "center", fontSize: "100px", marginRight: "500px" }}></p>
            <p style={{ color: "white", textAlign: "center", fontSize: "100px", marginRight: "500px" }}></p>
            &nbsp;

            <p style={{ color: "white", textAlign: "center", fontSize: "100px",marginTop:"100px"}}>404</p>

            &nbsp;
            <p style={{ color: "white", textAlign: "center", fontSize: "100px", marginRight: "500px" }}></p>
            <p style={{ color: "white", textAlign: "center", fontSize: "100px", marginRight: "500px" }}></p>


            <p style={{ color: "white", textAlign: "center", fontSize: "25px", marginBottom:"100px"}}>Page not found</p>
            &nbsp;

            </div>

        </>
    )
}

export default NotFound;