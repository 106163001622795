import React, { useEffect, useState } from "react";
import ScroreBoard from "../homepage/ScroreBoard";
import Headerarticles from "../ArticlesWR/Headerarticles";
import Videos from "../homepage/Videos";
import Comments from "../ArticlesWR/Comments";
import { useLocation } from "react-router-dom";
import { articleDetailsByLinkApi, articleRelatedListApi } from "../../service/articleService";
import { Helmet } from "react-helmet";
import GlobalConfig from "../../GlobalConfig";

const AritclesDetails = () => {

  const [relatedListData, set_relatedListData] = useState({});
  const [latestListData, set_latestListData] = useState({});
  const [currentCatUrl, set_currentCatUrl] = useState('');

  const location = useLocation();
  const permalinkId = location.pathname.split('article/')[1]; 
  const id = permalinkId.split('/')[1];

  const [dataRow, set_dataRow] = useState({});
  useEffect(() => {
    articleDetailsByLink();
  }, [id]);

  async function articleDetailsByLink() {
    articleDetailsByLinkApi(id).then(function (result) {
      const response = result.data;
      set_dataRow(response.response_data);
 
      set_currentCatUrl('/articles/' + response.response_data.category.permalink)
    });


    articleRelatedListApi(id).then(function (result) {
      const response = result.data;
      set_relatedListData(response.response_data.related);
      set_latestListData(response.response_data.latest);
    });
  }

console.log('dataRow', dataRow)

  return (
    <>
      <div className="bg_color_black_1a1a1a articles_wr overflow-x-hidden">

        <Helmet>
          <title> {(dataRow.meta_title != "null") ? dataRow.meta_title + ' | ' : '' }  Artile | {GlobalConfig.SITE_NAME} </title>
          <meta name="description" content={(dataRow.meta_description) ? dataRow.meta_description : ""} />
          <meta name="keywords" content={(dataRow.meta_keyword) ? dataRow.meta_keyword : ""} />
        </Helmet>
        <ScroreBoard />
        <Headerarticles articleData={dataRow}
          relatedListData={relatedListData}
          latestListData={latestListData}
          currentCatUrl={currentCatUrl} />
        <Videos viewOn="article" videoTitle="You May Also Interested" />
        <Comments articleId={id} />
      </div>
    </>
  );
};

export default AritclesDetails;
