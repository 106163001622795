import React from "react";
import { NcaafReceivingStatTableTh } from "./Helper";
import DownArrowIcon from "../../Assets/arrow-down.svg";
import btnBgImg from "../../Assets/img/gray-strip-btn-bg.png";
import { getNcaaPlayerLink } from "../NCAA/HelperNcaa";
import { Link } from "react-router-dom";

const StatsTableReceiving = ({ ncaaStatListReceiving }) => {
  return (
    <>
      <div className={`w-100  pt-2`}  >
        <div
          className={`border-top-bottom-grey border-top-0 d-flex justify-content-between position-relative pb-2"
              }`}
        >
          <div className={`position-absolute d-none d-xl-block gray_strip_btn_bg_2}`}>
            {/* gray_strip_btn_bg" : "gray_strip_btn_bg_2 */}
            <img src={btnBgImg} alt="" />
          </div>
          <h3 className={`font-16 fw-semibold nav_tabs_stats_btn text-uppercase table_btn_custom_padding mb-0 custom_margin_left_minus_2`}>
            {/*  custom_margin_left_minus"  "custom_margin_left_minus_2 */}
            <span className="table_details_stats_heading">Receiving</span>
          </h3>
        </div>

        <div className="table-responsive stats-details-table team_leaders_overflow">
          <table className="table table-striped mb-0">
            <thead>
              <tr  >
                {NcaafReceivingStatTableTh.map((heading, index) => (
                  <th className={`font-16 fw-semibold px-4 py-3 border-0 ${heading == 'NAME' && "team_stat_table_min_width"}`} key={index} scope="col" colSpan="3" >
                    <span className="text-nowrap">{heading}
                      {
                        heading == 'YDS' &&
                        <span className="ms-3">
                          {/* <img className="down-arrow-icon mb-1" src={DownArrowIcon} alt="DownArrowIcon" /> */}
                        </span>
                      }
                    </span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="border-0 table_team_stat_heading">
              {ncaaStatListReceiving && ncaaStatListReceiving.length > 0 && ncaaStatListReceiving.map((obj, index) => (
                <tr className="gamelog-tr" key={index}>
                  <td className="font-18 fw-medium blue text-start px-4 py-2" colSpan="3"  >
                    <Link to={getNcaaPlayerLink(obj)}>{obj.player_name}</Link>
                    <span className="font-14 fw-light color-grey ms-2">
                      {obj.name2}
                    </span>
                  </td>

                  {/* <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3" >{obj.gamePlayed}</td> */}
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{obj.receptions}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{Number(obj.receiving_targets) ? obj.receiving_targets : 0}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2 yds_col" colSpan="3">{obj.receiving_yards}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{obj.yards_per_reception_avg}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{obj.receiving_touchdowns}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{obj.longest_reception}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{Number(obj.over_20_yards) ? obj.over_20_yards : 0}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{Number(obj.yards_per_game) ? obj.yards_per_game : 0}</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{Number(obj.fumbles) ? obj.fumbles : 0 }</td>
                  <td className="font-18 fw-medium text-start px-4 py-2" colSpan="3">{Number(obj.fumbles_lost) ? obj.fumbles_lost : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default StatsTableReceiving;
