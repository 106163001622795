import tableImg1 from "../../NcaafFrontAssets/HomepageAssets/img/ncaaf-team-1.png";

export const teamTableList = [
  {
    buttonHeading: "",
    teamTableListItem: [
      {
        tableHeading: "AMERICAN",
        tableContent: [
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Cincinnati Bearcats",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "East Carolina Pirates",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Houston Cougars",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Memphis Tigers",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Navy Midshipmen",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "SMU Mustangs",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "South Florida Bulls",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Temple Owls",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Tulane Green Wave",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "Tulsa Golden Hurricane",
          },
          {
            placeLocation: "Stats  |  Schedule  |  Roster",
            tableFirstTeamImg: tableImg1,
            teamFirstName: "UCF Knights",
          },
        ],
      },
    ],
  },
];
