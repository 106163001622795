import React from "react";
import { useEffect } from "react";
import { getCmsData } from "../../service/cmsService";
import { useState } from "react"; 
import { useLocation } from "react-router-dom";
import { manageTitle } from "../../functions/HeaderManage";
import { Helmet } from "react-helmet";

const CmsCommon = () => {
  // document.title('ayon');
  const location = useLocation();
  const [cmsData, set_cmsData] = useState({});

  useEffect(() => {
    cmsdetailsByUrl();
  }, [location.pathname]);

  async function cmsdetailsByUrl() {
    getCmsData(location.pathname).then(function (result) {
      const response = result.data;
      console.log(response);
      // manageTitle(response.response_data.cms_title); 
      set_cmsData(response.response_data);
    });
  }

  return (
    <>
    <Helmet>
      <title>
        NFL | {(cmsData.meta_title) ? cmsData.meta_title : ""}
      </title>
      <meta
        name="description"
        content={
          (cmsData.meta_description) ? cmsData.meta_description : ""
        }
      />
      <meta
        name="keywords"
        content={(cmsData.meta_keyword) ? cmsData.meta_keyword : ""}
      />
    </Helmet>
      <section className="innerMainbg">
        <div className="innerBannerbg">
          <div className="innerShadow">
            <div className="innerDots">
              <div className="innerHeading">
                <h2>{cmsData.cms_title}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="innerBodycontent noMrgnTop">
          <div className="container customContainer">
            <div className="row mb-4">
              <div className="col-md-12 aboutContent">
                {/* <h3>{cmsData.cms_title}</h3> */}
                {/* <img src={innerdot} alt="" /> */}
                <br />
                <br />
                {
                  <p  dangerouslySetInnerHTML={{
                      __html: cmsData.cms_description,
                    }}
                 //   style={{ color: "white" }}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CmsCommon;
