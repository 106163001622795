import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import objectImg from "../../Assets/images/object.png";
import dnldImg from "../../Assets/images/dnld.png";
import heartImg from "../../Assets/images/heart.png";
import soundImg from "../../Assets/images/sound.png";
import clockImg from "../../Assets/images/clock.png";
import pauseImg from "../../Assets/images/pause.png";
import playicon from "../../Assets/images/playicon.png";
import Videos from "../homepage/Videos"
import ArrowIcon from "../../../src/components/Navbar/assets/arrowicon.png";
import ReactAudioPlayer from 'react-audio-player';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { userFavPod } from "../../service/authService";
import { getAllFavourite } from "../../service/cmsService";

const AudioPlayer = ({ favPodId, src, img, title, subTitle }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [plug, setPlug] = useState(false);
  const [fav, setFav] = useState(true);
  const [podcastList, set_podcastList] = useState([]);
  const [favId, setFavId] = useState();
  const [soundmute, setSoundMute] = useState(true);
  console.log(src, "3105234")

  const audioRef = React.createRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // setFavId(favPodId);

    podcastListData();
  }, []);
  console.log(favPodId, "favIdfavId")


  async function podcastListData() {
    getAllFavourite().then(function (result) {
      const response = result.data;
      const newList = response.response_data[0]?.podcast_id
      console.log(response, "response")

      console.log(newList, "newList")
      set_podcastList(newList);
    });
  }
  // console.log(formatTime(duration - currentTime),"remani")


  useEffect(() => {
    const audioElement = audioRef.current;


    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audioElement.currentTime);
    };


    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);
  console.log(favPodId, "desdes")
  // const muteSound = async (id) => {};

  const muteSound = () => {

    if (isPlaying || !isPlaying) {
      console.log("yes")
      audioRef.current.muted = true;
      setSoundMute(false);

    }
  };
  const muteSound2 = () => {

    if (isPlaying || !isPlaying) {

      console.log("yes")
      audioRef.current.muted = false;
      setSoundMute(true);

    }
  };

  const onHeart = async (id) => {
    const data = {
      podcast_id: id,
      isFavourite: podcastList?.filter(data => data._id === id).length > 0 ? 0 : 1
    }
    // const Token=localStorage.getItem('ff_AuthToken')

    if (localStorage.getItem('ff_isLogin')) {
      await userFavPod(data).then(function (result) {
        try {
          const response = result.data;
          let checkedData = {};
          checkedData = podcastList?.find((item) => item._id === id);
          if (typeof checkedData != 'undefined' && Object.keys(checkedData).length > 0) {
            var newList = podcastList?.filter((item) => item._id != id);
            set_podcastList(newList);
          } else {
            set_podcastList([...podcastList, { _id: id }]);
          }
        }
        catch {
          console.log("podcast added")
        }
      })


    } else {
      history.push({ pathname: '/login', state: location.pathname })
    }
  };



  useEffect(() => {
    if (duration && (Math.round(duration) == Math.round(currentTime))) {
      setPlug(false);
      setIsPlaying(false);
    }
  }, [currentTime])


  const togglePlay = () => {
    setPlug(true);
    if (isPlaying) {
      audioRef.current.pause();
      setPlug(false);

    } else {
      audioRef.current.play();
      setPlug(true);

    }
    setIsPlaying(!isPlaying);

  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleSeek = (e) => {
    const seekTime = parseFloat(e.target.value);
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  return (
    <div className='podcastMain'>

      <div className={`theread ${(plug) && `clickFocus`}`}>
        <img src={img} alt="" style={{ width: "100%", height: "100%" }} />
        <span className= {isPlaying?"pause" :"pause2"}>
          <img src={isPlaying?pauseImg:playicon} alt="" onClick={togglePlay} />
          {/* {isPlaying ? 'Pause' : 'Play'} */}

        </span>

      </div>








      <div className="podAudiobg">
        <div className="podAudioHeading">
          <span>{title}</span>
          <div className="instrumnt">
            <a href={src} target="_blank" rel="noopener noreferrer">
              {/* <div class="cust">
              <input type="checkbox" id="favs3"/>
              <label for="favs3">
              <i class="off fa-regular fa-download"></i>
              <i class="on fa-solid fa-download"></i>
                </label>
            </div> */}
              <img src={dnldImg} alt="Download" />
            </a>
            {/* <img src={heartImg} alt="Heart" />
            <img src={soundImg} alt="Sound" /> */}




            {localStorage.getItem('ff_isLogin') ? <><div onClick={() => onHeart(favPodId)}>
              {podcastList?.filter(data => data._id === favPodId).length > 0 ?
                <><input type="checkbox" />
                  <label className='favouriteIcn'>
                    <i class="fa-solid fa-heart" style={{ color: "#2192ff" }}></i>

                  </label></>
                : <><input type="checkbox" />
                  <label className='favouriteIcn'>
                    <i class="fa-regular fa-heart"></i>
                  </label></>}

            </div></> :
              <><div onClick={() => onHeart(favPodId)}>
                {podcastList?.filter(data => data._id === favPodId).length > 0 ?
                  <><input type="checkbox" />
                    <label className='favouriteIcn' >
                      <i class="fa-regular fa-heart" ></i>

                    </label></>
                  : <><input type="checkbox" />
                    <label className='favouriteIcn' >
                      <i class="fa-regular fa-heart"></i>
                    </label></>}

              </div></>}


            {soundmute === true ?
              <><div className={`cust ${(soundmute) && `muteIcon`}`}>
                <input type="checkbox" />
                <label>
                  <i class="off fa-light fa-volume-low" onClick={() => muteSound(favPodId)}></i>
                  {/* <i class="on fa-solid fa-volume-low"></i> */}
                  {/* <i class="on fa-solid fa-volume-xmark" style={{ color: "#2192ff" }}></i> */}
                </label>
              </div></> : <> <div className={`cust ${(soundmute) && `muteIcon`}`}>
                <input type="checkbox" />
                <label>
                  <i class="off fa-solid fa-volume-xmark" style={{ color: "#2192ff" }} onClick={() => muteSound2(favPodId)}></i>
                  {/* <i class="on fa-solid fa-volume-low"></i> */}
                  {/* <i class="on fa-solid fa-volume-xmark" ></i> */}
                </label>
              </div></>
            }




          </div>
        </div>
        <div className="podSecondtext">
          {subTitle} <span className="dotbg">&nbsp;</span>
          <img src={clockImg} alt="" />
          &nbsp; <span id="">{formatTime(duration)}</span>
        </div>

        <div>
          <audio ref={audioRef} src={src} />

          <input
            type="range"
            min={0}
            max={Math.round(duration)}
            value={Math.round(currentTime)}
            onChange={handleSeek}
          />

          <div className='audTime'>
            <span>{formatTime(Math.round(currentTime))}</span>
            <span>remaining time : {formatTime(Math.round(duration) - Math.round(currentTime))}</span>


            <span>{formatTime(Math.round(duration))}</span>
          </div>
        </div>

      </div>



    </div>
  );
};

export default AudioPlayer;
