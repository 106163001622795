import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./navbar.module.scss";
import { FaSearch } from "react-icons/fa";
import { useEffect } from "react";

const HeaderSearch = () => {
  const [topsearch, setTopsearch] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [searchData, set_searchData] = useState("");
  // console.log('queryParams', queryParams.get("s"))

  useEffect(() => {
    set_searchData(queryParams.get("s"));
  },[]);

  const togsearch = () => {
    setTopsearch(!topsearch);
  }



  const history = useHistory();
  const headerSearchHandler = (e) => {
    var searchVal = e.target.value;
    console.log(searchVal)
    set_searchData(searchVal);

    if (e.key === 'Enter') {
      history.push(`/search?s=${searchVal}`);
    }
  }

  return (
    <div className={`navsrchs ${topsearch && `searchNw`}`}>
      <input type="text" placeholder="Search" value={searchData}
       onChange={headerSearchHandler} onKeyDown={headerSearchHandler} />
      <button type="button" className={styles.btn} onClick={togsearch} >
        <FaSearch />
      </button>
    </div>
  );
};

export default HeaderSearch;
