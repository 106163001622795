import React, { useEffect, useState } from "react";
import ScroreBoard from "../components/homepage/ScroreBoard";
import NcaafTeamScheduleTable from "../components/ncaafTeamDetailSchedule.jsx/NcaafTeamScheduleTable";
import { useLocation } from "react-router-dom";
import { getNcaaTeamDetailsApi } from "../service/NcaaService";



const NcaafTeamDetailSchedule = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [teamDetails, set_teamDetails] = useState({});
  const [teamId, set_teamId] = useState(queryParams.get("team"));



  useEffect(() => {
    getNcaaTeamDetails();
  }, [teamId]);

  async function getNcaaTeamDetails() {
    getNcaaTeamDetailsApi(teamId).then(function (result) {
      const response = result.data;
      set_teamDetails(response.response_data);
    });
  }

  return (
    <>
   
      <div className="pb-5 mb-sm-5">
        <div className="ncaaf-scoreboard-page-bg d-flex flex-column ">
          <ScroreBoard />
          {/* <div className="pt-5 custom-mb-minus">
          <NcaafHero value={ teamDetails && teamDetails.team_name } />

          </div> */}
        </div>
        <NcaafTeamScheduleTable />
      </div>
    </>
  );
};

export default NcaafTeamDetailSchedule;
