import React, { useState } from "react";
import gsap from "gsap";
import { motion, AnimatePresence } from "framer-motion";
import { useHistory } from "react-router-dom";

const NfcLink = ({ item, viewTeam, mouseLeaveHandler }) => {
  const history = useHistory();

  const [activeDropdown, setActiveDropdown] = useState("");
  const dropdownOpenHandler = (value) => {
    if (value == activeDropdown) {
      setActiveDropdown("");
      gsap.fromTo(
        ".animated-el",
        {
          y: 0,
          x: 0,
          opacity: 1,
          height: "auto",
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.2,
          height: "auto",
        }
      );
    } else {
      setActiveDropdown(value);
      gsap.fromTo(
        ".animated-el",
        {
          opacity: 0,
          y: -20,
          height: 0,
        },
        {
          y: 0,
          x: 0,
          duration: 0.2,
          opacity: 1,
          height: "auto",
        }
      );
    }
  };
  const routeHandler = (item) => {
    if (item.isLink) {
      mouseLeaveHandler();
      history.push(item.url);
      // window.location = item.url;
    }
  };
  return (
    <AnimatePresence>
      {viewTeam && (
        <motion.div
          initial={{ scale: 0, transformOrigin: "left top", opacity: "0" }}
          animate={{ scale: 1, transformOrigin: "left top", opacity: "1" }}
          exit={{ scale: 0, transformOrigin: "left top", opacity: "0" }}
          className="w-100  bottom-list"
        >
          <div className="row w-100">
            <div className="col-auto px-5 mb-5">
              {/*============ BOTTOM DROPDOWN ROW ONE LINK MAP ============*/}
              {item.NFC_LINK.rowOne.map((item, index) => (
                <>
                  <li> {item.title} </li>
                  <div className="d-flex mt-4 flex-column">
                    {item.subUrl &&
                      item.subUrl.map((item, index) => (
                        <div
                          onClick={() => dropdownOpenHandler(item.title)}
                          key={index}
                          className={`dropdown-bottom-link`}
                          to={item.url}
                        >
                          {item.icon}
                          <span className="d-inline-block pl-3">
                            {item.title}
                          </span>
                          <div className="d-flex animated-el flex-column">
                            {item.title == activeDropdown ? (
                              <>
                                {item.nfcSub &&
                                  item.nfcSub.map((item, index) => (
                                    <div
                                      onClick={() => routeHandler(item)}
                                      className="afc-sub-url"
                                    >
                                      {item.title}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>
            <div className="col-auto px-5  px-5mb-5">
              {/*============ BOTTOM DROPDOWN ROW TOW LINK MAP ============*/}

              {item.NFC_LINK.rowTwo.map((item, index) => (
                <>
                  <li> {item.title} </li>
                  <div className="d-flex mt-4 flex-column">
                    {item.subUrl &&
                      item.subUrl.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => dropdownOpenHandler(item.title)}
                          className={`dropdown-bottom-link`}
                          to={item.url}
                        >
                          {item.icon}
                          <span className="d-inline-block pl-3">
                            {item.title}
                          </span>
                          <div className="d-flex animated-el flex-column">
                            {item.title == activeDropdown ? (
                              <>
                                {item.nfcSub &&
                                  item.nfcSub.map((item, index) => (
                                    <div
                                      onClick={() => routeHandler(item)}
                                      className="afc-sub-url"
                                    >
                                      {item.title}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>{" "}
            <div className="col-auto px-5 mb-5">
              {/*============ BOTTOM DROPDOWN ROW THREE LINK MAP ============*/}
              {item.NFC_LINK.rowThree.map((item, index) => (
                <>
                  <li> {item.title} </li>
                  <div className="d-flex mt-4 flex-column">
                    {item.subUrl &&
                      item.subUrl.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => dropdownOpenHandler(item.title)}
                          className={`dropdown-bottom-link`}
                          to={item.url}
                        >
                          {item.icon}
                          <span className="d-inline-block pl-3">
                            {item.title}
                          </span>
                          <div className="d-flex animated-el flex-column">
                            {item.title == activeDropdown ? (
                              <>
                                {item.nfcSub &&
                                  item.nfcSub.map((item, index) => (
                                    <div
                                      onClick={() => routeHandler(item)}
                                      className="afc-sub-url"
                                    >
                                      {item.title}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>{" "}
            <div className="col-auto px-5 mb-5">
              {/*============ BOTTOM DROPDOWN ROW FOUR LINK MAP ============*/}

              {item.NFC_LINK.rowFour.map((item, index) => (
                <>
                  <li> {item.title} </li>
                  <div className="d-flex mt-4 flex-column">
                    {item.subUrl &&
                      item.subUrl.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => dropdownOpenHandler(item.title)}
                          className={`dropdown-bottom-link`}
                          to={item.url}
                        >
                          {item.icon}
                          <span className="d-inline-block pl-3">
                            {item.title}
                          </span>
                          <div className="d-flex animated-el flex-column">
                            {item.title == activeDropdown ? (
                              <>
                                {item.nfcSub &&
                                  item.nfcSub.map((item, index) => (
                                    <div
                                      onClick={() => routeHandler(item)}
                                      href={item.url}
                                      className="afc-sub-url"
                                    >
                                      {item.title}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NfcLink;
