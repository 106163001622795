import React from "react";

const Prospects = () => {
  return (
    <>
      <div className="py-4 pt-xxl-5 pb-xxl-0 mt-xxl-5 team-detail-propspects">
        <h3 className="font-34 font-web fw-bold prospect-heading mb-5 text-nowrap mt-5 mt-sm-0">
          <span>N</span>
          <span>F</span>
          <span>L</span> 2022 Prospects
        </h3>
        <div className="team-table text-white mb-5 ">
          <div className="d-flex team-content px-4 py-4 mt-2">
            <h3 className="font-12 fw-medium w-50 mb-0">NAME</h3>
            <h3 className="font-13 fw-medium w-25 mb-0 text-center pe-1">
              POS
            </h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0 pe-3">
              SCHOOL
            </h3>
            <h3 className="font-12 fw-medium w-25 text-end mb-0">GRADE</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between px-4 py-2">
            <h3 className="text-nowrap font-12 mb-0 fw-light w-50 py-4 blue">
              Chamon Metayer
            </h3>
            <h3 className="font-13 mb-0 fw-light text-center w-25 ms-3">OLB</h3>
            <h3 className="font-12 mb-0 fw-light text-end w-25 ps-1">
              Committed
            </h3>
            <h3 className="font-12 mb-0 fw-light  text-end w-25 pe-2">80</h3>
          </div>
          <div className="bg-light-blue3 d-flex align-items-center justify-content-between px-4 py-2">
            <h3 className="font-12 mb-0 fw-light w-50 py-4 blue">
              Zeiqui Lawton
            </h3>
            <h3 className="font-13 mb-0 fw-light text-center w-25">DE</h3>
            <h3 className="font-12 mb-0 fw-light text-end w-25 ps-1">
              Committed
            </h3>
            <h3 className="font-12 mb-0 fw-light  text-end w-25 pe-2">79</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between px-4 py-2">
            <h3 className="text-nowrap font-12 mb-0 fw-light w-50 py-4 blue">
              Luke Collinsworth
            </h3>
            <h3 className="font-13 mb-0 fw-light text-center w-25">OT</h3>
            <h3 className="font-12 mb-0 fw-light text-end w-25 ps-1">
              Committed
            </h3>
            <h3 className="font-12 mb-0 fw-light  text-end w-25 pe-2">79</h3>
          </div>
          <div className="bg-light-blue3 d-flex align-items-center justify-content-between px-4 py-2">
            <h3 className="font-12 mb-0 fw-light w-50 py-4 blue">
              Jack Dingle
            </h3>
            <h3 className="font-13 mb-0 fw-light text-center w-25 ps-3">OLB</h3>
            <h3 className="font-12 mb-0 fw-light text-end w-25 ps-1">
              Committed
            </h3>
            <h3 className="font-12 mb-0 fw-light  text-end w-25 pe-2">77</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between px-4 py-2">
            <h3 className="font-12 mb-0 fw-light w-50 py-4 blue">
              Byron Threats
            </h3>
            <h3 className="font-13 mb-0 fw-light text-start w-25 ps-4 ms-5">
              S
            </h3>
            <h3 className="font-12 mb-0 fw-light text-end w-25">Committed</h3>
            <h3 className="font-12 mb-0 fw-light  text-end w-25 pe-2">76</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prospects;
