import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { DropDownContextProvider } from "./context/dropDownContext";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import GlobalConfig from "./GlobalConfig";

ReactDOM.render(
  <>
    <HashRouter>
      <Helmet>
        <title>{GlobalConfig.SITE_NAME}</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <ToastContainer />
      <DropDownContextProvider>
        <App />
      </DropDownContextProvider>
    </HashRouter>
  </>,
  document.getElementById("root")
);

reportWebVitals();
