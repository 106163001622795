import React from "react";
import ArrowIcon from "../../components/Navbar/assets/arrowicon.png";
import Articles from "../common/Articles";
import { playernewsArray } from "../common/data";
import HighlightsWeekCard from "../common/HighlightsWeekCard";
import PlayerProfileBottomSlider from "./PlayerProfileBottomSlider";
import HeadingDotted from "../common/HeadingDotted";

const PlayerProfileBottomNews = () => {
  const slicedArraytoTwo = playernewsArray.slice(0, 2);

  return (
    <>
      <div className="newssection position-relative py-lg-5">
        <div className="container py-5">
          <div className="row justify-content-between w-100 mx-0">
            <div className="col-lg-12">
              <div className="py-5 mb-5">
                <h1 className="heading white font-web skew-heading"> HIGHLIGHTS WEEK </h1>
                <HeadingDotted />
              </div>
              {/* <HighlightsWeekCard /> */}
              <PlayerProfileBottomSlider />
              {/* <p className="text-end white mb-0 font-web font-semibold para text-hover cursor-pointer pt-3">
                VIEW All HIGHLIGHTS WEEK
                <span className="ms-3">
                  <img src={ArrowIcon} alt="ArrowIcon" />
                </span>
              </p> */}
            </div>
            {/* <div className="col-lg-5 position-relative">
              <div className="py-5 mb-5">
                <h1 className="heading white font-web skew-heading ">
                  PLAYERS NEWS
                </h1>
                <span className="first-box d-inline-block"></span>
                <span className="second-box d-inline-block mx-2"></span>
                <span className="third-box d-inline-block"></span>
              </div>
              <div className="player-news-background py-4 mb-5">
                <div className="d-flex flex-column col-7 py-4 h-100 justify-content-end">
                  <h1 className="font-web white fw-bold font-xsm">
                    Matchup Previews + Week 5
                  </h1>
                  <p className="font-web white para mb-0">
                    Injury Analysis with Dr. David Chao
                  </p>
                  <p className="font-web white para mb-0">
                    (2020 Fantasy Football)
                  </p>
                </div>
              </div>

              <div className="mt-5 pt-5">
                {slicedArraytoTwo.map((playernews, index) => (
                  <Articles key={index} playernews={playernews} />
                ))}
              </div>
              <p className="text-end white mb-0 font-web font-semibold para text-hover cursor-pointer pt-3">
                VIEW ALL ARTICLES
                <span className="ms-3">
                  <img src={ArrowIcon} alt="ArrowIcon" />
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerProfileBottomNews;
