const GlobalConfig = {
    'SITE_NAME': "The NFL Draft, NFL Draft Logo, and NCAAF are copyright of the National Football League and in no way are owned by Cerberus Fantasy Sports LLC. This page is intended to provide links to Cerberus Fantasy Sports Draft coverage and is in no way affiliated with the Official NFL Draft site. For the official site please visit nfl.com or NCAA.com",

    'API_URL': "https://cerberusfantasysports.com:9000/api/web/",
    // 'API_URL': "http://localhost:9000/api/web/",

   // 'WEB_URL': "https://nodeserver.mydevfactory.com/projects/debarati/arghya/nfl/nfl-web/#/",
    //  'WEB_URL': "http://localhost:3000/projects/debarati/arghya/nfl/nfl-web#/",
    'WEB_URL': "https://cerberusfantasysports.com",
    'CONTACT_US_MAIL_1' : "cfs@gmail.com",
    'CONTACT_US_MAIL_2' : "support24@gmail.com",
    'CONTACT_US_ADDRESS' : "100 N Aurora Ave #APT 19 Oakland, Nebraska(NE), 68045",
    'CONTACT_US_PH_NO_1' : "(402) 685-5964",
    'CONTACT_US_PH_NO_2' : "+88 (00) 4568 457 437",

    'FOOTER_ADDRESS' : "1145 Forrest St. Suite 200 Conshohocken, PA 19428",
    'FOOTER_PH_NO' : "+01 12345 67890",
    'FOOTER_EMAIL' : "cerberusfootballfantasy@example.com",
    'FOOTER_FB_LINK' : "https://www.facebook.com/share/yRsF6VzWLiC6hr9C/?mibextid=qi2Omg",
    'FOOTER_FB_TWITTER' : "https://x.com/CerberusFS2023?t=XWrdHM6giiKQsn_2B4dj3w&s=09",
    'FOOTER_FB_INSTAGRAM' : "https://www.instagram.com/cerberusfs2023?igsh=eGRiMzJjdXZpYWsx",
    'FOOTER_FB_YOUTUBE' : "https://youtube.com/@cerberusfantasysports2023?si=yGEifUwtMi6MN01c",

    
    'NFL_FB_LINK' : "https://www.facebook.com/share/yRsF6VzWLiC6hr9C/?mibextid=qi2Omg",
    'NFL_TWITTER_LINK' : "https://x.com/CerberusFS2023?t=XWrdHM6giiKQsn_2B4dj3w&s=09",
    'NFL_INSTAGRAM_LINK' : "https://www.instagram.com/cerberusfs2023?igsh=eGRiMzJjdXZpYWsx",
    'NFL_YOUTUBE_LINK' : "https://youtube.com/@cerberusfantasysports2023?si=yGEifUwtMi6MN01c",
    'NFL_SNAPCHAT_LINK' : "https://www.snapchat.com/add/cerberusfs23?share_id=N2A4la1X4s4&locale=en-US",

     
    'NCAAF_FB_LINK' : "https://www.facebook.com/share/yRsF6VzWLiC6hr9C/?mibextid=qi2Omg",
    'NCAAF_TWITTER_LINK' : "https://x.com/CerberusFS2023?t=XWrdHM6giiKQsn_2B4dj3w&s=09",
    'NCAAF_INSTAGRAM_LINK' : "https://www.instagram.com/cerberusfs2023?igsh=eGRiMzJjdXZpYWsx",
    'NCAAF_YOUTUBE_LINK' : "https://youtube.com/@cerberusfantasysports2023?si=yGEifUwtMi6MN01c",
    'NCAAF_SNAPCHAT_LINK' : "https://www.snapchat.com/add/cerberusfs23?share_id=N2A4la1X4s4&locale=en-US"

     

    
}

export default GlobalConfig;