
const testVideo = () => {
    var youTubeVideoLink = "https://www.youtube.com/watch?v=QcUU89xKu70";
    var youTubeVideoLinkEmbed = youTubeVideoLink.replace('watch?v=', 'embed/');
    console.log('youTubeVideoLinkEmbed', youTubeVideoLinkEmbed)
    return <>
        <h1>Test</h1>
        {/* <iframe width="853" height="480" src="https://www.youtube.com/watch?v=QcUU89xKu70" title="How To Embed YouTube Videos in React / Gatsby (and make them Responsive)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
    
        {/* <iframe width="500" height="300" src={youTubeVideoLinkEmbed}  type="video/mp4" ></iframe> */}

       
        {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/xNRJwmlRBNU" title="How To Embed YouTube Videos in React / Gatsby (and make them Responsive)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
    
    </>
}


export default testVideo;