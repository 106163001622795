import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GlobalConfig from "../GlobalConfig";
import { articleListApi } from "../service/articleService";
import ScroreBoard from "../components/homepage/ScroreBoard";
import HeaderNflArtiles from "../components/Aritcles/HeaderArtilesNfl";
import HeaderArtilesNcaaf from "../components/Aritcles/HeaderArtilesNcaaf";
import NewsSection from "../components/homepage/NewsSection";
import HeaderArtilesDevy from "../components/Aritcles/HeaderArtilesDevy";
import HeaderArtilesCampusCanton from "../components/Aritcles/HeaderArtilesCampusCanton";
import HeaderArtilesDynasty from "../components/Aritcles/HeaderArtilesDynasty";
import HeaderArtilesSeasonLong from "../components/Aritcles/HeaderArtilesSeasonLong";
import ColorRingCustomLoader from "../components/common/ColorRingCustomLoader";

const Articles = () => {
  const [articleListData, set_articleListData] = useState({});
  const [isLoader, set_isLoader] = useState(true);

  useEffect(() => {
    getArticleListData();
  }, []);

  async function getArticleListData() {
    articleListApi().then(function (result) {
      const response = result.data;
      set_articleListData(response.response_data);
      set_isLoader(false);
    });
  }

  console.log('articleListData', articleListData);


  var nflList = '';
  var ncaaList = '';
  var DEVYList = '';
  var CAMPUSList = '';
  var DYNASTYList = '';
  var SEASONList = '';
  var otherList = '';
  {
    Object.entries(articleListData).map((objL, keyL) => {
      if (objL[0] == 'NFL') {
        nflList = <HeaderNflArtiles catName={objL[0]} dataList={objL[1]} />
      } else if (objL[0] == 'NCAAF') {
        ncaaList = <HeaderArtilesNcaaf catName={objL[0]} dataList={objL[1]} />
      } else if (objL[0] == 'DEVY') {
        DEVYList = <HeaderArtilesDevy catName={objL[0]} dataList={objL[1]} />
      } else if (objL[0] == 'CAMPUS 2 CANTON') {
        CAMPUSList = <HeaderArtilesCampusCanton catName={objL[0]} dataList={objL[1]} />
      } else if (objL[0] == 'DYNASTY') {
        DYNASTYList = <HeaderArtilesDynasty catName={objL[0]} dataList={objL[1]} />
      } else if (objL[0] == 'SEASON LONG') {
        SEASONList = <HeaderArtilesSeasonLong catName={objL[0]} dataList={objL[1]} />
      } else {
        otherList = otherList + (<HeaderArtilesSeasonLong catName={objL[0]} dataList={objL[1]} />)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>  Articles | {GlobalConfig.SITE_NAME} </title>
        <meta name="description" content={` Artiles | ${GlobalConfig.SITE_NAME}`} />
        <meta name="keywords" content={` Artiles | ${GlobalConfig.SITE_NAME}`} />
      </Helmet>
      {/* <ScroreBoard/> */}
      {/* //====== Header + NFL  ===// */}
      {(isLoader) ?
        <ColorRingCustomLoader isLoader={isLoader} />
        :
        (
          <>
            {nflList}
            {ncaaList}
            {DEVYList}
            {CAMPUSList}
            {DYNASTYList}
            {SEASONList}
            {otherList}
            {/* {Object.entries(articleListData).map((objL, keyL) =>
              <>
                {(objL[0] == 'NFL') && <HeaderNflArtiles catName={objL[0]} dataList={objL[1]} />}
                {(objL[0] == 'NCAAF') && <HeaderArtilesNcaaf catName={objL[0]} dataList={objL[1]} />}
                {(objL[0] == 'DEVY') && <HeaderArtilesDevy catName={objL[0]} dataList={objL[1]} />}
                {(objL[0] == 'CAMPUS 2 CANTON') && <HeaderArtilesCampusCanton catName={objL[0]} dataList={objL[1]} />}
                {(objL[0] == 'DYNASTY') && <HeaderArtilesDynasty catName={objL[0]} dataList={objL[1]} />}
                {(objL[0] == 'SEASON LONG') && <HeaderArtilesSeasonLong catName={objL[0]} dataList={objL[1]} />}

              </>
            )} */}

            {/* {Array.isArray(articleListData['NFL']) ? <HeaderNflArtiles catName="NFL" dataList={articleListData['NFL']} /> : ""} */}
            {/* {Array.isArray(articleListData['NCAAF']) ? <HeaderArtilesNcaaf catName="NCAAF" dataList={articleListData['NCAAF']} /> : ""}
            {Array.isArray(articleListData['DEVY']) ? <HeaderArtilesDevy catName="DEVY" dataList={articleListData['DEVY']} /> : ""}
            {Array.isArray(articleListData['CAMPUS 2 CANTON']) ? <HeaderArtilesCampusCanton catName="CAMPUS 2 CANTON" dataList={articleListData['CAMPUS 2 CANTON']} /> : ""}
            {Array.isArray(articleListData['DYNASTY']) ? <HeaderArtilesDynasty catName="DYNASTY" dataList={articleListData['DYNASTY']} /> : ""}
            {Array.isArray(articleListData['SEASON LONG']) ? <HeaderArtilesSeasonLong catName="SEASON LONG" dataList={articleListData['SEASON LONG']} /> : ""} */}
          </>
        )}

      <NewsSection />
    </>
  );
};

export default Articles;
