import webApi from "../../WebApi/WebApi";

// export const getAllNflFantasylist = async (year,team, week,onSuccess, onFailure) => {
//     console.log(year,team,"bcr")

//     try {
//       const res = await webApi.post(

//         `/getFantasyScore`,
//         {
//           season: year ? Number(year) : "",
//           team_id:Number(team)?Number(team):1696,
//           week:week?week:"",
//           page:"NFL"
//         //   week: week ? week : returnDate,
//         //   team: teamId ? teamId : ""
//         }
//       );
//       if (res.status === 200) {
//         const r = res.data;
//         console.log(r, "rerere")

//         return onSuccess(r);

//       } else {
//         onFailure("Something Wrong! Please Try again later" + res.data);
//       }
//     } catch (error) {
//       onFailure("Something Wrong! Please Try again later" + error);
//     }
//   };






//NEW UPDATED API
//---------------------------->>>>>>>>>><<<<<<<<<-----------------------------

export const getAllNflFantasylist = async (year, week, team, pos, score_type, onSuccess, onFailure) => {
  console.log(year, "bcr")

  try {
    const res = await webApi.post(

      `/getFantasyScoreRecent`,
      {
        season: year ? Number(year) : "",
        week: week ? week : "",
        team_id: Number(team) ? Number(team) : "",
        player_position: pos ? pos : "",
        score_type: score_type,
        page: "NFL",
        limit: 50,

      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};

export const getAllNcaafFantasylist = async (year, week, team, pos, score_type, onSuccess, onFailure) => {
  console.log(week, "week")

  try {
    const res = await webApi.post(

      `/getFantasyScoreRecent`,
      {
        season: year ? Number(year) : "",
        week:week ? week : "",
        team_id: Number(team) ? Number(team) : "",
        player_position: pos ? pos : "",
        score_type: score_type,
        page: "NCAAF",
        limit: 50,

      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};