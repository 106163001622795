import React from "react";
import AmericanFootballStandings from "../components/nflStanding/AmericanFootballStandings";

const NflStandings = () => {
  return (
    <>
      
      <AmericanFootballStandings />

    </>
  );
};

export default NflStandings;
