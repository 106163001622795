import topHeadingMen2 from "../../Assets/img/top-heading-men.png";
import eagleImg from "../../Assets/img/eagleimg.png";
import blueEagleImg from "../../Assets/img/blueeagleimg.png";
export const aboutPlayerBioList = [
  {
    numberUrl: `5' 11`,
    description: "Height",
  },
  {
    numberUrl: `1.08`,
    description: "Draft Pick",
  },
  {
    numberUrl: `202 lbs`,
    description: "Weight",
  },
  {
    numberUrl: `Stanford`,
    description: "College",
  },
  {
    numberUrl: `28.2 (12nd)`,
    description: "BMI",
  },
  {
    numberUrl: `24.4`,
    description: "Age",
  },
];
export const dataSlider_not_use = [
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
  {
    img: topHeadingMen2,
    text: "  Matchup Previews + Week 5 Injury Analysis With Dr. David Caho(2020 FANTASY FOOTBALL)",
  },
];
export const gameLogDataFirst = [
  { name: " REGULAR SEASON" },
  { name: "PASSING" },
  { name: "RUSHING" },
];
export const gameLogDataSecond = [
  { name: "DATE" },
  { name: "OPP" },
  { name: "RESULT" },
  { name: "CMP" },
  // { name: "ATT" },
  { name: "YDS" },
  { name: "CMP%" },
  { name: "AVG" },
  { name: "TD" },
  { name: "INT" },
  // { name: "LNG" },
  { name: "SACK" },
  { name: "RTG" },
  { name: "QBR" },
  { name: "ATT" },
  { name: "YDS" },
  { name: "AVG" },
  { name: "TD" },
  { name: "LNG" },
];
export const gameLogBodyData = [
  {
    date: "Mon 11/16",
    opp: "@",
    result: "L 32-30",
    cmp1: "32",
    att: "49",
    yds: "284",
    cmp2: "65.3",
    avg: "5.8",
    td: "2",
    int: "2",
    lng: "31",
    sack: "0",
    rtg: "77.3",
    qbr: "70.4",
    att2: "7",
    avg2: "5.4",
    td2: "0",
    lng2: "15",
    imgUrl: eagleImg,
    blueAri: "ARI",
    coloredText: "L",
    text: "32-30",
  },
  {
    date: "Sun 11/8",
    opp: "vs",
    result: "L 32-30",
    cmp1: "31",
    att: "38",
    cmp2: "81.6",
    avg: "10.9",
    td: "3",
    int: "0",
    lng: "39",
    sack: "7",
    rtg: "138.5",
    qbr: "79.3",
    att2: "7",
    yds: "14",
    avg2: "2.0",
    td2: "1",
    lng2: "7",
    imgUrl: blueEagleImg,
    blueAri: "SEA",
    coloredText: "W",
    text: "44-34",
  },
  {
    date: "Mon 11/16",
    opp: "@",
    result: "L 32-30",
    cmp1: "32",
    att: "49",
    yds: "284",
    cmp2: "65.3",
    avg: "5.8",
    td: "2",
    int: "2",
    lng: "31",
    sack: "0",
    rtg: "77.3",
    qbr: "70.4",
    att2: "7",
    avg2: "5.4",
    td2: "0",
    lng2: "15",
    imgUrl: eagleImg,
    blueAri: "ARI",
    coloredText: "L",
    text: "32-30",
  },
  {
    date: "Sun 11/8",
    opp: "vs",
    result: "L 32-30",
    cmp1: "31",
    att: "38",
    yds: "415",
    cmp2: "81.6",
    avg: "10.9",
    td: "3",
    int: "0",
    lng: "39",
    sack: "7",
    rtg: "138.5",
    qbr: "79.3",
    att2: "7",
    avg2: "2.0",
    td2: "1",
    lng2: "7",
    imgUrl: blueEagleImg,
    blueAri: "SEA",
    coloredText: "W",
    text: "44-34",
  },
  {
    date: "Mon 11/16",
    opp: "@",
    result: "L 32-30",
    cmp1: "32",
    att: "49",
    yds: "284",
    cmp2: "65.3",
    avg: "5.8",
    td: "2",
    int: "2",
    lng: "31",
    sack: "0",
    rtg: "77.3",
    qbr: "70.4",
    att2: "7",
    avg2: "5.4",
    td2: "0",
    lng2: "15",
    imgUrl: eagleImg,
    blueAri: "ARI",
    coloredText: "L",
    text: "32-30",
  },
  {
    date: "Sun 11/8",
    opp: "vs",
    result: "L 32-30",
    cmp1: "31",
    att: "38",
    cmp2: "81.6",
    avg: "10.9",
    td: "3",
    int: "0",
    lng: "39",
    sack: "7",
    rtg: "138.5",
    qbr: "79.3",
    att2: "7",
    yds: "14",
    avg2: "2.0",
    td2: "1",
    lng2: "7",
    imgUrl: blueEagleImg,
    blueAri: "SEA",
    coloredText: "W",
    text: "44-34",
  },
];
