import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import objectImg from "../../Assets/images/object.png";
import dnldImg from "../../Assets/images/dnld.png";
import heartImg from "../../Assets/images/heart.png";
import soundImg from "../../Assets/images/sound.png";
import clockImg from "../../Assets/images/clock.png";
import pauseImg from "../../Assets/images/pause.png";
import Videos from "../homepage/Videos"
import ArrowIcon from "../../../src/components/Navbar/assets/arrowicon.png";
import AudioPodcast from "../homepage/AudioPodcast"

import { getAllPodcastlist } from "../../service/cmsService";

const Podcast = (index,podcastRow) => {
    const [podcastList, set_podcastList] = useState({});

    const str_pad_left = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }
    const timeUpdate = (event) => {
        var audioFileId = event.target.id;

        var audioFileIdIndex = audioFileId.split("__")[1];

        const minutes = Math.floor(event.target.currentTime / 60);
        const seconds = Math.floor(event.target.currentTime - minutes * 60);
        const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

        document.getElementById("audioStartTime__" + audioFileIdIndex).innerHTML = currentTime;

        const m = Math.floor(event.target.duration / 60);
        const s = Math.floor(event.target.duration - m * 60);
        const duration = str_pad_left(m, '0', 2) + ':' + str_pad_left(s, '0', 2);

        document.getElementById("duration" + audioFileIdIndex).innerHTML = duration;

        const remain = currentTime.split(":")[1];
        const remain1 = duration.split(":")[1];

        const remain3 = currentTime.split(":")[0];
        const remain4 = duration.split(":")[0];

        const final = remain1 - remain;
        const final1 = remain4 - remain3;


        document.getElementById("remain" + audioFileIdIndex).innerHTML = "remaining time is " + "0" + final1 + ":" + final;

    }


    const togglePlaying = (e, index) => {

        const myAudio = document.getElementById(index); 
        if (myAudio.paused) {
            myAudio.play();
        } else {
            myAudio.pause();
        }
    }

    useEffect(() => {
        podcastListData();
    }, []);

    async function podcastListData() {
        getAllPodcastlist("ncaaf_home").then(function (result) {
            const response = result.data;
            console.log(response.response_data, "321321");
            set_podcastList(response.response_data);
        });
    }

   
    return (
        <>
            <section className="innerMainbg">
                <div className="podcastBannerbg">
                    <div className="innerShadow">
                        <div className="innerDots">
                            <div className="innerHeading">
                                <img src={objectImg} className="objImg" alt="" />
                                <h2>NCAAF Podcasts</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="innerBodycontent">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="podcastContent">

                                <AudioPodcast viewOn="ncaaf" />

                                    {/* <div className="prodPagination">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li className="page-item disabled">
                          <span className="page-link">Previous</span>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item active" aria-current="page">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
                                </div>
                            </div>
                        </div>
                       
                     
                    </div>
                </div>
            </section>
        </>
    );
};

export default Podcast;
