import React from "react";
import NcaafFrontFootballStats from "../NcaafFrontComponents/NcaafFrontfantasyStats/NcaafFrontFootballStats";

const NcaafFrontfantasyStats = () => {
  return (
    <>
      <NcaafFrontFootballStats />
    </>
  );
};

export default NcaafFrontfantasyStats;
