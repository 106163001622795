import imagePath from "../../Assets/football-fantasy-Img1.png";
import imagePath2 from "../../Assets/fantasy-table-img-2.png";
import imagePath3 from "../../Assets/fantasy-table-img-3.png";
import imagePath4 from "../../Assets/fantasy-table-img-4.png";

export const fantasyFootballTableListMain = [
  {
    fantasyTableFixed: [
      {
        srNo: "1",
        imgUrl: imagePath,
        teamName: "Justin Herbert",
        teamDesc: "F •",
        teamDesc1: " 39 ",
        teamDesc2: "- 29 vs. JAX",
      },
      {
        srNo: "2",
        imgUrl: imagePath3,
        teamName: "Tyler Lockett",
        teamDesc: "F F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "3",
        imgUrl: imagePath4,
        teamName: "Kyler Murray",
        teamDesc: "F/OT •",
        teamDesc1: " 37 ",
        teamDesc2: "- 34 vs. SEA",
      },
      {
        srNo: "4",
        imgUrl: imagePath2,
        teamName: "Tom Brady",
        teamDesc: "F • 20 -",
        teamDesc1: " 45 ",
        teamDesc2: "@ LV",
      },
      //====================================
      {
        srNo: "5",
        imgUrl: imagePath,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      {
        srNo: "6",
        imgUrl: imagePath3,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "7",
        imgUrl: imagePath4,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
      {
        srNo: "8",
        imgUrl: imagePath2,
        teamName: "Russell Wilson",
        teamDesc: "F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      //====================================
      {
        srNo: "9",
        imgUrl: imagePath,
        teamName: "Tyler Lockett",
        teamDesc: "F F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "10",
        imgUrl: imagePath3,
        teamName: "Kyler Murray",
        teamDesc: "F/OT •",
        teamDesc1: " 37 ",
        teamDesc2: "- 34 vs. SEA",
      },
      {
        srNo: "11",
        imgUrl: imagePath4,
        teamName: "Tom Brady",
        teamDesc: "F • 20 -",
        teamDesc1: " 45 ",
        teamDesc2: "@ LV",
      },
      {
        srNo: "12",
        imgUrl: imagePath2,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      //====================================
      {
        srNo: "13",
        imgUrl: imagePath,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "14",
        imgUrl: imagePath3,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
      {
        srNo: "15",
        imgUrl: imagePath4,
        teamName: "Russell Wilson",
        teamDesc: "F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "16",
        imgUrl: imagePath2,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      //====================================
      {
        srNo: "17",
        imgUrl: imagePath,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "18",
        imgUrl: imagePath3,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
      {
        srNo: "19",
        imgUrl: imagePath4,
        teamName: "Russell Wilson",
        teamDesc: "F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "20",
        imgUrl: imagePath2,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      //====================================
      {
        srNo: "21",
        imgUrl: imagePath,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "22",
        imgUrl: imagePath3,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
      {
        srNo: "23",
        imgUrl: imagePath4,
        teamName: "Russell Wilson",
        teamDesc: "F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "24",
        imgUrl: imagePath2,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      //====================================
      {
        srNo: "25",
        imgUrl: imagePath,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "26",
        imgUrl: imagePath3,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
      {
        srNo: "27",
        imgUrl: imagePath4,
        teamName: "Russell Wilson",
        teamDesc: "F/OT • 37 -",
        teamDesc1: " 34 ",
        teamDesc2: "@ ARI",
      },
      {
        srNo: "28",
        imgUrl: imagePath2,
        teamName: "Joe Burrow",
        teamDesc: "F •",
        teamDesc1: " 34 ",
        teamDesc2: "- 37 vs. CLE",
      },
      //====================================
      {
        srNo: "29",
        imgUrl: imagePath,
        teamName: "Davante Adams",
        teamDesc: "F • 20 -",
        teamDesc1: " 35 ",
        teamDesc2: "@ HOU",
      },
      {
        srNo: "30",
        imgUrl: imagePath3,
        teamName: "Jeff Wilson Jr. P",
        teamDesc: "F • 6 -",
        teamDesc1: " 33 ",
        teamDesc2: "@ NE",
      },
    ],

    fantasyFootballTableList: [
      {
        team: "LAC",
        pos: "7",
        wk: "QB",
        opp: "JAX",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "347",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "ARI",
        pos: "7",
        wk: "SEA",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "360",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "TB",
        pos: "7",
        wk: "LV",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "369",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "388",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "ARI",
        pos: "7",
        wk: "SEA",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "360",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "TB",
        pos: "7",
        wk: "LV",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "369",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "388",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "388",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "388",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SEA",
        pos: "7",
        wk: "ARI",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "388",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "CIN",
        pos: "7",
        wk: "CLE",
        opp: "QB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "406",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      //====================================
      {
        team: "GB",
        pos: "7",
        wk: "HOU",
        opp: "WR",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
      {
        team: "SF",
        pos: "7",
        wk: "NE",
        opp: "RB",
        fptsG: "38.5",
        fpts: "38.48",
        yds1: "0",
        td1: "3",
        int: "0",
        yds2: "66",
        td2: "1",
        rec: "0",
        yds3: "0",
        td3: "0",
        tt: "0",
        ast: "0",
        td4: "0",
        sck: "0",
        int2: "0",
        ff: "0",
        fr: "0",
      },
    ],
  },
];
