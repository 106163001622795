import React from "react";
import { useEffect, useState } from "react";
import { weekListDate, weekListDateNcaaf } from "../../../src/components/nfl-schedule/Helper";

const NcaafFrontFantasyTableItem = ({ allNflList }) => {
  const [startWeek, setStartWeek] = useState("");

  console.log(allNflList, "WILLDO")

  const getWeek = (d, m) => {
    if (d > 9) {
      if (m > 9) {
        return `${m}-${d}`
      }
      else {
        return `0${m}-${d}`

      }
    }
    else {
      if (m > 9) {
        return `${m}-${d}`
      }
      else {
        return `0${m}-0${d}`

      }
    }
  }

  // useEffect(async () => {
  //   const curYear = new Date().getFullYear();
  //   const curDay = getWeek(new Date().getDate(), new Date().getMonth() + 1);
  //   console.log(curDay, "dayyyyyy")
  //   let returnDate = "";
  //   weekListDate[curYear].forEach((element, index) => {

  //     Object.values(element).forEach((val, key) => {
  //       val.forEach(d => {
  //         console.log(curDay, d, "curDay === d")
  //         if (curDay === d) {

  //           const newarray = Object.keys(element);

  //           setStartWeek(newarray[0])
  //           return returnDate = newarray[0]
  //         }
  //       })

  //     })

  //   });
  // }, []);

  // const ftpsCal = (index) => {

  //   //PASSING
  //   const passingyards = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Passing" ? d?.yards : null
  //     )
  //   })

  //   const passingTD = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Passing" ? d?.passing_touchdowns : null
  //     )
  //   })

  //   const passingINT = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Passing" ? d?.interceptions : null
  //     )
  //   })

  //   //RUSHING

  //   const rushingYards = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Rushing" ? d?.yards : null
  //     )
  //   })

  //   const rushingTD = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Rushing" ? d?.rushing_touchdowns : null
  //     )
  //   })

  //   //RECEIVING

  //   const receivingYards = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Receiving" ? d?.receiving_yards : null
  //     )
  //   })

  //   const receivingTD = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Receiving" ? d?.receiving_touchdowns : null
  //     )
  //   })

  //   //Scoring

  //   const scoringTwoPoints = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Scoring" ? d?.two_point_conversions : null
  //     )
  //   })

  //   //Defense

  //   const fumblesRecovered = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Defense" ? d?.interceptions_returned_for_touchdowns : null
  //     )
  //   })

  //   //RUSHING AND RECEIVING

  //   const fumblesLostRushing = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {

  //     return (
  //       d.stat_category === "Rushing" ? d?.fumbles_lost : null
  //     )
  //   })

  //   const fumblesLostReceiving = setAllNflList?.response_data?.NFL[index]?.doc?.map((d) => {
  //     return (
  //       d.stat_category === "Receiving" ? d?.fumbles_lost : null
  //     )
  //   })

  //   //RUSHING AND RECEIVING
  //   var filteredfumblesLostRushing = fumblesLostRushing.filter(function (item) {
  //     return item !== null;
  //   });
  //   console.log(filteredfumblesLostRushing, "frdeswaq")

  //   var filteredfumblesLostReceiving = fumblesLostReceiving.filter(function (item) {
  //     return item !== null;
  //   });

  //   //Passing
  //   var filteredPassingYards = passingyards.filter(function (item) {
  //     return item !== null;
  //   });
  //   var finalPassingYards = String(filteredPassingYards).replace(',', '')

  //   var filteredPassingTD = passingTD.filter(function (item) {
  //     return item !== null;
  //   });
  //   var filteredPassingINT = passingINT.filter(function (item) {
  //     return item !== null;
  //   });

  //   //rushing
  //   var filteredRushingYards = rushingYards.filter(function (item) {
  //     return item !== null;
  //   });

  //   var filteredRushingTD = rushingTD.filter(function (item) {
  //     return item !== null;
  //   });

  //   //receiving
  //   var filteredReceivingYards = receivingYards.filter(function (item) {
  //     return item !== null;
  //   });

  //   var filteredReceivingTD = receivingTD.filter(function (item) {
  //     return item !== null;
  //   });

  //   //Scoring
  //   var filteredScoringTwoPoints = scoringTwoPoints.filter(function (item) {
  //     return item !== null;
  //   });
  //   //defense
  //   var filteredDefenceFumblesRecovered = fumblesRecovered.filter(function (item) {
  //     return item !== null;
  //   });



  //   //passing
  //   const totalPassingYards = Number(finalPassingYards) / 25;
  //   const totalPassingTD = Number(filteredPassingTD) * 4;
  //   const totalPassingINT = (Number(filteredPassingINT) * (-2));
  //   //rushing
  //   const totalRushingYards = Number(filteredRushingYards) / 10;
  //   const totalRushingTD = Number(filteredRushingTD) * 6;
  //   //RECEIVING
  //   const totalReceivingYards = Number(filteredReceivingYards) / 10;
  //   const totalReceivingTD = Number(filteredReceivingTD) * 6;
  //   //Scoring
  //   const totalScoringTwoPoints = Number(filteredScoringTwoPoints) * 2;
  //   //Defense
  //   const totalDefenceFumblesRecovered = Number(filteredDefenceFumblesRecovered) * 6;
  //   //RUSHING AND RECEIVING
  //   const totalFumblesLostRushing = (Number(filteredfumblesLostRushing.length != 0 ? filteredfumblesLostRushing[0] : 0) * (-2));
  //   const totalFumblesLostReceiving = (Number(filteredfumblesLostReceiving.length != 0 ? filteredfumblesLostReceiving[0] : 0) * (-2));

  //   const finalFumlesLost = (totalFumblesLostRushing + totalFumblesLostReceiving)

  //   console.log(totalPassingYards, totalPassingTD, totalPassingINT, totalRushingYards, totalRushingTD, totalReceivingYards, totalReceivingTD, totalScoringTwoPoints, totalDefenceFumblesRecovered, totalFumblesLostRushing, totalFumblesLostReceiving, "finalFumlesLost")

  //   const totalftps = (totalPassingYards + totalPassingTD + totalPassingINT + totalRushingYards + totalRushingTD + totalReceivingYards + totalReceivingTD + totalScoringTwoPoints + totalDefenceFumblesRecovered + totalFumblesLostRushing + totalFumblesLostReceiving);
  //   console.log(totalftps, "totalftps")
  //   return (totalftps)

  // }

  const filterdata = (data, flag, cond) => {
    // Find the object with stat_category equal to "flag"
    const receivingObject = data.find(item => item.stat_category === flag);

    // Now you can access the properties of the "flag" object
    if (receivingObject) {
      let result = receivingObject[cond] ? receivingObject[cond] : 0;
      // console.log(receivingObject,'receivingObjectreceivingObjectreceivingObject', result);
      return result;
    } else {
      console.log("Object not found");
      return 0
    }
  }

  console.log("6587,4".replace(',', ''), "ftpsCal",)
  return (
    <>
      <div className="mt-5 pt-5 min-w-280">
        <div className="d-flex align-items-center bg-light-blue2 text-white border-right-custom skew-img1 position-relative   fantasy-rk-name-custom-height">
          <h4 className="font-14 fw-semibold text-uppercase ps-sm-4 pe-2 ps-2 mb-0 py-4 border-right-custom fantasy-rk-name-custom-height d-flex align-items-center justify-content-center rk-text">
            <span className="d-inline-block pt-1 pe-1">rk</span>
          </h4>
          <h4 className="font-14 fw-semibold text-uppercase ps-2 mb-0 py-4 ps-3 fantasy-rk-name-custom-height d-flex align-items-center justify-content-center">
            <span className="d-inline-block pt-1 ps-sm-4 ps-2">Name</span>
          </h4>
        </div>
        {allNflList?.response_data?.NCAA.length != 0 && allNflList?.response_data?.NCAA.map((item, index) => {
         

          return (
            <div
              className={`d-flex align-items-center justify-content-around ${index <= 4 ? "bg-gray-123" : "bg-white"
                }`}
              key={index}
            >
              <div className="w-xs-25 border-custom-after d-flex align-items-center justify-content-center">

                <p className="font-14 mb-0 text-center ">{index + 1}</p>

              </div>
              <div className="d-flex border-left-custom align-items-center border-bottom w-100 border-right-custom     custom-max-height-fantasy-table border-custom-after d-flex align-items-center">
                <div className="py-2 ps-2 ps-sm-3 pe-2">
                  <img
                    className="fantasy-table-img my-1"
                    src={item?.player_details?.profile_img}
                    alt="image"
                  />
                </div>
                <div className="d-flex font-14 flex-column px-2">
                  <span className="d-inline-block text-blue pb-sm-2 ">
                    <p className="font-14 mb-0">{item?.player_details?.name} </p>
                  </span>

                  <span className="d-inline-block text-nowrap">
                    <p className="font-14 mb-0 text-uppercase text-dark-gray">

                      <span className="span fw-bold"> </span>

                    </p>
                  </span>
                </div>
              </div>
            </div>
          )
        })}

      </div>

      <div className="table-responsive pt-5 pe-5">
        <table className="table mt-5 text-center">
          <thead className="text-white gradient-bg custom-transform-skew position-relative skew-img     fantasy-rk-name-custom-height">
            <tr className="px-4">
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                team
              </th>
              <th
                className="text-uppercase border-right-custom  border-left-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                POS
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                OPP
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4 "
                scope="col"
              >
                FPTS/G
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                FPTS
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4 position-relative text-center"
                scope="col"
              >
                <div className="table-text-2 d-flex align-items-end">
                  <span className="font-20 fw-semibold">PASSING</span>
                </div>
                yds
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                td
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                int
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4 position-relative   fantasy-yds-custom-width"
                scope="col-2"
              >
                <div className="table-text-3 d-flex align-items-end">
                  <span className="font-20 fw-semibold">Rushing</span>
                </div>
                yds
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                td
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4 position-relative"
                scope="col"
              >
                <div className="table-text-4 d-flex align-items-end">
                  <span className="font-20 fw-semibold">RECEIVING</span>
                </div>
                rec
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                yds
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                td
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4 position-relative"
                scope="col"
              >
                <div className="table-text-5 d-flex align-items-end">
                  <span className="font-20 fw-semibold">Defense</span>
                </div>
                TT
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                AST
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                ftd
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                sck
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                int
              </th>
              <th
                className="text-uppercase border-right-custom border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                ff
              </th>
              <th
                className="text-uppercase border-bottom-0 font-14 fw-semibold py-4"
                scope="col"
              >
                fr
              </th>
            </tr>
          </thead>

          <tbody className="bg-white border-top-0">
            {allNflList?.response_data?.NCAA.length != 0 && allNflList?.response_data?.NCAA.map((item, index) => {
              // const hasRushingOrReceiving = item?.doc.some((d) => d?.stat_category === "Rushing" || d?.stat_category === "Receiving");

              // const result = item?.doc.map((d) => {
              //   return hasRushingOrReceiving && (d.stat_category === "Passing" ? d?.yards : 0);
              // });
              return (

                <tr className={`${index <= 4 ? "bg-gray-123" : ""}`} key={item._id}>
                  <td className="text-start border-top-0 text-blue text-center font-14 fw-normal px-4">
                    {item?.team_details?.team_name}
                  </td>
                  <td className="text-start border-top-0 text-center font-14 fw-normal px-4">
                    {item?.player_details?.position}

                  </td>

                  <td className="text-start border-top-0 text-center font-14 fw-normal px-4">
                    {item?.team_details?.team_id === item?.opponent[0]?.awayTeam?.team_id ? item?.opponent[0]?.homeTeam?.team_name : item?.opponent[0]?.awayTeam?.team_name}
                  </td>
                  <td className="text-start bg-blue border-0 text-white text-center font-14 fw-normal px-4">
                    {/* {(ftpsCal(index).toFixed(2) / Number(String(startWeek).replace('Week ', ''))).toFixed(2)} */}
                    {item?.ftp_per_game?.toFixed(2)}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {ftpsCal(index).toFixed(2)} */}
                    {Number(item?.ftp).toFixed(2)}


                  </td>

                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {item?.Passing[0]?.yards ? item?.Passing[0]?.yards : "0"}
                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Passing', 'passing_touchdowns')}`} */}
                    {item?.Passing[0]?.passing_touchdowns ? item?.Passing[0]?.passing_touchdowns : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Passing', 'interceptions')}`}*/}
                    {item?.Passing[0]?.interceptions ? item?.Passing[0]?.interceptions : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Rushing', 'yards')}`} */}
                    {item?.Rushing[0]?.yards ? item?.Rushing[0]?.yards : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Rushing', 'rushing_touchdowns')}`} */}
                    {item?.Rushing[0]?.rushing_touchdowns ? item?.Rushing[0]?.rushing_touchdowns : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Receiving', 'receptions')}`} */}
                    {item?.Receiving[0]?.receptions ? item?.Receiving[0]?.receptions : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Receiving', 'receiving_yards')}`} */}
                    {item?.Receiving[0]?.receiving_yards ? item?.Receiving[0]?.receiving_yards : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Receiving', 'receiving_touchdowns')}`} */}
                    {item?.Receiving[0]?.receiving_touchdowns ? item?.Receiving[0]?.receiving_touchdowns : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'total_tackles')}`} */}
                    {item?.Defense[0]?.total_tackles ? item?.Defense[0]?.total_tackles : "0"}
                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'assisted_tackles')}`} */}
                    {item?.Defense[0]?.assisted_tackles ? item?.Defense[0]?.assisted_tackles : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'sacks')}`} */}
                    {item?.Defense[0]?.sacks ? item?.Defense[0]?.sacks : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'fumbles_returned_for_touchdowns')}`} */}
                    {item?.Defense[0]?.fumbles_returned_for_touchdowns ? item?.Defense[0]?.fumbles_returned_for_touchdowns : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'interceptions')}`} */}
                    {item?.Defense[0]?.interceptions ? item?.Defense[0]?.interceptions : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'forced_fumbles')}`} */}
                    {item?.Defense[0]?.forced_fumbles ? item?.Defense[0]?.forced_fumbles : "0"}

                  </td>
                  <td className="text-start border-top-0  text-center font-14 fw-normal px-4">
                    {/* {`${filterdata(item?.doc, 'Defense', 'fumbles_recovered')}`} */}
                    {item?.Defense[0]?.fumbles_recovered ? item?.Defense[0]?.fumbles_recovered : "0"}

                  </td>
                </tr>
              )
            })}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default NcaafFrontFantasyTableItem;
