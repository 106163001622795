import imagePath3 from "../../Assets/fantasy-table-img-3.png";

export const ncaafWeeklyLeaderData = [
  {
    date: "Total QBR - All Conferences",
    ncaafLeaderTableData: [
      {
        SrNo: "1",
        Name: "Kyle Trask",
        NameGrayText: "FLA",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "44-28",
        resultGrayText: "at",
        resultGrayTextUppercase: "UGA",
        Qbr: "86.9",
        Paa: "44.2",
        Plays: "320",
        Epa: "60.0",
        Pass: "50.2",
        Run: "4.9",
        Sack: "-2.9",
        Pen: "2.0",
        Raw: "87.6",
      },
      {
        SrNo: "2",
        Name: "Taulia Tagovailoa",
        NameGrayText: "MD",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "35-19",
        resultGrayText: "at",
        resultGrayTextUppercase: "PSU",
        Qbr: "85.4",
        Paa: "46.2",
        Plays: "347",
        Epa: "68.7",
        Pass: "53.2",
        Run: "10.3",
        Sack: "-4.6",
        Pen: "0.7",
        Raw: "86.8",
      },
      {
        SrNo: "3",
        Name: "Sam Howell",
        NameGrayText: "UNC",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "56-24",
        resultGrayText: "at",
        resultGrayTextUppercase: "DUKE",
        Qbr: "82.5",
        Paa: "37.4",
        Plays: "328",
        Epa: "62.4",
        Pass: "42.5",
        Run: "8.1",
        Sack: "-9.4",
        Pen: "2.4",
        Raw: "83.4",
      },
      {
        SrNo: "4",
        Name: "Tyler Shough",
        NameGrayText: "ORE",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "35-14",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "STAN",
        Qbr: "80.8",
        Paa: "39.4",
        Plays: "362",
        Epa: "64.1",
        Pass: "47.2",
        Run: "9.6",
        Sack: "-5.9",
        Pen: "1.5",
        Raw: "82.4",
      },
      {
        SrNo: "5",
        Name: "Justin Fields",
        NameGrayText: "OSU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "49-27",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "RUTG",
        Qbr: "80.5",
        Paa: "26.5",
        Plays: "242",
        Epa: "39.4",
        Pass: "27.6",
        Run: "6.2",
        Sack: "-3.4",
        Pen: "2.3",
        Raw: "82.5",
      },
      {
        SrNo: "6",
        Name: "Desmond Ridder",
        NameGrayText: "CIN",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "38-10",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "HOU",
        Qbr: "79.5",
        Paa: "26.5",
        Plays: "280",
        Epa: "47.9",
        Pass: "39.8",
        Run: "1.2",
        Sack: "-4.3",
        Pen: "2.6",
        Raw: "79.2",
      },
      {
        SrNo: "7",
        Name: "D'Eriq King",
        NameGrayText: "MIAMI",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "44-41",
        resultGrayText: "at",
        resultGrayTextUppercase: "NCST",
        Qbr: "79.0",
        Paa: "28.6",
        Plays: "284",
        Epa: "51.2",
        Pass: "36.5",
        Run: "8.0",
        Sack: "-4.3",
        Pen: "2.5",
        Raw: "80.6",
      },
      {
        SrNo: "8",
        Name: "Zach Wilson",
        NameGrayText: "BYU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "51-17",
        resultGrayText: "at",
        resultGrayTextUppercase: "BSU",
        Qbr: "77.7",
        Paa: "24.5",
        Plays: "284",
        Epa: "46.6",
        Pass: "34.4",
        Run: "4.1",
        Sack: "-5.3",
        Pen: "4.5",
        Raw: "77.3s",
      },
      {
        SrNo: "9",
        Name: "D.J. Uiagalelei",
        NameGrayText: "CLEM",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "L",
        resultBlueText: "47-40",
        resultGrayText: "at",
        resultGrayTextUppercase: "ND",
        Qbr: "76.4",
        Paa: "25.3",
        Plays: "269",
        Epa: "46.7",
        Pass: "36.0",
        Run: "4.9",
        Sack: "-5.5",
        Pen: "1.1",
        Raw: "79.1",
      },
      {
        SrNo: "10",
        Name: "Malik Willis",
        NameGrayText: "LIB",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "38-35",
        resultGrayText: "at",
        resultGrayTextUppercase: "VT",
        Qbr: "76.3",
        Paa: "20.8",
        Plays: "263",
        Epa: "36.5",
        Pass: "27.0",
        Run: "5.3",
        Sack: "-4.2",
        Pen: "0.4",
        Raw: "75.4",
      },
      {
        SrNo: "11",
        Name: "Kaleb Elebyx",
        NameGrayText: "WMU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "58-13",
        resultGrayText: "at",
        resultGrayTextUppercase: "AKR",
        Qbr: "75.9",
        Paa: "29.9",
        Plays: "370",
        Epa: "51.8",
        Pass: "36.7",
        Run: "4.1",
        Sack: "-7.7",
        Pen: "2.1",
        Raw: "75.8",
      },
      {
        SrNo: "12",
        Name: "Jack West",
        NameGrayText: "STAN",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "L",
        resultBlueText: "35-14",
        resultGrayText: "at",
        resultGrayTextUppercase: "ORE",
        Qbr: "73.2",
        Paa: "19.4",
        Plays: "271",
        Epa: "36.5",
        Pass: "27.5",
        Run: "1.3",
        Sack: "-4.0",
        Pen: "0.9",
        Raw: "71.2",
      },
      {
        SrNo: "13",
        Name: "Jayden Daniels",
        NameGrayText: "ASU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "L",
        resultBlueText: "28-27",
        resultGrayText: "at",
        resultGrayTextUppercase: "USC",
        Qbr: "72.4",
        Paa: "10.8",
        Plays: "305",
        Epa: "38.7",
        Pass: "29.2",
        Run: "-2.0",
        Sack: "-7.7",
        Pen: "0.5",
        Raw: "71.1",
      },
      {
        SrNo: "14",
        Name: "Shane Buechele",
        NameGrayText: "SMU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "47-23",
        resultGrayText: "at",
        resultGrayTextUppercase: "TEM",
        Qbr: "71.8",
        Paa: "24.3",
        Plays: "349",
        Epa: "49.1",
        Pass: "42.6",
        Run: "18.9",
        Sack: "-4.3",
        Pen: "4.3",
        Raw: "72.8",
      },
      {
        SrNo: "15",
        Name: "Patrick O'Brien",
        NameGrayText: "CSU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "34-24",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "WYO",
        Qbr: "71.1",
        Paa: "24.0",
        Plays: "340",
        Epa: "46.9",
        Pass: "23.9",
        Run: "6.2",
        Sack: "-2.9",
        Pen: "1.2",
        Raw: "73.1",
      },
      {
        SrNo: "16",
        Name: "Hendon Hooker",
        NameGrayText: "VT",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "L",
        resultBlueText: "38-35",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "LIB",
        Qbr: "68.1",
        Paa: "19.0",
        Plays: "329",
        Epa: "43.9",
        Pass: "26.7",
        Run: "4.9",
        Sack: "-7.1",
        Pen: "3.9",
        Raw: "69.4",
      },
      {
        SrNo: "17",
        Name: "Kyle Vantrease",
        NameGrayText: "BUFF",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "49-30",
        resultGrayText: "at",
        resultGrayTextUppercase: "NIU",
        Qbr: "65.9",
        Paa: "13.1",
        Plays: "295",
        Epa: "33.6",
        Pass: "17.3",
        Run: "0.1",
        Sack: "-8.2",
        Pen: "3.3",
        Raw: "65.3",
      },
      {
        SrNo: "18",
        Name: "Kedon Slovis",
        NameGrayText: "USC",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "27-28",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "ASU",
        Qbr: "65.5",
        Paa: "13.4",
        Plays: "256",
        Epa: "33.8",
        Pass: "27.7",
        Run: "7.2",
        Sack: "-2.7",
        Pen: "3.6",
        Raw: "67.7",
      },
      {
        SrNo: "19",
        Name: "Kenny Pickett",
        NameGrayText: "PITT",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "56-24",
        resultGrayText: "at",
        resultGrayTextUppercase: "DUKE",
        Qbr: "63.9",
        Paa: "9.4",
        Plays: "282",
        Epa: "28.7",
        Pass: "15.4",
        Run: "11.3",
        Sack: "-6.0",
        Pen: "0.1",
        Raw: "61.6",
      },
      {
        SrNo: "20",
        Name: "Kellen Mond",
        NameGrayText: "TA&M",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "41-17",
        resultGrayText: "at",
        resultGrayTextUppercase: "FSU",
        Qbr: "61.6",
        Paa: "6.0",
        Plays: "346",
        Epa: "34.1",
        Pass: "9.9",
        Run: "-4.3",
        Sack: "-9.7",
        Pen: "3.3",
        Raw: "56.1",
      },
      {
        SrNo: "21",
        Name: "Michael Penix",
        NameGrayText: "Jr.IU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "49-27",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "RUTG",
        Qbr: "61.0",
        Paa: "11.0",
        Plays: "283",
        Epa: "35.0",
        Pass: "29.5",
        Run: "4.5",
        Sack: "-5.1",
        Pen: "4.7",
        Raw: "63.4",
      },
      {
        SrNo: "22",
        Name: "Ian Book",
        NameGrayText: "ND",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "48-3",
        resultGrayText: "at",
        resultGrayTextUppercase: "SC",
        Qbr: "59.9",
        Paa: "12.6",
        Plays: "426",
        Epa: "46.7",
        Pass: "28.8",
        Run: "3.4",
        Sack: "-12.1",
        Pen: "1.2",
        Raw: "60.3",
      },
      {
        SrNo: "23",
        Name: "Tanner Morgan",
        NameGrayText: "MINN",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "38-21",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "MICH",
        Qbr: "59.3",
        Paa: "7.9",
        Plays: "328",
        Epa: "32.0",
        Pass: "20.0",
        Run: "3.7",
        Sack: "-6.0",
        Pen: "2.6",
        Raw: "58.5",
      },
      {
        SrNo: "24",
        Name: "Brock Purdy",
        NameGrayText: "ISU",
        imgUrl: imagePath3,
        Week: "10",
        resultGreenText: "W",
        resultBlueText: "47-40",
        resultGrayText: "vs.",
        resultGrayTextUppercase: "CLEM",
        Qbr: "54.4",
        Paa: "7.2",
        Plays: "331",
        Epa: "30.1",
        Pass: "14.2",
        Run: "2.3",
        Sack: "-10.2",
        Pen: "2.0",
        Raw: "57.6",
      },
    ],
  },
];
