import rosterImg1 from "../../Assets/roster-1.png";
import webApi from "../../WebApi/WebApi";

export const ncaafRosterTable = [
  {
    rosterHeading: "Offense",
    rosterTableContent: [
      {
        firstName: "Josh Allen",
        imgUrl: rosterImg1,
        firstNameNum: " 17",
        pos: "QB",
        age: "24",
        ht: "1.96 m",
        wt: "107 kg",
        exp: "3",
        college: "Wyoming",
      },
      {
        firstName: "Matt Barkley",
        imgUrl: rosterImg1,
        firstNameNum: " 5",
        pos: "QB",
        age: "30",
        ht: "1.88 m",
        wt: "106 kg",
        exp: "8",
        college: "USC",
      },
      {
        firstName: "Jake Fromm",
        imgUrl: rosterImg1,
        firstNameNum: " 10",
        pos: "QB",
        age: "22",
        ht: "1.88 m",
        wt: "97 kg",
        exp: "R",
        college: "Georgia",
      },
      {
        firstName: "Taiwan Jones",
        imgUrl: rosterImg1,
        firstNameNum: " 25",
        pos: "RB",
        age: "32",
        ht: "1.83 m",
        wt: "88 kg",
        exp: "10",
        college: "Eastern Washington",
      },
      {
        firstName: "Zack Moss",
        imgUrl: rosterImg1,
        firstNameNum: " 20",
        pos: "RB",
        age: "22",
        ht: "1.75 m",
        wt: "92 kg",
        exp: "R",
        college: "Utah",
      },
      {
        firstName: "Devin Singletary",
        imgUrl: rosterImg1,
        firstNameNum: " 26",
        pos: "RB",
        age: "23",
        ht: "1.7 m",
        wt: "92 kg",
        exp: "2",
        college: "Florida Atlantic",
      },
      {
        firstName: "T.J. Yeldon",
        imgUrl: rosterImg1,
        firstNameNum: " 22",
        pos: "RB",
        age: "27",
        ht: "1.85 m",
        wt: "101 kg",
        exp: "6",
        college: "Alabama",
      },
      {
        firstName: "Cole Beasley",
        imgUrl: rosterImg1,
        firstNameNum: " 11",
        pos: "WR",
        age: "31",
        ht: "1.73 m",
        wt: "78 kg",
        exp: "9",
        college: "SMU",
      },
      {
        firstName: "John Brown",
        imgUrl: rosterImg1,
        firstNameNum: " 15",
        pos: "WR",
        age: "30",
        ht: "1.8 m",
        wt: "80 kg",
        exp: "7",
        college: "Pittsburg State",
      },
      {
        firstName: "Gabriel Davis",
        imgUrl: rosterImg1,
        firstNameNum: " 13",
        pos: "WR",
        age: "21",
        ht: "1.88 m",
        wt: "95 kg",
        exp: "R",
        college: "UCF",
      },
      {
        firstName: "Stefon Diggs",
        imgUrl: rosterImg1,
        firstNameNum: " 14",
        pos: "WR",
        age: "26",
        ht: "1.83 m",
        wt: "86 kg",
        exp: "6",
        college: "Maryland",
      },
      {
        firstName: "Isaiah McKenzie",
        imgUrl: rosterImg1,
        firstNameNum: " 19",
        pos: "WR",
        age: "25",
        ht: "1.73 m",
        wt: "78 kg",
        exp: "4",
        college: "Georgia",
      },
      {
        firstName: "Andre Roberts",
        imgUrl: rosterImg1,
        firstNameNum: " 18",
        pos: "WR",
        age: "32",
        ht: "1.8 m",
        wt: "88 kg",
        exp: "11",
        college: "Citadel",
      },
      {
        firstName: "Reggie Gilliam",
        imgUrl: rosterImg1,
        firstNameNum: " 86",
        pos: "TE",
        age: "23",
        ht: "1.83 m",
        wt: "110 kg",
        exp: "R",
        college: "Toledo",
      },
      {
        firstName: "Tyler Kroft",
        imgUrl: rosterImg1,
        firstNameNum: " 81",
        pos: "TE",
        age: "28",
        ht: "1.98 m",
        wt: "114 kg",
        exp: "6",
        college: "Rutgers",
      },
      {
        firstName: "Lee Smith",
        imgUrl: rosterImg1,
        firstNameNum: " 85",
        pos: "TE",
        age: "32",
        ht: "1.98 m",
        wt: "120 kg",
        exp: "10",
        college: "Marshall",
      },
      {
        firstName: "Mitch Morse",
        imgUrl: rosterImg1,
        firstNameNum: " 60",
        pos: "C",
        age: "28",
        ht: "1.98 m",
        wt: "138 kg",
        exp: "6",
        college: "Missouri",
      },
      {
        firstName: "Ike Boettger",
        imgUrl: rosterImg1,
        firstNameNum: " 65",
        pos: "G",
        age: "26",
        ht: "1.98 m",
        wt: "141 kg",
        exp: "3",
        college: "Iowa",
      },
      {
        firstName: "Jon Feliciano",
        imgUrl: rosterImg1,
        firstNameNum: " 76",
        pos: "G",
        age: "28",
        ht: "1.93 m",
        wt: "147 kg",
        exp: "6",
        college: "Miami (FL)",
      },
      {
        firstName: "Brian Winters",
        imgUrl: rosterImg1,
        firstNameNum: " 66",
        pos: "G",
        age: "29",
        ht: "1.93 m",
        wt: "145 kg",
        exp: "8",
        college: "Kent State",
      },
      {
        firstName: "Ryan Bates",
        imgUrl: rosterImg1,
        firstNameNum: " 71",
        pos: "OT",
        age: "23",
        ht: "1.93 m",
        wt: "136 kg",
        exp: "2",
        college: "Penn State",
      },
      {
        firstName: "Dion Dawkins",
        imgUrl: rosterImg1,
        firstNameNum: " 73",
        pos: "OT",
        age: "26",
        ht: "1.96 m",
        wt: "145 kg",
        exp: "4",
        college: "Temple",
      },
      {
        firstName: "Cody Ford",
        imgUrl: rosterImg1,
        firstNameNum: " 70",
        pos: "OT",
        age: "23",
        ht: "1.91 m",
        wt: "149 kg",
        exp: "2",
        college: "Oklahoma",
      },
      {
        firstName: "Ty Nsekhe",
        imgUrl: rosterImg1,
        firstNameNum: " 77",
        pos: "OT",
        age: "26",
        ht: "2.03 m",
        wt: "149 kg",
        exp: "6",
        college: "Texas State",
      },
      {
        firstName: "Daryl Williams",
        imgUrl: rosterImg1,
        firstNameNum: " 75",
        pos: "OT",
        age: "28",
        ht: "1.98 m",
        wt: "149 kg",
        exp: "6",
        college: "Oklahoma",
      },
    ],
  },
  {
    rosterHeading: "Defense",
    rosterTableContent: [
      {
        firstName: "Mario Addison",
        imgUrl: rosterImg1,
        firstNameNum: " 97",
        pos: "DE",
        age: "33",
        ht: "1.91 m",
        wt: "117 kg",
        exp: "10",
        college: "Troy",
      },
      {
        firstName: "AJ Epenesa",
        imgUrl: rosterImg1,
        firstNameNum: " 57",
        pos: "DE",
        age: "22",
        ht: "1.98 m",
        wt: "127 kg",
        exp: "R",
        college: "lowa",
      },
      {
        firstName: "Jerry Hughes",
        imgUrl: rosterImg1,
        firstNameNum: " 55",
        pos: "DE",
        age: "32",
        ht: "1.88 m",
        wt: "115 kg",
        exp: "11",
        college: "TCU",
      },
      {
        firstName: "Darryl Johnson",
        imgUrl: rosterImg1,
        firstNameNum: " 92",
        pos: "DE",
        age: "23",
        ht: "1.98 m",
        wt: "114 kg",
        exp: "2",
        college: "North Carolina A&T",
      },
      {
        firstName: "Trent Murphy",
        imgUrl: rosterImg1,
        firstNameNum: " 93",
        pos: "DE",
        age: "29",
        ht: "1.98 m",
        wt: "117 kg",
        exp: "7",
        college: "Stanford",
      },
      {
        firstName: "Vernon Butler",
        imgUrl: rosterImg1,
        firstNameNum: " 94",
        pos: "DT",
        age: "26",
        ht: "1.93 m",
        wt: "149 kg",
        exp: "5",
        college: "Louisiana Tech",
      },
      {
        firstName: "Quinton Jefferson",
        imgUrl: rosterImg1,
        firstNameNum: " 90",
        pos: "DT",
        age: "27",
        ht: "1.93 m",
        wt: "131 kg",
        exp: "5",
        college: "Maryland",
      },
      {
        firstName: "Ed Oliver",
        imgUrl: rosterImg1,
        firstNameNum: " 91",
        pos: "DT",
        age: "22",
        ht: "1.85 m",
        wt: "130 kg",
        exp: "2",
        college: "Houston",
      },
      {
        firstName: "Harrison Phillips",
        imgUrl: rosterImg1,
        firstNameNum: " 99",
        pos: "DT",
        age: "24",
        ht: "1.91 m",
        wt: "139 kg",
        exp: "3",
        college: "Stanford",
      },
      {
        firstName: "Justin Zimmer",
        imgUrl: rosterImg1,
        firstNameNum: " 61",
        pos: "DT",
        age: "28",
        ht: "1.91 m",
        wt: "136 kg",
        exp: "2",
        college: "Ferris State",
      },
      {
        firstName: "Tremaine Edmunds",
        imgUrl: rosterImg1,
        firstNameNum: " 49",
        pos: "LB",
        age: "22",
        ht: "1.96 m",
        wt: "113 kg",
        exp: "3",
        college: "Virginia Tech",
      },
      {
        firstName: "A.J. Klein",
        imgUrl: rosterImg1,
        firstNameNum: " 54",
        pos: "LB",
        age: "29",
        ht: "1.85 m",
        wt: "108 kg",
        exp: "8",
        college: "Iowa State",
      },
      {
        firstName: "Tyler Matakevich",
        imgUrl: rosterImg1,
        firstNameNum: " 44",
        pos: "LB",
        age: "27",
        ht: "1.85 m",
        wt: "106 kg",
        exp: "5",
        college: "Temple",
      },
      {
        firstName: "Matt Milano",
        imgUrl: rosterImg1,
        firstNameNum: " 58",
        pos: "LB",
        age: "26",
        ht: "1.83 m",
        wt: "101 kg",
        exp: "4",
        college: "Boston College",
      },
      {
        firstName: "Andre Smith",
        imgUrl: rosterImg1,
        firstNameNum: " 59",
        pos: "LB",
        age: "23",
        ht: "1.83 m",
        wt: "108 kg",
        exp: "3",
        college: "North Carolina",
      },
      {
        firstName: "Taron Johnson",
        imgUrl: rosterImg1,
        firstNameNum: " 24",
        pos: "CB",
        age: "24",
        ht: "1.8 m",
        wt: "87 kg",
        exp: "3",
        college: "Weber State",
      },
      {
        firstName: "Siran Neal",
        imgUrl: rosterImg1,
        firstNameNum: " 33",
        pos: "CB",
        age: "26",
        ht: "1.83 m",
        wt: "93 kg",
        exp: "3",
        college: "Jacksonville State",
      },
      {
        firstName: "Josh Norman",
        imgUrl: rosterImg1,
        firstNameNum: " 29",
        pos: "CB",
        age: "32",
        ht: "1.83 m",
        wt: "90 kg",
        exp: "9",
        college: "Coastal Carolina",
      },
      {
        firstName: "Levi Wallace",
        imgUrl: rosterImg1,
        firstNameNum: " 39",
        pos: "CB",
        age: "25",
        ht: "1.83 m",
        wt: "81 kg",
        exp: "3",
        college: "Alabama",
      },
      {
        firstName: "Tre'Davious White",
        imgUrl: rosterImg1,
        firstNameNum: " 27",
        pos: "CB",
        age: "25",
        ht: "1.8 m",
        wt: "87 kg",
        exp: "3",
        college: "LSU",
      },
      {
        firstName: "Micah Hyde",
        imgUrl: rosterImg1,
        firstNameNum: " 23",
        pos: "S",
        age: "29",
        ht: "1.83 m",
        wt: "89 kg",
        exp: "8",
        college: "Iowa",
      },
      {
        firstName: "Jaquan Johnson",
        imgUrl: rosterImg1,
        firstNameNum: " 46",
        pos: "S",
        age: "24",
        ht: "1.78 m",
        wt: "86 kg",
        exp: "2",
        college: "Miami (FL)",
      },
      {
        firstName: "Dean Marlowe",
        imgUrl: rosterImg1,
        firstNameNum: " 31",
        pos: "S",
        age: "28",
        ht: "1.85 m",
        wt: "94 kg",
        exp: "4",
        college: "James Madison",
      },
      {
        firstName: "Jordan Poyer",
        imgUrl: rosterImg1,
        firstNameNum: " 21",
        pos: "S",
        age: "29",
        ht: "1.83 m",
        wt: "86 kg",
        exp: "8",
        college: "Oregon State",
      },
    ],
  },
  {
    rosterHeading: "Special Teams",
    rosterTableContent: [
      {
        firstName: "Tyler Bass",
        imgUrl: rosterImg1,
        firstNameNum: " 2",
        pos: "PK",
        age: "23",
        ht: "1.78 m",
        wt: "83 kg",
        exp: "R",
        college: "Georgia Southern",
      },
      {
        firstName: "Josh Allen ",
        imgUrl: rosterImg1,
        firstNameNum: " 17",
        pos: "P",
        age: "24",
        ht: "1.83 m",
        wt: "94 kg",
        exp: "3",
        college: "New Mexico",
      },
      {
        firstName: "Josh Allen ",
        imgUrl: rosterImg1,
        firstNameNum: " 17",
        pos: "LS",
        age: "26",
        ht: "1.88 m",
        wt: "106 kg",
        exp: "4",
        college: "LSU",
      },
    ],
  },
];


export const getAllNcaafRosterlistById = async (teamId, onSuccess, onFailure) => {
  console.log(teamId, "ncaafteamId")



  try {
    const res = await webApi.get(

      `/getNCaaRosterByTeamId?teamId=${teamId}`,
      {}
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};