import React from "react";

const Ranking = () => {
  var currentYear = new Date().getFullYear();  // returns the current year
  return (
    <>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center pt-md-5">
              <h1 className="font-42 white fw-bold text-uppercase pt-md-5 pb-4">
                College Football Rankings - {currentYear}
              </h1>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-12 z-5 col-lg-10 col-xxl-8 d-flex justify-content-between">
              <div className="d-md-flex justify-content-center w-100 px-md-5">
                <select
                  className="form-select schedule_select font-16 mx-auto mx-md-0"
                  aria-label="Default select example"
                >
                  <option selected>ALL FBS </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <select
                  className="form-select my-3 my-md-0 schedule_select font-16 mx-auto mx-md-2"
                  aria-label="Default select example"
                >
                  <option selected>2020</option>
                  <option value="1">2001</option>
                  <option value="2">2002</option>
                  <option value="3">2003</option>
                </select>
                <select
                  className="form-select schedule_select font-16 mx-auto mx-md-0"
                  aria-label="Default select example"
                >
                  <option selected>WEEK 11 </option>
                  <option value="1">Week 12</option>
                  <option value="2">Week 13</option>
                  <option value="3">Week 14</option>
                </select>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Ranking;
