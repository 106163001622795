import React from "react";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import objectImg from "../../Assets/images/object.png";
import dnldImg from "../../Assets/images/dnld.png";
import heartImg from "../../Assets/images/heart.png";
import soundImg from "../../Assets/images/sound.png";
import clockImg from "../../Assets/images/clock.png";
import pauseImg from "../../Assets/images/pause.png";
import Videos from "./Videos"
import ArrowIcon from "../../../src/components/Navbar/assets/arrowicon.png";
import ReactAudioPlayer from 'react-audio-player';
import { getAllFavourite } from "../../service/cmsService";
import FavAudioPlayer from "./favAudioPlayer";
const FavAudioPodcast = () => {
    const audioRef = useRef(null);
    
    const [podcastList, set_podcastList] = useState({});
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    // const progressBar =(id)=>{

    //     var music=document.getElementsById(id)
    // }



    // const handlePlayPause = (e) => {
    //     console.log(isPlaying.id,"target")
    //     if (isPlaying) {
    //         console.log(audioRef.current,"current")
    //         audioRef.current.pause();
    //     } else {
    //         audioRef.current.play();
    //     }
    //     setIsPlaying(!isPlaying);
    // };

    // const handleSeek = (e) => {
    //     const seekTime = e.target.value;
    //     audioRef.current.currentTime = seekTime;
    //     console.log(seekTime, "seekTime")

    //     setCurrentTime(seekTime);
    // };

    console.log(currentTime, "currentTime")
    // const str_pad_left = (string, pad, length) => {
    //     return (new Array(length + 1).join(pad) + string).slice(-length);
    // }


    // const timeUpdate = (event) => {
    //     console.log(audioRef.current.currentTime, "321654")
    //     setCurrentTime(audioRef.current.currentTime);
    //     var audioFileId = event.target.id;

    //     var audioFileIdIndex = audioFileId.split("__")[1];

    //     const minutes = Math.floor(event.target.currentTime / 60);
    //     const seconds = Math.floor(event.target.currentTime - minutes * 60);
    //     const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

    //     document.getElementById("audioStartTime__" + audioFileIdIndex).innerHTML = currentTime;
    //     document.getElementById("range__" + audioFileIdIndex).value = currentTime;


    //     const m = Math.floor(event.target.duration / 60);
    //     const s = Math.floor(event.target.duration - m * 60);
    //     const duration = str_pad_left(m, '0', 2) + ':' + str_pad_left(s, '0', 2);

    //     document.getElementById("duration" + audioFileIdIndex).innerHTML = duration;

    //     const remain = currentTime.split(":")[1];
    //     const remain1 = duration.split(":")[1];

    //     const remain3 = currentTime.split(":")[0];
    //     const remain4 = duration.split(":")[0];

    //     const final = remain1 - remain;
    //     const final1 = remain4 - remain3;


    //     document.getElementById("remain" + audioFileIdIndex).innerHTML = "remaining time is " + "0" + final1 + ":" + final;

    // }


    // const togglePlaying = (index) => {
        
        
    //     const myAudio =  document.getElementById("audioFile__" + index);  
    //     // const myRange =  document.getElementById("range__" + index);        
    //     // const seekTime = myAudio.target.value;
    //     // console.log(seekTime, "myaudio")
    //     if (myAudio.paused) {
    //         myAudio.play();
    //     } else {
    //         myAudio.pause();
    //     }
    // }

    useEffect(() => {
        podcastListData();
    }, []);

    async function podcastListData() {
        getAllFavourite().then(function (result) {
            const response = result.data;
            const newList =response.response_data[0]?.podcast_id
            console.log(response,"response")
            
            console.log(newList,"newList")
            set_podcastList(newList);
        });
    }

    


    let podcastDataContent = <div style={{ color: "white", textAlign: "center" }}>Loading...</div>;
    if (podcastList && podcastList.length > 0) {
        console.log(podcastList,"ididid")
        podcastDataContent = podcastList.map((podcastRow, index) => (
            <div  key={index}>
                {/* {console.log(typeof (podcastRow.podcast_view_on), "310523")} */}
                
                <FavAudioPlayer favPodId={podcastRow._id} src={podcastRow.audio} img={podcastRow.image} title={podcastRow.title} subTitle={podcastRow.sub_title}/>

            </div>

        ));
    }else
    {
        podcastDataContent =<div className="container noData">
        <p style={{ color: "white", textAlign: "center" }}>Data not Found</p>
      </div>
    }

    return (
        <>
            <section className="innerMainbg">
                

                <div className="innerBodycontent">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="podcastContent">

                                    {podcastDataContent}


                                </div>
                            </div>
                        </div>

                        


                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default FavAudioPodcast;
