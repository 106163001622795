import React from "react";

const HeadingDotted = () => {
  return (
    <>
      <span className="first-box d-inline-block"></span>
      <span className="second-box d-inline-block mx-2"></span>
      <span className="third-box d-inline-block"></span>
    </>
  );
};

export default HeadingDotted;
