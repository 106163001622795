import {
  FaceBookIcon,
  InstagramIcon,
  SnapChatIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../icons/Icons";
import titlns18 from "../../Assets/img/horse.png";
import teambengalsimg from "../../Assets/img/text-img.png";
import teamnorthcarolina from "../../Assets/img/scoreboard-img-3.png";
import teamduke from "../../Assets/img/scoreboard-img-4.png";
import team25liberty from "../../Assets/img/scoreboard-img-5.png";
import teamvirginatech from "../../Assets/img/scoreboard-img-6.png";
import teamwestvirginia from "../../Assets/img/scoreboard-img-7.png";
import team22texas from "../../Assets/img/scoreboard-img-8.png";
import team23michigan from "../../Assets/img/scoreboard-img-9.png";
import team13indiana from "../../Assets/img/scoreboard-img-10.png";
import teammichst from "../../Assets/img/scoreboard-img-11.png";
import teamlowa from "../../Assets/img/scoreboard-img-12.png";
import teamarizonastate from "../../Assets/img/scoreboard-img-13.png";
import team20usc from "../../Assets/img/scoreboard-img-14.png";
import team9byu from "../../Assets/img/scoreboard-img-15.png";
import team21boisestate from "../../Assets/img/scoreboard-img-16.png";
import GlobalConfig from "../../GlobalConfig";
import webApi from "../../WebApi/WebApi";

export const quicklinksdata = [
  {
    buttonText: "2020-21 Bowl Projections",
  },
  {
    buttonText: "Allstate Playoff Predictor",
  },
  {
    buttonText: "College Pick'em",
  },
  {
    buttonText: "Position U 2.0",
  },
  {
    buttonText: "CFB Recruiting",
  },
];
export const topheadlinedata = [
  {
    buttonText: "QB Lawrence to stay at Clemson? 'Who knows?'",
  },
  {
    buttonText: "Washington's Onwuzurike declares for NFL draft",
  },
  {
    buttonText: "Ex-Wolverine Collins: Felt like 'no man's land'",
  },
  {
    buttonText: "Ohio State CB, son of protest leader, opting out",
  },
  {
    buttonText: "QB Lance skips spring season, enters NFL draft",
  },
];

export const followNflList = [
  {
    socialIconUrl: <FaceBookIcon />,
    socialLink: "Facebook",
    linkUrl: GlobalConfig.NFL_FB_LINK,
  },
  {
    socialIconUrl: <TwitterIcon />,
    socialLink: "Twitter",
    linkUrl: GlobalConfig.NFL_TWITTER_LINK,
  },
  {
    socialIconUrl: <InstagramIcon />,
    socialLink: "Instagram",
    linkUrl: GlobalConfig.NFL_INSTAGRAM_LINK,
  },
  {
    socialIconUrl: <YoutubeIcon />,
    socialLink: "Youtube",
    linkUrl: GlobalConfig.NFL_YOUTUBE_LINK,
  },
  {
    socialIconUrl: <SnapChatIcon />,
    socialLink: "Snapchat",
    linkUrl: GlobalConfig.NFL_SNAPCHAT_LINK,
  },
];

export const followNcaaList = [
  {
    socialIconUrl: <FaceBookIcon />,
    socialLink: "Facebook",
    linkUrl: GlobalConfig.NCAAF_FB_LINK,
  },
  {
    socialIconUrl: <TwitterIcon />,
    socialLink: "Twitter",
    linkUrl: GlobalConfig.NCAAF_TWITTER_LINK,
  },
  {
    socialIconUrl: <InstagramIcon />,
    socialLink: "Instagram",
    linkUrl: GlobalConfig.NCAAF_INSTAGRAM_LINK,
  },
  {
    socialIconUrl: <YoutubeIcon />,
    socialLink: "Youtube",
    linkUrl: GlobalConfig.NCAAF_YOUTUBE_LINK,
  },
  {
    socialIconUrl: <SnapChatIcon />,
    socialLink: "Snapchat",
    linkUrl: GlobalConfig.NCAAF_SNAPCHAT_LINK,
  },
];

export const footballscoreboardTeamArray = [
  {
    roww: 1,
    firstMatch: {
      firstTeam: {
        teamImg: titlns18,
        teamName: "Arizona Cardinals",
        score: "6-1",
      },
      secondTeam: {
        teamImg: teambengalsimg,
        teamName: "Bengals",
        score: "1-3",
      },
      matchTime: "10:30 AM",
    },
    roww: 1,
    secondMatch: {
      firstTeam: {
        teamImg: teamnorthcarolina,
        teamName: "North Carolina",
        score: "4-2",
      },
      secondTeam: {
        teamImg: teamduke,
        teamName: "Duke",
        score: "2-5",
      },
      matchTime: "10:30 AM",
    },
  },
  {
    roww: 2,
    firstMatch: {
      firstTeam: {
        teamImg: team25liberty,
        teamName: "25 Liberty",
        score: "6-0",
      },
      secondTeam: {
        teamImg: teamvirginatech,
        teamName: "Virginia Tech",
        score: "4-1",
      },
      matchTime: "1:30 PM",
    },
    roww: 2,
    secondMatch: {
      firstTeam: {
        teamImg: teamwestvirginia,
        teamName: "West Virginia",
        score: "4-2",
      },
      secondTeam: {
        teamImg: team22texas,
        teamName: "22 Texas",
        score: "4-2",
      },
      matchTime: "1:30 PM",
    },
  },
  {
    roww: 3,
    firstMatch: {
      firstTeam: {
        teamImg: team23michigan,
        teamName: "23 Michigan",
        score: "6-0",
      },
      secondTeam: {
        teamImg: team13indiana,
        teamName: "13 Indiana",
        score: "4-1",
      },
      matchTime: "4:30 PM",
    },
    roww: 3,
    secondMatch: {
      firstTeam: {
        teamImg: teammichst,
        teamName: "Mich. St.",
        score: "4-2",
      },
      secondTeam: {
        teamImg: teamlowa,
        teamName: "Iowa",
        score: "4-2",
      },
      matchTime: "4:30 PM",
    },
  },
  {
    roww: 4,
    firstMatch: {
      firstTeam: {
        teamImg: teamarizonastate,
        teamName: "Arizona State",
        score: "6-0",
      },
      secondTeam: {
        teamImg: team20usc,
        teamName: "20 USC",
        score: "4-1",
      },
      matchTime: "8:30 PM",
    },
    roww: 4,
    secondMatch: {
      firstTeam: {
        teamImg: team9byu,
        teamName: "9 BYU",
        score: "4-2",
      },
      secondTeam: {
        teamImg: team21boisestate,
        teamName: "21 Boise State",
        score: "4-2",
      },
      matchTime: "8:30 PM",
    },
  },
];

export const scoreListData = [
  {
    listItem: "Home",
  },
  { listItem: "Player Profile" },
  { listItem: "NFL Draft" },
  { listItem: "Scores" },
  { listItem: "Schedule" },
  { listItem: "Standings" },
  { listItem: "Stats" },
  { listItem: "Teams" },
  { listItem: "Injuries" },
];


export const getNflScoreList = async (onSuccess, onFailure) => {
  

  try {
    const res = await webApi.get(

      `/scoreBoard?page=NFL`,
      
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const getNcaaScoreList = async (onSuccess, onFailure) => {
  

  try {
    const res = await webApi.get(

      `/scoreBoard?page=NCAA`,
      
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};


export const getQuickLinkList = async (page,onSuccess, onFailure) => {
  

  try {
    const res = await webApi.get(

      `/quickLinkList?page=${page}`,
      
    );
    if (res.status === 200) {
      const r = res.data;    

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};
