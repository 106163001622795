import newsimg1 from "../Navbar/assets/news1.png";
import newsimg2 from "../Navbar/assets/news2.png";
import newsimg3 from "../Navbar/assets/news3.png";

// import CodeImg1 from "../Assets/cody.png";

export const playernewsArray = [
  {
    id: 1,
    heading: "Matchup Previews + Week 5",
    description: `Injury Analysis with Dr. David Chao`,
    match: `(2020 Fantasy Football)`,
    imagePath: newsimg1,
  },
  {
    id: 2,
    heading: "Matchup Previews + Week 5",
    description: `Injury Analysis with Dr. David Chao`,
    match: `(2020 Fantasy Football)`,
    imagePath: newsimg2,
  },
  {
    id: 3,
    heading: "Matchup Previews + Week 5",
    description: `Injury Analysis with Dr. David Chao`,
    match: `(2020 Fantasy Football)`,
    imagePath: newsimg3,
  },
];
