// import FantasyTableItem from "./FantasyTableItem";
import { fantasyFootballTableListMain } from "./Helper";
import NflFrontFantasyTableItem from "./NflFrontFantasyTableItem";

const FantasyStatsTable = ({allNflList}) => { 
  return (
    <>
      <div className="container py-5 z-1 position-relative fantasy-stats mb-5">
        {/* <div className="d-flex mb-negative-50 mb-xs-0">
          <button className="fantasy-btn"> COLLEGE</button>
          <button className="fantasy-btn ms-2"> NFL</button>
        </div> */}
        <div className="d-flex">
          {/* {fantasyFootballTableListMain.map((item, index) => (
            <FantasyTableItem key={index} item={item} />
          ))} */}
          {/* {allNflList && allNflList.response_data?.NFL.length!=0 && allNflList?.response_data?.NFL.map((item, index) => ( */}
           {allNflList && allNflList.response_data?.NFL.length!=0 &&                      
            <NflFrontFantasyTableItem allNflList={allNflList} />}
           {/* ))} */}
        </div>
      </div>
      {/* <div className="container text-center mb-5 pb-5 mb-lg-0 pb-lg-0">
        <span className="font-20 fw-semibold text-white d-inline-block">
          10 / 25 / 50 / 100 / ALL
        </span>
        <button className="gradient-bg text-white d-block font-17 fw-semibold px-5 rounded-pill py-3 mx-auto mt-4">
          <span className="d-inline-block px-5 mx-sm-5 py-1 ">LOAD MORE</span>
        </button>
      </div> */}
      <div className="vh-25 d-none d-lg-block"></div>
    </>
  );
};

export default FantasyStatsTable;
