import standingImg1 from "../../Assets/schedule-table-img-1.png";
import standingImg2 from "../../Assets/schedule-table-img-2.png";
import standingImg3 from "../../Assets/schedule-table-img-3.png";
import standingImg4 from "../../Assets/schedule-table-img-4.png";
import webApi from "../../WebApi/WebApi";

export const americanStandingList = [
  {
    standingTableHeading: "American Football Conference",
    standingMatches: [
      {
        tableHeading: "AFC EAST",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC NORTH",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Pittsburgh Steelers",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Baltimore Ravens",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "Cleveland Browns",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "Cincinnati Bengals",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC SOUTH",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC WEST",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
    ],
  },
  {
    standingTableHeading: "National Football Conference",
    standingMatches: [
      {
        tableHeading: "AFC EAST",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC NORTH",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC SOUTH",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
      {
        tableHeading: "AFC WEST",
        tableBodyList: [
          {
            teamImgUrl: standingImg1,
            teamName: "Buffalo",
            win: "5",
            lose: "2",
            tie: "0",
            ptc: ".714",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -4,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg2,
            teamName: "Miami Dolphins",
            win: "5",
            lose: "3",
            tie: "0",
            ptc: ".500",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: +47,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg3,
            teamName: "New England Patriots",
            win: "5",
            lose: "4",
            tie: "0",
            ptc: ".333",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -28,
            strk: "W1",
          },
          {
            teamImgUrl: standingImg4,
            teamName: "New York Jets",
            win: "0",
            lose: "7",
            tie: "0",
            ptc: ".000",
            home: "2-1",
            away: "3-1",
            diV: "3-0",
            conf: "4-2",
            pf: "174",
            pa: "178",
            diff: -118,
            strk: "W1",
          },
        ],
      },
    ],
  },
];



export const getAllNflStanding = async (year,onSuccess, onFailure) => {
  console.log(year,"bcr")

  try {
    const res = await webApi.post(

      `/getNflStandings`,
      {
        year: year ? year : "",
        
      }
    );
    if (res.status === 200) {
      const r = res.data;
      console.log(r, "rerere")

      return onSuccess(r);

    } else {
      onFailure("Something Wrong! Please Try again later" + res.data);
    }
  } catch (error) {
    onFailure("Something Wrong! Please Try again later" + error);
  }
};