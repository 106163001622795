import React from "react";
import NcaafFrontStandingsIABody from "../NcaafFrontComponents/NcaafFrontStandingsIA/NcaafFrontStandingsIABody";

const NcaafFrontStandingsIA = () => {
  return (
    <>
      <NcaafFrontStandingsIABody />
    </>
  );
};

export default NcaafFrontStandingsIA;
