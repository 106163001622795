import React from "react";
import NcaafFrontFantasyTableItem from "../../components/fantacyFootballStats/NcaafFrontFantasyTableItem";
import { fantasyFootballTableListMain } from "./Helper";

const NcaafFrontFantasyStatsTable = () => {
  return (
    <>
      <div className="container py-5 z-1 position-relative fantasy-stats mb-sm-5">
        <div className="d-flex justify-content-center justify-content-sm-start mb-negative-50 mb-xs-0 py-5 py-sm-0 position-relative">
          <button className="fantasy-btn"> COLLEGE</button>
          <button className="fantasy-btn ms-3"> NFL</button>
        </div>
        <div className="d-flex">
          {fantasyFootballTableListMain.map((item, index) => (
            <NcaafFrontFantasyTableItem key={index} item={item} />
          ))}
        </div>
      </div>
      {/* <div className="container text-center mb-5 mb-lg-0">
        <span className="font-20 fw-semibold text-white d-inline-block">
          10 / 25 / 50 / 100 / ALL
        </span>
        <button className="gradient-bg text-white d-block font-17 fw-semibold px-5 rounded-pill py-2 py-lg-3 mx-auto mt-4 border-0    load-more-btn-01">
          <span className="d-inline-block px-5 mx-sm-5 py-1 ">LOAD MORE</span>
        </button>
      </div> */}
      <div className="vh-50 d-none d-lg-block"></div>
    </>
  );
};

export default NcaafFrontFantasyStatsTable;
