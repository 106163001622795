import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../Assets/Arow.svg";
import { getArticleDetailsLink } from "./HelperArticle"; 

const ArticlesCard2 = (props) => {
  const dataRow = props.dataRow;

  return (
    <Link to={getArticleDetailsLink(dataRow)}>
      <div className="deny devy-bg-4 Articles_card position-relative d-flex flex-column justify-content-end" style={{ backgroundImage: `url(${(dataRow) ? dataRow.thumbnail : ""})` }}>
        <div className="px-4 z-10 d-flex flex-column flex-sm-row align-item-center justify-content-between mb-4">
          <div>
            <h5 className="white font-web font-20 fw-bold">
              {(dataRow) ? dataRow.title : ""}
              <br className="d-none d-lg-inline-block" />
              {/* {""} Week 5 */}
            </h5>
            <p className="font-18 font-web white mb-0">
              {(dataRow) ? dataRow.sub_title : ""}
            </p>
          </div>
          {/* <div className="d-flex align-items-end pt-2 pt-sm-0"> 
            <Link to={getArticleDetailsLink(dataRow)}
              style={{ float: "right" }}>
              <img className="ms-2" src={arrow} alt="arrow" />
            </Link>
          </div> */}
        </div>
      </div>
    </Link>
  );
};

export default ArticlesCard2;
