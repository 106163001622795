import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Navbar/Header";
import "./components/Navbar/homepage.css";
import Articles from "./views/Articles";
import FantasyFootballStats from "./views/FantasyFootballStats";
import HomePage from "./views/HomePage";
import NflDraftPage from "./views/NflDraftPage";
import NflSchedule from "./views/NflSchedule";
import NflStandings from "./views/NflStandings";
import nflTeam from "./views/nflTeam";

import ScoreBoardPage from "./views/ScoreBoardPage";
import StatsPage from "./views/StatsPage";
import ArticlesWR from "./views/ArticlesWR";
import Roster from "./components/nfl-team/Roster";
import NflTeamSchedule from "./components/nfl-team/NflTeamSchedule";
import NflTeamDetailStats from "./components/nfl-team/NflTeamDetailsStats";
import InjuriesMain from "./components/nfl-team/InjuriesMain";
import DepthChart from "./components/nfl-team/DepthChart";
import NcaafSchedule from "./views/NcaafSchedule";
import NcaafFrontScoreboard from "./views/NcaafFrontScoreboard";
import NcaafRanking from "./views/NcaafRanking";
import NcaafFrontStandingsIA from "./views/NcaafFrontStandingsIA";
import NcaafTeamDetail from "./views/NcaafTeamDetail";
import NcaafFrontfantasyStats from "./views/NcaafFrontfantasyStats";
import NcaafFrontStatsPage from "./views/NcaafFrontStatsPage";
import NcaafFrontTeamPage from "./views/NcaafFrontTeamPage";
import NcaafTeam from "./NcaafFrontComponents/ncaaf-team/NcaafTeam";
import NcaafTeamDetailStat from "./components/NcaafTeamDetailStat/NcaafTeamDetailStat";
import NcaafSeasonLeaders from "./views/NcaafSeasonLeaders";
import NcaafTeamDetailSchedule from "./views/NcaafTeamDetailSchedule";
import NcaafTeamDetailRoster from "./views/NcaafTeamDetailRoster";
import NcaafDepthChart from "./views/NcaafDepthChart";
import NcaafInjuries from "./views/NcaafInjuries";
import AboutUs from "./components/Cms/AboutUs";
import CmsCommon from "./components/Cms/CmsCommon";
import Help from "./components/Cms/Help";
import Podcast from "./components/Cms/Podcast";
import viewAllNflPodcast from "./components/Cms/viewAllNflPodcast";
import viewAllNcaafPodcast from "./components/Cms/viewAllNcaafPodcast";


import Login_register from "./components/Auth/Login_register";
import ForgotPassword from "./components/Auth/ForgotPassword";
import News from "./components/News/News";
import Profile from "./components/Account/Profile";
import ResetPassword from "./components/Auth/ResetPassword";
import ChangePassword from "./components/Account/ChangePassword";
import ViewallVideo from "./components/homepage/ViewallVideo";
import NewsSearch from "./components/News/NewsSearch";
import ArticleViewAll from "./components/Aritcles/ArticleViewAll";
import AritclesDetails from "./components/Aritcles/AritclesDetails";
import NflHomePage from "./views/NflHomePage";
import NcaafHomePage from "./views/NcaafHomePage";
import viewAllMyfavourite from "./components/Cms/viewAllMyfavourite";
import NflPlayerProfilePage from "./views/NflPlayerProfilePage";
import NcaaPlayerProfilePage from "./views/NcaaPlayerProfilePage";
import SearchPage from "./views/SearchPage";
import testVideo from "./views/test";
import NotFound from "./views/NotFound";


const AppRoutes = () => {
  const [ff_isLogin, set_ff_isLogin] = useState(localStorage.getItem('ff_isLogin'));

  return (
    <>
      <div className="App">
        <Header />
        <HashRouter>

          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/search" component={SearchPage} />


            <Route exact path="/nfl" component={NflHomePage} />
            <Route exact path="/nfl/draft" component={NflDraftPage} />
            <Route exact path="/nfl/schedule" component={NflSchedule} />
            <Route exact path="/nfl/team" component={nflTeam} />   {/* Implemented */}
            <Route exact path="/nfl/stats" component={StatsPage} />
            <Route exact path="/nfl/fantasy-stats" component={FantasyFootballStats} />
            <Route exact path="/nfl/scoreboard" component={ScoreBoardPage} />
            <Route exact path="/nfl/standings" component={NflStandings} />

            {/* Button on team list page */}
            <Route exact path="/nfl/team-details/stats" component={NflTeamDetailStats} /> {/* ?team=1500&year=2023 */}
            <Route exact path="/nfl/team-details/schedule" component={NflTeamSchedule} />
            <Route exact path="/nfl/team-details/roster" component={Roster} />
            <Route exact path="/nfl/team-details/depth-chart" component={DepthChart} />
            <Route exact path="/nfl/team-details/injuries" component={InjuriesMain} />

            <Route exact path="/nfl/player-profile/:playerId" component={NflPlayerProfilePage} />


            <Route exact path="/articles" component={Articles} />
            <Route exact path="/articles/:category" component={ArticleViewAll} />
            <Route exact path="/article/:permalink/:id" component={AritclesDetails} />
            <Route exact path="/nfl/articles-details" component={ArticlesWR} />

            {/*===================== NCAAF FRONT PAGE ROUTES =====================*/}
            <Route exact path="/ncaaf" component={NcaafHomePage} />
            <Route exact path="/ncaaf/schedule" component={NcaafSchedule} />
            <Route exact path="/ncaaf/scoreboard" component={NcaafFrontScoreboard} />
            <Route exact path="/ncaaf/ranking" component={NcaafRanking} />
            <Route exact path="/ncaaf/team_1" component={NcaafFrontTeamPage} />
            <Route exact path="/ncaaf/team" component={NcaafTeam} />


            <Route exact path="/ncaaf/team-details" component={NcaafTeamDetail} />
            <Route exact path="/ncaaf/team-details/stats" component={NcaafTeamDetailStat} />

            <Route exact path="/ncaaf/team-details/schedule" component={NcaafTeamDetailSchedule} />
            <Route exact path="/ncaaf/team-details/depth-chart" component={NcaafDepthChart} />
            <Route exact path="/ncaaf/team-details/injuries" component={NcaafInjuries} />

            <Route exact path="/ncaaf/team-details/roster" component={NcaafTeamDetailRoster} />
            <Route exact path="/ncaaf/standings" component={NcaafFrontStandingsIA} />
            <Route exact path="/ncaaf/fantasy-stats" component={NcaafFrontfantasyStats} />
            <Route exact path="/ncaaf/stats" component={NcaafFrontStatsPage} />
            <Route exact path="/ncaaf/qbr-leaders" component={NcaafSeasonLeaders} />

            <Route exact path="/ncaaf/player-profile/:playerId" component={NcaaPlayerProfilePage} />


            <Route exact path="/news" component={News} />
            <Route exact path="/viewallvideo" component={ViewallVideo} />
            <Route exact path="/news-search" component={NewsSearch} />


            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/privacy-policy" component={CmsCommon} />
            <Route exact path="/terms-and-conditions" component={CmsCommon} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/podcast" component={Podcast} />
            <Route exact path="/viewAllNflPodcast" component={viewAllNflPodcast} />
            <Route exact path="/viewAllNcaafPodcast" component={viewAllNcaafPodcast} />
            <Route exact path="/viewAllMyfavourite" component={viewAllMyfavourite} />

          

            <Route exact path="/test" component={testVideo} />

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/login" component={Login_register} />  {/* Worked */}
            <Route exact path="/register" component={Login_register} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />


            {/* {(!ff_isLogin) && (
              <>
                <Route exact path="/login" component={Login_register} />  
                <Route exact path="/register" component={Login_register} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />

              </>
            )}

            {(ff_isLogin) && (
              <>
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/change-password" component={ChangePassword} />
              </>
            )} */}

            <Route path="*" component={NotFound} />

          </Switch>
        </HashRouter>

        <Footer />
      </div>
    </>
  );
};

export default AppRoutes;
