import NcaafFrontleaderImage1 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage1.png";
import NcaafFrontleaderImage2 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage2.png";
import NcaafFrontleaderImage3 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage3.png";
import NcaafFrontleaderImage4 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage4.png";
import NcaafFrontleaderImage5 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage5.png";
import NcaafFrontleaderImage6 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage6.png";
import NcaafFrontleaderImage7 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage7.png";
import NcaafFrontleaderImage8 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage8.png";
import NcaafFrontleaderImage9 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage9.png";
import NcaafFrontleaderImage10 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage10.png";
import NcaafFrontleaderImage11 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage11.png";
import NcaafFrontleaderImage12 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage12.png";
import NcaafFrontleaderImage13 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage13.png";
import NcaafFrontleaderImage14 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage14.png";
import NcaafFrontleaderImage15 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage15.png";
//
import NcaafFrontleaderImage16 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage16.png";
import NcaafFrontleaderImage17 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage17.png";
import NcaafFrontleaderImage18 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage18.png";
import NcaafFrontleaderImage19 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage19.png";
import NcaafFrontleaderImage20 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage20.png";
import NcaafFrontleaderImage21 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage21.png";
import NcaafFrontleaderImage22 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage22.png";
import NcaafFrontleaderImage23 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage23.png";
import NcaafFrontleaderImage24 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage24.png";
import NcaafFrontleaderImage25 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage25.png";
import NcaafFrontleaderImage26 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage26.png";
import NcaafFrontleaderImage27 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage27.png";
import NcaafFrontleaderImage28 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage28.png";
import NcaafFrontleaderImage29 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage29.png";
import NcaafFrontleaderImage30 from "../../NcaafFrontAssets/HomepageAssets/img/NcaafFrontleaderImage30.png";
//
import ncaafFrontoffensiveTeamImg1 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg1.png";
import ncaafFrontoffensiveTeamImg2 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg2.png";
import ncaafFrontoffensiveTeamImg3 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg3.png";
import ncaafFrontoffensiveTeamImg4 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg4.png";
import ncaafFrontoffensiveTeamImg5 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg5.png";
import ncaafFrontoffensiveTeamImg6 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg6.png";
import ncaafFrontoffensiveTeamImg7 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg7.png";
import ncaafFrontoffensiveTeamImg8 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg8.png";
import ncaafFrontoffensiveTeamImg9 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg9.png";
import ncaafFrontoffensiveTeamImg10 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg10.png";
import ncaafFrontoffensiveTeamImg11 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg11.png";
import ncaafFrontoffensiveTeamImg12 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoffensiveTeamImg12.png";
//
import ncaafFrontoDefensiveTeamImg1 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg1.png";
import ncaafFrontoDefensiveTeamImg2 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg2.png";
import ncaafFrontoDefensiveTeamImg3 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg3.png";
import ncaafFrontoDefensiveTeamImg4 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg4.png";
import ncaafFrontoDefensiveTeamImg5 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg5.png";
import ncaafFrontoDefensiveTeamImg6 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg6.png";
import ncaafFrontoDefensiveTeamImg7 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg7.png";
import ncaafFrontoDefensiveTeamImg8 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg8.png";
import ncaafFrontoDefensiveTeamImg9 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg9.png";
import ncaafFrontoDefensiveTeamImg10 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg10.png";
import ncaafFrontoDefensiveTeamImg11 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg11.png";
import ncaafFrontoDefensiveTeamImg12 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg12.png";
import ncaafFrontoDefensiveTeamImg13 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg13.png";
import ncaafFrontoDefensiveTeamImg14 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg14.png";
import ncaafFrontoDefensiveTeamImg15 from "../../NcaafFrontAssets/HomepageAssets/img/ncaafFrontoDefensiveTeamImg15.png";

export const ncaafFrontoffensiveLeaderList = [
  {
    listype: "PASSING",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage1,
        playerName: "Shane Buechele",
        team: "SMU",
        yds: 2581,
      },
      {
        playerImage: NcaafFrontleaderImage2,
        playerName: "Zach Wilson",
        team: "BYU",
        yds: 2511,
      },
      {
        playerImage: NcaafFrontleaderImage3,
        playerName: "Dillon Gabriel",
        team: "UCF",
        yds: 2506,
      },
      {
        playerImage: NcaafFrontleaderImage4,
        playerName: "Mac Jones",
        team: "ALA",
        yds: 2196,
      },
      {
        playerImage: NcaafFrontleaderImage5,
        playerName: "Brady White",
        team: "MEM",
        yds: 2218,
      },
    ],
  },
  {
    listype: "RUSHING",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage6,
        playerName: "Breece Hall",
        team: "ISU",
        yds: 1034,
      },
      {
        playerImage: NcaafFrontleaderImage7,
        playerName: "Sincere McCormick",
        team: "UTSA",
        yds: 921,
      },
      {
        playerImage: NcaafFrontleaderImage8,
        playerName: "Javian Hawkins",
        team: "LOU",
        yds: 822,
      },
      {
        playerImage: NcaafFrontleaderImage9,
        playerName: "Khalil Herbert",
        team: "VT",
        yds: 803,
      },
      {
        playerImage: NcaafFrontleaderImage10,
        playerName: "Ulysses Bentley IV",
        team: "SMU",
        yds: 774,
      },
    ],
  },
  {
    listype: "RECEIVING",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage11,
        playerName: "Marlon Williams",
        team: "UCF",
        yds: 704,
      },
      {
        playerImage: NcaafFrontleaderImage12,
        playerName: "Jonathan Adams Jr.",
        team: "ARST",
        yds: 695,
      },
      {
        playerImage: NcaafFrontleaderImage13,
        playerName: "Elijah Moore",
        team: "MISS",
        yds: 688,
      },
      {
        playerImage: NcaafFrontleaderImage14,
        playerName: "Dax Milne",
        team: "BYU",
        yds: 680,
      },
      {
        playerImage: NcaafFrontleaderImage15,
        playerName: "DeVonta Smith",
        team: "ALA",
        yds: 657,
      },
    ],
  },
];

export const ncaafFrontdefensiveLeaderList = [
  {
    listype: "TACKLES",
    listypetext: "TOT",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage16,
        playerName: "Max Richardson",
        team: "BC",
        yds: 79,
      },
      {
        playerImage: NcaafFrontleaderImage17,
        playerName: "Tyler Grubbs",
        team: "LT",
        yds: 72,
      },
      {
        playerImage: NcaafFrontleaderImage18,
        playerName: "Isaiah McDuffie",
        team: "BC",
        yds: 71,
      },
      {
        playerImage: NcaafFrontleaderImage19,
        playerName: "Grant Morgan",
        team: "ARK",
        yds: 70,
      },
      {
        playerImage: NcaafFrontleaderImage20,
        playerName: "Riley Cole",
        team: "USA",
        yds: 68,
      },
    ],
  },
  {
    listype: "SACKS",
    listypetext: "SACK",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage21,
        playerName: "Patrick Johnson",
        team: "TULN",
        yds: 9.0,
      },
      {
        playerImage: NcaafFrontleaderImage22,
        playerName: "Patrick Jones II",
        team: "PITT",
        yds: 8.0,
      },
      {
        playerImage: NcaafFrontleaderImage23,
        playerName: "Victor Dimukeje",
        team: "DUKE",
        yds: 7.5,
      },
      {
        playerImage: NcaafFrontleaderImage24,
        playerName: "Chris Rumph II",
        team: "DUKE",
        yds: 7.5,
      },
      {
        playerImage: NcaafFrontleaderImage25,
        playerName: "Praise Amaewhule",
        team: "UTEP",
        yds: 7.0,
      },
    ],
  },
  {
    listype: "INTERCEPTIONS",
    listypetext: "INT",
    listplayers: [
      {
        playerImage: NcaafFrontleaderImage26,
        playerName: "Xavien Howard",
        team: "MIA",
        yds: 4,
      },
      {
        playerImage: NcaafFrontleaderImage27,
        playerName: "Kendall Fuller",
        team: "WSH",
        yds: 3,
      },
      {
        playerImage: NcaafFrontleaderImage28,
        playerName: "J.C. Jackson",
        team: "NE",
        yds: 3,
      },
      {
        playerImage: NcaafFrontleaderImage29,
        playerName: "Carlton Davis",
        team: "TB",
        yds: 3,
      },
      {
        playerImage: NcaafFrontleaderImage30,
        playerName: "Pierre Desir",
        team: "NYJ",
        yds: 3,
      },
    ],
  },
];

export const ncaafFrontoffensiveTeamListData = [
  {
    listype: "TOTAL YARDS",
    listplayers: [
      {
        playerImage: ncaafFrontoffensiveTeamImg1,
        playerName: "UCF Knights",
        yds: 652.5,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg2,
        playerName: "North Texas Mean Green",
        yds: 593.6,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg3,
        playerName: "USC Trojans",
        yds: 556.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg4,
        playerName: "Alabama Crimson Tide",
        team: "",
        yds: 555.2,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg5,
        playerName: "Ole Miss Rebels",
        yds: 541.0,
      },
    ],
  },
  {
    listype: "PASSING",
    listplayers: [
      {
        playerImage: ncaafFrontoffensiveTeamImg1,
        playerName: "UCF Knights",
        yds: 417.7,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg6,
        playerName: "Nevada Wolf Pack",
        yds: 397.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg3,
        playerName: "USC Trojans",
        yds: 381.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg4,
        playerName: "Alabama Crimson Tide",
        team: "",
        yds: 380.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg7,
        playerName: "Florida Gators",
        yds: 369.0,
      },
    ],
  },
  {
    listype: "RECEIVING",
    listplayers: [
      {
        playerImage: ncaafFrontoffensiveTeamImg8,
        playerName: "Air Force Falcons",
        yds: 330.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg9,
        playerName: "Toledo Rockets",
        yds: 310.0,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg10,
        playerName: "Army Black Knights",
        yds: 308.1,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg11,
        playerName: "Appalachian State Mountaineers",
        yds: 283.2,
      },
      {
        playerImage: ncaafFrontoffensiveTeamImg12,
        playerName: "Virginia Tech Hokies",
        yds: 277.4,
      },
    ],
  },
];

export const ncaafFrontdefensiveTeamListData = [
  {
    listype: "POINTS ALLOWED",
    ptsg: "PTS/G",
    listplayers: [
      {
        playerImage: ncaafFrontoDefensiveTeamImg1,
        playerName: "Toledo Rockets",
        yds: 3.0,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg2,
        playerName: "Wisconsin Badgers",
        yds: 7.0,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg3,
        playerName: "Marshall Thundering Herd",
        yds: 9.5,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg4,
        playerName: "Florida Atlantic Owls",
        team: "",
        yds: 11.5,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg5,
        playerName: "Army Black Knights",
        yds: 11.7,
      },
    ],
  },
  {
    listype: "YARDS ALLOWED",
    ptsg: "YDS/G",
    listplayers: [
      {
        playerImage: ncaafFrontoDefensiveTeamImg6,
        playerName: "Wisconsin Badgers",
        yds: 218.0,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg7,
        playerName: "San Diego State Aztecs",
        yds: 233.0,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg8,
        playerName: "Marshall Thundering Herd",
        yds: 252.5,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg9,
        playerName: "Western Michigan Broncos",
        team: "",
        yds: 256.0,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg10,
        playerName: "Toledo Rockets",
        yds: 267.0,
      },
    ],
  },
  {
    listype: "SACKS",
    ptsg: "SACK",
    listplayers: [
      {
        playerImage: ncaafFrontoDefensiveTeamImg11,
        playerName: "Pittsburgh Panthers",
        yds: 38,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg12,
        playerName: "Clemson Tigers",
        yds: 30,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg13,
        playerName: "Tulane Green Wave",
        yds: 29,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg14,
        playerName: "Duke Blue Devils",
        yds: 27,
      },
      {
        playerImage: ncaafFrontoDefensiveTeamImg15,
        playerName: "Oklahoma Sooners",
        yds: 26,
      },
    ],
  },
];

export const teamstatisticsTableData = [
  {
    offense: "Total Yards",
    defense: "Points Allowed",
    specialteam: "Returning",
  },
  {
    offense: "Passing",
    defense: "Yards Allowed",
    specialteam: "Kicking",
  },
  {
    offense: "Rushing",
    defense: "Passing",
    specialteam: "Punting",
  },
  {
    offense: "Receiving",
    defense: "Receiving",
    specialteam: "",
  },
  {
    offense: "Downs",
    defense: "",
    specialteam: "",
  },
];
